import React, { useState, useEffect, useRef } from 'react';
import './ChallengeMaster.css';

const CATEGORIES = {
  all: 'All',
  physical: 'Physical',
  mental: 'Mental',
  social: 'Social',
  wellness: 'Wellness',
  creative: 'Creative'
};

const CHALLENGES = {
  physical: ["Run 5km today", "Do 20 push-ups", "Try a yoga pose", "Go for a 1-hour walk"],
  mental: ["Meditate for 10 minutes", "Read 20 pages", "Learn 3 new words", "Research a topic"],
  social: ["Call a friend", "Compliment 3 strangers", "Do a random act of kindness"],
  wellness: ["Drink 8 glasses of water", "Take a cold shower", "Cook a healthy meal"],
  creative: ["Write in your journal", "Draw something", "Try a new hobby"]
};

const ChallengeMaster = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [challenges, setChallenges] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [streak, setStreak] = useState(0);
  const canvasRef = useRef(null);

  useEffect(() => {
    const savedData = localStorage.getItem('challengeMasterData');
    if (savedData) {
      const { streak: savedStreak, completed: savedCompleted } = JSON.parse(savedData);
      setStreak(savedStreak || 0);
      setCompleted(savedCompleted || []);
    }
    generateDailyChallenges();
  }, []);

  useEffect(() => {
    localStorage.setItem('challengeMasterData', JSON.stringify({
      streak,
      completed
    }));
  }, [streak, completed]);

  const generateDailyChallenges = () => {
    const allChallenges = Object.values(CHALLENGES).flat();
    const shuffled = [...allChallenges].sort(() => 0.5 - Math.random());
    setChallenges(shuffled.slice(0, 5));
  };

  const handleComplete = (index, e) => {
    e.stopPropagation();
    if (completed.includes(index)) {
      setCompleted(completed.filter(i => i !== index));
    } else {
      const newCompleted = [...completed, index];
      setCompleted(newCompleted);
      if (newCompleted.length === 5) {
        setStreak(s => s + 1);
      }
    }
  };

  const generateShareImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas size
    canvas.width = 1200;
    canvas.height = 630;

    // Background
    ctx.fillStyle = '#4caf50';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Add gradient overlay
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 0.1)');
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0.1)');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set text styles
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';

    // Title
    ctx.font = 'bold 60px -apple-system, BlinkMacSystemFont, sans-serif';
    ctx.fillText('Challenge Master Achievement', canvas.width/2, 100);

    // Streak
    ctx.font = 'bold 48px -apple-system, BlinkMacSystemFont, sans-serif';
    ctx.fillText(`🔥 ${streak} Day Streak!`, canvas.width/2, 180);

    // Completion status
    ctx.font = '36px -apple-system, BlinkMacSystemFont, sans-serif';
    ctx.fillText(`${completed.length}/5 Challenges Completed`, canvas.width/2, 250);

    // Completed challenges
    const completedChallenges = challenges.filter((_, index) => completed.includes(index));
    ctx.font = '28px -apple-system, BlinkMacSystemFont, sans-serif';
    completedChallenges.forEach((challenge, index) => {
      ctx.fillText(`✓ ${challenge}`, canvas.width/2, 350 + index * 50);
    });

    // Add date
    const date = new Date().toLocaleDateString();
    ctx.font = '24px -apple-system, BlinkMacSystemFont, sans-serif';
    ctx.fillText(date, canvas.width/2, 580);

    return canvas.toDataURL('image/png');
  };

  const handleShare = async () => {
    if (completed.length === 0) return;

    const shareImage = generateShareImage();

    // Convert base64 to blob
    const response = await fetch(shareImage);
    const blob = await response.blob();

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'My Challenge Master Achievement',
          text: `🏆 Completed ${completed.length}/5 challenges with a ${streak} day streak!`,
          files: [new File([blob], 'achievement.png', { type: 'image/png' })]
        });
      } catch (error) {
        console.log('Error sharing:', error);
        // Fallback to download
        const link = document.createElement('a');
        link.href = shareImage;
        link.download = 'challenge-master-achievement.png';
        link.click();
      }
    } else {
      // Fallback to download
      const link = document.createElement('a');
      link.href = shareImage;
      link.download = 'challenge-master-achievement.png';
      link.click();
    }
  };

  return (
    <div className="challenge-app">
      <header className="header">
        <h1>Today's Epic Challenges</h1>
        <div className="streak-counter">
          🔥 {streak} Day Streak
        </div>
      </header>

      <nav className="category-filter">
        {Object.entries(CATEGORIES).map(([key, label]) => (
          <button
            key={key}
            className={`category-button ${selectedCategory === key ? 'active' : ''}`}
            onClick={() => setSelectedCategory(key)}
          >
            {label}
          </button>
        ))}
      </nav>

      <div className="challenge-grid">
        {challenges
          .filter(challenge =>
            selectedCategory === 'all' ||
            Object.entries(CHALLENGES).some(([cat, list]) =>
              cat === selectedCategory && list.includes(challenge)
            )
          )
          .map((challenge, index) => (
            <div
              key={index}
              className={`challenge-card ${completed.includes(index) ? 'completed' : ''}`}
            >
              <div className="card-inner">
                <div className="card-front">
                  <span className="challenge-number">{index + 1}</span>
                </div>
                <div className="card-back">
                  <div className="challenge-text">
                    {challenge}
                  </div>
                  <button
                    className="action-button complete-button"
                    onClick={(e) => handleComplete(index, e)}
                  >
                    {completed.includes(index) ? '✓ Completed' : 'Complete'}
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="progress-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${(completed.length / 5) * 100}%` }}
          />
        </div>
        <div className="progress-text">
          {completed.length}/5 Completed
        </div>
        {completed.length > 0 && (
          <button
            className="share-button"
            onClick={handleShare}
          >
            Share Achievement
          </button>
        )}
      </div>

      {/* Hidden canvas for generating share image */}
      <canvas
        ref={canvasRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ChallengeMaster;