import React, { useState } from 'react';

const GoldenRatioAnalyzer = () => {
  const [measurements, setMeasurements] = useState({ width: '', height: '' });
  const [result, setResult] = useState(null);
  const [recommendation, setRecommendation] = useState(null);

  const handleMeasurementChange = (e) => {
    setMeasurements({ ...measurements, [e.target.name]: e.target.value });
  };

  const analyzeRatio = () => {
    const width = parseFloat(measurements.width);
    const height = parseFloat(measurements.height);

    if (isNaN(width) || isNaN(height) || width <= 0 || height <= 0) {
      setResult({ error: "Please enter valid positive numbers for both width and height." });
      setRecommendation(null);
      return;
    }

    const ratio = width / height;
    const goldenRatio = 1.618;
    const tolerance = 0.1;
    const isGoldenRatio = Math.abs(ratio - goldenRatio) <= tolerance;

    setResult({
      isGoldenRatio,
      ratio: ratio.toFixed(3),
      difference: Math.abs(ratio - goldenRatio).toFixed(3),
    });

    if (!isGoldenRatio) {
      if (ratio < goldenRatio) {
        const newWidth = height * goldenRatio;
        const newHeight = width / goldenRatio;
        setRecommendation({
          increaseWidth: (newWidth - width).toFixed(2),
          decreaseHeight: (height - newHeight).toFixed(2),
        });
      } else {
        const newHeight = width / goldenRatio;
        const newWidth = height * goldenRatio;
        setRecommendation({
          increaseHeight: (newHeight - height).toFixed(2),
          decreaseWidth: (width - newWidth).toFixed(2),
        });
      }
    } else {
      setRecommendation(null);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-2xl font-bold mb-4">Golden Ratio Analyzer</h1>

      <div className="mb-4">
        <label htmlFor="width" className="block mb-2 font-medium text-gray-700">Width</label>
        <input
          type="number"
          id="width"
          name="width"
          value={measurements.width}
          onChange={handleMeasurementChange}
          placeholder="Enter width"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="height" className="block mb-2 font-medium text-gray-700">Height</label>
        <input
          type="number"
          id="height"
          name="height"
          value={measurements.height}
          onChange={handleMeasurementChange}
          placeholder="Enter height"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <button
        onClick={analyzeRatio}
        className="w-full mb-4 px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Analyze Ratio
      </button>

      {result && (
        <div className={`p-4 mb-4 rounded-md ${
          result.error ? "bg-red-100 text-red-700" :
          result.isGoldenRatio ? "bg-green-100 text-green-700" :
          "bg-yellow-100 text-yellow-700"
        }`}>
          {result.error ? (
            <p>{result.error}</p>
          ) : (
            <>
              <h3 className="font-bold">{result.isGoldenRatio ? "Golden Ratio Detected!" : "Not Quite Golden Ratio"}</h3>
              <p>
                The ratio is {result.ratio}.
                {result.isGoldenRatio
                  ? " This is within the golden ratio range!"
                  : ` This differs from the golden ratio by ${result.difference}.`}
              </p>
            </>
          )}
        </div>
      )}

      {recommendation && (
        <div className="mt-4 p-4 bg-blue-100 text-blue-700 rounded-md">
          <h3 className="font-bold">Recommendations to achieve Golden Ratio:</h3>
          <ul className="list-disc list-inside">
            {recommendation.increaseWidth && (
              <li>Increase width by approximately {recommendation.increaseWidth} units</li>
            )}
            {recommendation.decreaseWidth && (
              <li>Decrease width by approximately {recommendation.decreaseWidth} units</li>
            )}
            {recommendation.increaseHeight && (
              <li>Increase height by approximately {recommendation.increaseHeight} units</li>
            )}
            {recommendation.decreaseHeight && (
              <li>Decrease height by approximately {recommendation.decreaseHeight} units</li>
            )}
          </ul>
          <p className="mt-2 text-sm text-blue-600">
            Note: You can adjust either dimension to achieve the golden ratio.
          </p>
        </div>
      )}
    </div>
  );
};

export default GoldenRatioAnalyzer;