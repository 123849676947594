import React, { useState, useEffect, useCallback } from 'react';
import { Sun, Moon, Loader } from 'lucide-react';

const cities = [
  { name: "New York", lat: 40.7128, lon: -74.0060 },
  { name: "Los Angeles", lat: 34.0522, lon: -118.2437 },
  { name: "Chicago", lat: 41.8781, lon: -87.6298 },
  { name: "Tokyo", lat: 35.6762, lon: 139.6503 },
  { name: "London", lat: 51.5074, lon: -0.1278 },
  { name: "Paris", lat: 48.8566, lon: 2.3522 },
  { name: "Sydney", lat: -33.8688, lon: 151.2093 },
  { name: "Moscow", lat: 55.7558, lon: 37.6173 },
  { name: "Dubai", lat: 25.2048, lon: 55.2708 },
  { name: "Singapore", lat: 1.3521, lon: 103.8198 },
  { name: "Rio de Janeiro", lat: -22.9068, lon: -43.1729 },
  { name: "Cape Town", lat: -33.9249, lon: 18.4241 },
  { name: "Mumbai", lat: 19.0760, lon: 72.8777 },
  { name: "Beijing", lat: 39.9042, lon: 116.4074 },
  { name: "Cairo", lat: 30.0444, lon: 31.2357 }
];

const USNOSunDataManager = () => {
  const [sunData, setSunData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentEvents, setCurrentEvents] = useState([]);

  const fetchUSNOData = useCallback(async (city) => {
    const year = new Date().getFullYear();
    const url = `https://aa.usno.navy.mil/calculated/rstt/year?ID=AA&year=${year}&task=0&lat=${city.lat}&lon=${city.lon}&label=${encodeURIComponent(city.name)}&tz=0&tz_sign=-1&submit=Get+Data`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const text = await response.text();

      // Parse the text response into structured data
      const lines = text.split('\n').filter(line => line.trim() !== '');
      const data = lines.slice(7).map(line => {
        const [date, rise, set] = line.split(/\s+/);
        return { date, rise, set };
      });

      return data;
    } catch (error) {
      console.error(`Error fetching data for ${city.name}:`, error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      const allData = {};
      for (const city of cities) {
        const cityData = await fetchUSNOData(city);
        if (cityData) {
          allData[city.name] = cityData;
        }
      }
      setSunData(allData);
      setLoading(false);
    };

    fetchAllData();
  }, [fetchUSNOData]);

  const findCurrentEvents = useCallback(() => {
    const now = new Date();
    const events = [];

    Object.entries(sunData).forEach(([cityName, cityData]) => {
      const today = cityData.find(day => day.date === now.toISOString().split('T')[0]);
      if (today) {
        const riseTime = new Date(`${today.date}T${today.rise}:00Z`);
        const setTime = new Date(`${today.date}T${today.set}:00Z`);

        if (Math.abs(now - riseTime) <= 5 * 60 * 1000) { // within 5 minutes
          events.push({ type: 'sunrise', city: cityName, time: riseTime });
        }
        if (Math.abs(now - setTime) <= 5 * 60 * 1000) { // within 5 minutes
          events.push({ type: 'sunset', city: cityName, time: setTime });
        }
      }
    });

    setCurrentEvents(events);
  }, [sunData]);

  useEffect(() => {
    if (!loading && Object.keys(sunData).length > 0) {
      findCurrentEvents();
      const interval = setInterval(findCurrentEvents, 60000); // Check every minute
      return () => clearInterval(interval);
    }
  }, [loading, sunData, findCurrentEvents]);

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200">
      <Loader className="animate-spin mr-2" />
      <p className="text-xl">Loading sun data...</p>
    </div>
  );

  if (error) return (
    <div className="min-h-screen flex items-center justify-center bg-red-200">
      <p className="text-xl text-red-800">{error}</p>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-400 to-purple-500 p-8">
      <h1 className="text-4xl font-bold text-white mb-8 text-center">Global Sunset/Sunrise Tracker</h1>
      {currentEvents.length === 0 ? (
        <p className="text-xl text-white text-center">No sunset or sunrise events occurring right now.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentEvents.map((event, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                {event.type === 'sunrise' ? (
                  <Sun className="text-yellow-500 mr-2" size={24} />
                ) : (
                  <Moon className="text-indigo-500 mr-2" size={24} />
                )}
                <h2 className="text-2xl font-semibold">{event.city}</h2>
              </div>
              <p className="text-lg">
                {event.type.charAt(0).toUpperCase() + event.type.slice(1)} at{' '}
                {event.time.toLocaleTimeString()}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default USNOSunDataManager;