import React, { useState } from 'react';
import { motion } from 'framer-motion';

const questions = [
  "Do they make you laugh?",
  "Do you share common interests?",
  "Do they support your goals?",
  "Do you feel comfortable being yourself around them?",
  "Do they communicate openly with you?",
  "Do you trust them?",
  "Do they respect your boundaries?",
  "Do you feel excited about your future together?",
  "Do they show affection in ways you appreciate?",
  "Do you feel loved and valued in the relationship?",
];

const LoveMe = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [fallenPetals, setFallenPetals] = useState([]);
  const [gameResult, setGameResult] = useState(null);
  const [lovesMe, setLovesMe] = useState(true);

  const handleAnswer = (isYes) => {
    if (fallenPetals.length < questions.length) {
      setFallenPetals([...fallenPetals, fallenPetals.length]);
      setLovesMe(!lovesMe);

      if (fallenPetals.length === questions.length - 1) {
        setGameResult(lovesMe ? "They love you!" : "They love you not. But remember, self-love comes first!");
      } else {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const Petal = ({ rotation, fallen, index }) => (
    <motion.path
      d="M0,-90 C5,-80 15,-70 0,-40 C-15,-70 -5,-80 0,-90"
      fill="white"
      stroke="#ffd700"
      strokeWidth="1"
      transform={`rotate(${rotation})`}
      initial={{ opacity: 1 }}
      animate={fallen ? {
        y: 200 + Math.random() * 100,
        x: Math.random() * 200 - 100,
        rotate: rotation + (Math.random() * 720 - 360),
        opacity: 0
      } : {}}
      transition={{ duration: 2 }}
    />
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <h1 className="text-4xl font-bold text-yellow-500 mb-8">LoveMe</h1>
      <svg viewBox="-100 -100 200 200" className="w-80 h-80">
        {/* Stem */}
        <path d="M0,90 Q-10,120 0,150" stroke="#4CAF50" strokeWidth="4" fill="none" />

        {/* Daisy petals */}
        {[...Array(10)].map((_, index) => (
          <Petal
            key={index}
            rotation={index * 36}
            fallen={fallenPetals.includes(index)}
            index={index}
          />
        ))}

        {/* Daisy center */}
        <circle cx="0" cy="0" r="20" fill="#ffd700" />
      </svg>

      <div className="mt-8 text-center">
        <p className="text-xl font-semibold mb-4">{questions[currentQuestionIndex]}</p>
        <p className="text-lg font-medium mb-4">{lovesMe ? "Loves me..." : "Loves me not..."}</p>
        <div className="space-x-4">
          <button
            onClick={() => handleAnswer(true)}
            className="px-6 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
            disabled={gameResult !== null}
          >
            Yes
          </button>
          <button
            onClick={() => handleAnswer(false)}
            className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            disabled={gameResult !== null}
          >
            No
          </button>
        </div>
      </div>

      {gameResult && (
        <div className="mt-8 text-2xl font-bold text-yellow-500">{gameResult}</div>
      )}
    </div>
  );
};

export default LoveMe;