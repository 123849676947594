import React, { useState, useEffect, useRef } from 'react';
import Matter from 'matter-js';

const HourglassTimer = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const particlesRef = useRef([]);

  useEffect(() => {
    const { Engine, Render, World, Bodies, Composite } = Matter;

    // Create engine
    engineRef.current = Engine.create();
    const engine = engineRef.current;

    // Create renderer
    const render = Render.create({
      element: canvasRef.current,
      engine: engine,
      options: {
        width: 300,
        height: 400,
        wireframes: false,
        background: '#f0f0f0'
      }
    });

    // Create hourglass shape
    const topContainer = Bodies.rectangle(150, 100, 200, 200, { isStatic: true, render: { fillStyle: 'transparent' } });
    const bottomContainer = Bodies.rectangle(150, 300, 200, 200, { isStatic: true, render: { fillStyle: 'transparent' } });
    const leftWall = Bodies.rectangle(50, 200, 10, 400, { isStatic: true });
    const rightWall = Bodies.rectangle(250, 200, 10, 400, { isStatic: true });
    const narrowPassage = Bodies.rectangle(150, 200, 40, 10, { isStatic: true });

    Composite.add(engine.world, [topContainer, bottomContainer, leftWall, rightWall, narrowPassage]);

    // Run the engine
    Engine.run(engine);
    Render.run(render);

    return () => {
      Render.stop(render);
      World.clear(engine.world);
      Engine.clear(engine);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, []);

  useEffect(() => {
    if (isRunning && time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsRunning(false);
            playRetroBeep();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isRunning, time]);

  const handleStart = () => {
    if (time > 0 && !isRunning) {
      setIsRunning(true);
      createSandParticles();
    }
  };

  const createSandParticles = () => {
    const { World, Bodies } = Matter;
    const engine = engineRef.current;
    const particleCount = time * 2; // Adjust this value to change the number of particles

    const newParticles = [];
    for (let i = 0; i < particleCount; i++) {
      const particle = Bodies.circle(
        150 + (Math.random() - 0.5) * 150,
        100 + Math.random() * 50,
        2,
        { restitution: 0.5, friction: 0.1, render: { fillStyle: '#FFD700' } }
      );
      newParticles.push(particle);
    }

    World.add(engine.world, newParticles);
    particlesRef.current = newParticles;
  };

  const playRetroBeep = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'square';
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // A4 note
    oscillator.connect(audioContext.destination);
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Hourglass Timer</h1>
        <div className="mb-4">
          <input
            type="number"
            value={time}
            onChange={(e) => setTime(Math.min(Math.max(0, parseInt(e.target.value)), 180))}
            className="border rounded px-2 py-1 mr-2"
            placeholder="Enter time (min)"
          />
          <button
            onClick={handleStart}
            disabled={isRunning || time === 0}
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Start
          </button>
        </div>
        <div className="text-xl font-semibold mb-4">
          Time Remaining: {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
        </div>
        <div ref={canvasRef} className="border rounded" />
      </div>
    </div>
  );
};

export default HourglassTimer;