import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const navLinks = [
    { name: 'Explore', path: '/explore' },

  ];

  const isActive = (path) => location.pathname === path;

  return (
    <header className="relative z-50">
      <div className="absolute inset-0 bg-gradient-to-r from-orange-50 via-amber-50 to-rose-100 opacity-90 backdrop-blur-md" />
      <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-stone-300/50 to-transparent" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 relative">
        <nav className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="relative group">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="relative"
            >
              <span className="text-3xl font-serif font-bold text-stone-800 tracking-wide relative z-10">
                Azoodle
              </span>
              <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-amber-700/0 via-amber-700/50 to-amber-700/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
            </motion.div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                className="relative group"
              >
                <span className={`text-sm font-medium tracking-wide transition-colors duration-300 ${
                  isActive(link.path) ? 'text-stone-900' : 'text-stone-600 hover:text-stone-800'
                }`}>
                  {link.name}
                </span>
                <div className={`absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-transparent via-stone-800 to-transparent transform origin-left transition-transform duration-300 ${
                  isActive(link.path) ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                }`} />
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-full bg-white/20 hover:bg-white/30 transition-colors duration-300"
            >
              {isMobileMenuOpen ? (
                <FiX className="w-6 h-6 text-stone-800" />
              ) : (
                <FiMenu className="w-6 h-6 text-stone-800" />
              )}
            </button>
          </div>
        </nav>

        {/* Mobile Menu */}
        <motion.div
          initial="closed"
          animate={isMobileMenuOpen ? "open" : "closed"}
          variants={{
            open: { opacity: 1, height: "auto" },
            closed: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 0.3 }}
          className="md:hidden overflow-hidden"
        >
          <div className="py-4 space-y-4">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block px-4 py-2 rounded-lg transition-colors duration-300 ${
                  isActive(link.path)
                    ? 'bg-stone-900 text-white'
                    : 'text-stone-600 hover:bg-white/30'
                }`}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;