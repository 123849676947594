import React, { useState, useEffect } from 'react';
import { Loader2, X } from 'lucide-react';

const PeriodicTable = () => {
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [price, setPrice] = useState('');
  const [swivel, setSwivel] = useState(false);

  useEffect(() => {
    fetch('/PubChemElements_all.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const processedData = data.Table.Row.map(row => {
          const element = {};
          row.Cell.forEach((value, index) => {
            const key = data.Table.Columns.Column[index];
            element[key] = value;
          });
          return element;
        });
        setElements(processedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to load elements. Please try again later.');
        setLoading(false);
      });
  }, []);

  const getGroupColor = (groupBlock) => {
    const colorMap = {
      "Nonmetal": "bg-red-200",
      "Noble gas": "bg-purple-200",
      "Alkali metal": "bg-yellow-200",
      "Alkaline earth metal": "bg-green-200",
      "Metalloid": "bg-blue-200",
      "Halogen": "bg-pink-200",
      "Post-transition metal": "bg-indigo-200",
      "Transition metal": "bg-orange-200",
      "Lanthanide": "bg-teal-200",
      "Actinide": "bg-cyan-200"
    };
    return colorMap[groupBlock] || "bg-gray-200";
  };

  const handleSetPrice = () => {
    if (price && selectedElement) {
      setElements(prevElements =>
        prevElements.map(el => ({
          ...el,
          price: parseFloat(((parseFloat(el.AtomicNumber) / parseFloat(selectedElement.AtomicNumber)) * parseFloat(price)).toFixed(2))
        }))
      );
      setSwivel(true);
      setTimeout(() => setSwivel(false), 2000);
      setSelectedElement(null);
      setPrice('');
    }
  };

  const ElementCard = ({ element }) => (
    <div
      className={`w-full h-full cursor-pointer ${getGroupColor(element.GroupBlock)} p-1 flex flex-col justify-between rounded-lg shadow-sm transition-transform duration-500 ${swivel ? 'rotate-y-180' : ''}`}
      onClick={() => setSelectedElement(element)}
    >
      <div className="text-[0.6rem] text-right">{element.AtomicNumber}</div>
      <div className="text-base font-bold text-center">{element.Symbol}</div>
      {element.price && <div className="text-[0.6rem] text-center">${element.price}</div>}
    </div>
  );

  const ElementDetails = ({ element }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-4 rounded-lg w-full max-w-md max-h-[80vh] overflow-y-auto relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={() => setSelectedElement(null)}
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-2">{element.Name} ({element.Symbol})</h2>
        <p><strong>Atomic Number:</strong> {element.AtomicNumber}</p>
        <p><strong>Atomic Mass:</strong> {element.AtomicMass}</p>
        <p><strong>Group Block:</strong> {element.GroupBlock}</p>
        <p><strong>Standard State:</strong> {element.StandardState}</p>
        <p><strong>Electron Configuration:</strong> {element.ElectronConfiguration}</p>
        <p><strong>Oxidation States:</strong> {element.OxidationStates}</p>
        <p><strong>Electronegativity:</strong> {element.Electronegativity}</p>
        <p><strong>Atomic Radius:</strong> {element.AtomicRadius} pm</p>
        <p><strong>Ionization Energy:</strong> {element.IonizationEnergy} eV</p>
        <p><strong>Electron Affinity:</strong> {element.ElectronAffinity} eV</p>
        <p><strong>Melting Point:</strong> {element.MeltingPoint} K</p>
        <p><strong>Boiling Point:</strong> {element.BoilingPoint} K</p>
        <p><strong>Density:</strong> {element.Density} g/cm³</p>
        <p><strong>Year Discovered:</strong> {element.YearDiscovered}</p>
        <div className="mt-4">
          <input
            type="number"
            className="w-full p-2 border rounded"
            placeholder="Set price for this element"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <button
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full"
            onClick={handleSetPrice}
          >
            Set Price
          </button>
        </div>
      </div>
    </div>
  );

  const periodicTableLayout = [
    [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2],
    [3, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 6, 7, 8, 9, 10],
    [11, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 14, 15, 16, 17, 18],
    [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
    [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54],
    [55, 56, 57, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86],
    [87, 88, 89, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 0, 0],
    [0, 0, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 0, 0]
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
        <span className="ml-2 text-lg">Loading elements...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-full px-2 py-4">
      <h1 className="text-2xl font-bold text-center mb-4">Periodic Table of Elements</h1>
      <div className="grid grid-cols-18 gap-px">
        {periodicTableLayout.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map((atomicNumber, colIndex) => (
              <div key={colIndex} className="aspect-w-1 aspect-h-1">
                {atomicNumber !== 0 && (
                  <ElementCard
                    element={elements.find(e => parseInt(e.AtomicNumber) === atomicNumber)}
                  />
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      {selectedElement && <ElementDetails element={selectedElement} />}
    </div>
  );
};

export default PeriodicTable;