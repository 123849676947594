import React, { useState, useEffect, useRef } from 'react';
import './NoiseLevelChecker.css';

const Button = ({ onClick, disabled, className, children }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`button ${className}`}
  >
    {children}
  </button>
);

const Progress = ({ value, className }) => (
  <div className={`w-full bg-gray-200 rounded-full h-2.5 ${className}`}>
    <div
      className="bg-blue-600 h-2.5 rounded-full"
      style={{ width: `${value}%` }}
    ></div>
  </div>
);

const Moon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
  </svg>
);

const Sun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="5"></circle>
    <line x1="12" y1="1" x2="12" y2="3"></line>
    <line x1="12" y1="21" x2="12" y2="23"></line>
    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
    <line x1="1" y1="12" x2="3" y2="12"></line>
    <line x1="21" y1="12" x2="23" y2="12"></line>
    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
  </svg>
);

const NoiseLevelChecker = () => {
  const [isListening, setIsListening] = useState(false);
  const [noiseLevel, setNoiseLevel] = useState(0);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [streak, setStreak] = useState(0);

  const audioContext = useRef(null);
  const analyser = useRef(null);
  const microphone = useRef(null);
  const animationFrameId = useRef(null);
  const stream = useRef(null);

  useEffect(() => {
    const savedStreak = localStorage.getItem('ambientStreak');
    if (savedStreak) {
      setStreak(parseInt(savedStreak, 10));
    }

    const lastCheckDate = localStorage.getItem('lastStreakCheck');
    const today = new Date().toDateString();

    if (lastCheckDate !== today) {
      if (lastCheckDate && new Date(lastCheckDate).getTime() < new Date(today).getTime() - 86400000) {
        // Reset streak if more than a day has passed
        setStreak(0);
        localStorage.setItem('ambientStreak', '0');
      }
      localStorage.setItem('lastStreakCheck', today);
    }

    return () => {
      stopListening();
    };
  }, []);

  const startListening = async () => {
    console.log('Start Listening button clicked');
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
      microphone.current = audioContext.current.createMediaStreamSource(stream.current);
      microphone.current.connect(analyser.current);

      analyser.current.fftSize = 1024;
      const bufferLength = analyser.current.frequencyBinCount;
      const dataArray = new Float32Array(bufferLength);

      const updateNoiseLevel = () => {
        analyser.current.getFloatTimeDomainData(dataArray);

        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i] * dataArray[i];
        }
        const rms = Math.sqrt(sum / bufferLength);

        // Convert to decibels
        const db = 20 * Math.log10(rms);

        // Normalize to 0-100 range for progress bar
        const normalizedDb = Math.max(0, Math.min(100, (db + 100) * 1.5));

        setNoiseLevel(db);
        animationFrameId.current = requestAnimationFrame(updateNoiseLevel);
      };

      setIsListening(true);
      updateNoiseLevel();

    } catch (err) {
      console.error("Error accessing microphone:", err);
      setError(`Error accessing microphone: ${err.message}`);
    }
  };

  const stopListening = () => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
    if (microphone.current) {
      microphone.current.disconnect();
    }
    if (audioContext.current) {
      audioContext.current.close();
    }
    if (stream.current) {
      stream.current.getTracks().forEach(track => track.stop());
    }
    setIsListening(false);
    checkAndUpdateStreak();
  };

  const checkAndUpdateStreak = () => {
    const today = new Date().toDateString();
    const lastUpdateDate = localStorage.getItem('lastStreakUpdateDate');

    if (lastUpdateDate !== today) {
      if (noiseLevel < -65) {  // Adjusted threshold for dB
        const newStreak = streak + 1;
        setStreak(newStreak);
        localStorage.setItem('ambientStreak', newStreak.toString());
      }
      localStorage.setItem('lastStreakUpdateDate', today);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  const getNoiseLevelCategory = (db) => {
    if (db < -65) return "Very Quiet";
    if (db < -50) return "Quiet";
    if (db < -35) return "Moderate";
    if (db < -20) return "Loud";
    return "Very Loud";
  };

  return (
    <div className={`noise-level-checker ${darkMode ? 'dark' : 'light'}`}>
      <div className="flex justify-between items-center mb-6">
        <h1 className={`text-2xl font-light ${darkMode ? 'text-white' : 'text-blue-800'}`}>Ambient Sound Monitor</h1>
        <Button
          onClick={toggleDarkMode}
          className={`p-2 rounded-full ${darkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-200 text-gray-900'}`}
          aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          {darkMode ? <Sun /> : <Moon />}
        </Button>
      </div>

      {error && (
        <div className={`border px-4 py-3 rounded-lg mb-4 ${darkMode ? 'bg-red-900 border-red-700 text-red-100' : 'bg-red-50 border-red-200 text-red-700'}`} role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="flex justify-center space-x-4 mb-6">
        <Button
          onClick={startListening}
          disabled={isListening}
          className={`start-button ${isListening ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Start Listening
        </Button>
        <Button
          onClick={stopListening}
          disabled={!isListening}
          className={`stop-button ${!isListening ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Stop Listening
        </Button>
      </div>

      <div className={`p-6 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-white shadow-inner'}`}>
        <div className="flex items-center justify-between mb-4">
          <span className={`text-lg font-medium ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>Sound Level:</span>
          <span className={`text-2xl font-bold ${darkMode ? 'text-blue-300' : 'text-blue-600'}`}>
            {getNoiseLevelCategory(noiseLevel)}
          </span>
        </div>
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${darkMode ? 'text-blue-300 bg-blue-900' : 'text-blue-600 bg-blue-200'}`}>
                {noiseLevel.toFixed(1)} dB
              </span>
            </div>
          </div>
          <Progress
            value={Math.max(0, Math.min(100, (noiseLevel + 100) * 1.5))}
            className={darkMode ? 'bg-gray-600' : 'bg-blue-200'}
          />
        </div>
      </div>

      <div className={`mt-4 text-center ${darkMode ? 'text-blue-300' : 'text-blue-600'}`}>
        <p className="font-semibold">Current Streak: {streak} day{streak !== 1 ? 's' : ''}</p>
        <p className="text-sm">Maintain a quiet environment (below -65 dB) daily to increase your streak!</p>
      </div>
    </div>
  );
};

export default NoiseLevelChecker;