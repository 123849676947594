import React, { useState, useEffect, useRef } from 'react';
import { Calendar, MapPin, Heart, Share2, Download, ArrowLeft } from 'lucide-react';
import html2canvas from 'html2canvas'; // You'll need to install this package
import './DreamsBeginHere.css';
import airports from './airports.json';

const BoardingPass = ({ boardingPass, flightDetails }) => {
  const boardingPassRef = useRef(null);

  const downloadAsImage = async () => {
    try {
      const element = boardingPassRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: '#ffffff',
      });

      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = `dream-journey-${boardingPass.destinationAirport}.png`;
      link.click();
    } catch (error) {
      console.error('Error generating image:', error);
      alert('Failed to download image. Please try again.');
    }
  };

  const shareImage = async () => {
    try {
      const element = boardingPassRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: '#ffffff',
      });

      canvas.toBlob(async (blob) => {
        const file = new File([blob], 'dream-journey.png', { type: 'image/png' });
        if (navigator.share) {
          await navigator.share({
            title: 'My Dream Journey',
            text: `I'm manifesting my move from ${boardingPass.departureAirport} to ${boardingPass.destinationAirport}!`,
            files: [file]
          });
        } else {
          // Fallback for browsers that don't support sharing
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'dream-journey.png';
          link.click();
          URL.revokeObjectURL(url);
        }
      }, 'image/png');
    } catch (error) {
      console.error('Error sharing:', error);
      alert('Sharing failed. You can try downloading the image instead.');
    }
  };

  return (
    <div className="boarding-pass">
      <div ref={boardingPassRef} className="boarding-pass-content">
        <div className="boarding-pass-header">
          <h2>Your Journey Awaits</h2>
        </div>
        <div className="boarding-pass-inner">
          <div className="flight-details-grid">
            <div className="flight-info-column">
              <div className="info-item">
                <p className="info-label">From</p>
                <p className="info-value truncate-text">{boardingPass.departureAirport}</p>
              </div>
              <div className="info-item">
                <p className="info-label">Passenger</p>
                <p className="info-value truncate-text">{boardingPass.passengerName}</p>
              </div>
              <div className="info-item">
                <p className="info-label">Flight</p>
                <p className="info-value">{flightDetails.flightNumber}</p>
              </div>
            </div>
            <div className="flight-info-column">
              <div className="info-item">
                <p className="info-label">To</p>
                <p className="info-value truncate-text">{boardingPass.destinationAirport}</p>
              </div>
              <div className="info-item">
                <p className="info-label">Date</p>
                <p className="info-value">{new Date(boardingPass.departureDate).toLocaleDateString()}</p>
              </div>
              <div className="info-grid">
                <div className="info-item">
                  <p className="info-label">Gate</p>
                  <p className="info-value">{flightDetails.gate}</p>
                </div>
                <div className="info-item">
                  <p className="info-label">Seat</p>
                  <p className="info-value">{flightDetails.seat}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boarding-pass-actions">
        <button onClick={shareImage} className="action-button share-button">
          <Share2 size={20} />
          Share Image
        </button>
        <button onClick={downloadAsImage} className="action-button download-button">
          <Download size={20} />
          Download Image
        </button>
      </div>
    </div>
  );
};

const DreamJourneyPlanner = ({ boardingPass }) => {
  const [notes, setNotes] = useState(localStorage.getItem(`notes-${boardingPass.destinationAirport}`) || '');
  const [milestones, setMilestones] = useState(() => {
    const saved = localStorage.getItem(`milestones-${boardingPass.destinationAirport}`);
    return saved ? JSON.parse(saved) : [
      { text: 'Research housing costs', completed: false },
      { text: 'Update resume for local job market', completed: false },
      { text: 'Research visa requirements', completed: false },
      { text: 'Start learning local language', completed: false },
      { text: 'Join local community groups online', completed: false }
    ];
  });
  const [newMilestone, setNewMilestone] = useState('');

  useEffect(() => {
    localStorage.setItem(`notes-${boardingPass.destinationAirport}`, notes);
  }, [notes, boardingPass.destinationAirport]);

  useEffect(() => {
    localStorage.setItem(`milestones-${boardingPass.destinationAirport}`, JSON.stringify(milestones));
  }, [milestones, boardingPass.destinationAirport]);

  const daysUntil = () => {
    const today = new Date();
    const departureDate = new Date(boardingPass.departureDate);
    const diffTime = Math.abs(departureDate - today);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const addMilestone = (e) => {
    e.preventDefault();
    if (newMilestone.trim()) {
      setMilestones([...milestones, { text: newMilestone, completed: false }]);
      setNewMilestone('');
    }
  };

  return (
    <div className="journey-planner">
      <div className="journey-planner-header">
        <div className="journey-title">
          <Heart className="icon-heart" />
          Your Dream Journey Plan
        </div>
      </div>
      <div className="journey-content">
        <div className="journey-summary">
          <div className="destination-info">
            <MapPin className="icon-map" />
            <span className="destination-text">
              {boardingPass.departureAirport} → {boardingPass.destinationAirport}
            </span>
          </div>
          <div className="countdown-info">
            <Calendar className="icon-calendar" />
            <span className="countdown-text">
              {daysUntil()} days until your new beginning
            </span>
          </div>
        </div>

        <div className="planning-grid">
          <div className="notes-section">
            <h3>Why {boardingPass.destinationAirport}?</h3>
            <textarea
              className="notes-textarea"
              placeholder="Write why you chose this destination and what excites you about it..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className="milestones-section">
            <h3>Your Journey Milestones</h3>
            <div className="milestone-list">
              {milestones.map((milestone, index) => (
                <div key={index} className="milestone-item">
                  <input
                    type="checkbox"
                    checked={milestone.completed}
                    onChange={() => {
                      const newMilestones = [...milestones];
                      newMilestones[index].completed = !newMilestones[index].completed;
                      setMilestones(newMilestones);
                    }}
                    className="milestone-checkbox"
                  />
                  <span className={milestone.completed ? 'milestone-text completed' : 'milestone-text'}>
                    {milestone.text}
                  </span>
                </div>
              ))}
            </div>
            <form onSubmit={addMilestone} className="add-milestone-form">
              <input
                type="text"
                value={newMilestone}
                onChange={(e) => setNewMilestone(e.target.value)}
                placeholder="Add new milestone..."
                className="milestone-input"
              />
              <button type="submit" className="add-milestone-button">
                Add
              </button>
            </form>
          </div>
        </div>

        <div className="affirmation-box">
          <h3>Daily Affirmation</h3>
          <p className="affirmation-text">
            "My new life in {boardingPass.destinationAirport} is already in motion.
            Each day brings me closer to my dreams."
          </p>
        </div>
      </div>
    </div>
  );
};

const DreamsBeginHere = () => {
  const [boardingPass, setBoardingPass] = useState(null);
  const [flightDetails, setFlightDetails] = useState(null);
  const [formData, setFormData] = useState({
    departureAirport: '',
    destinationAirport: '',
    departureDate: '',
    passengerName: '',
  });

  const generateFlightDetails = () => ({
    flightNumber: `DA${Math.floor(Math.random() * 1000) + 100}`,
    gate: `${String.fromCharCode(65 + Math.floor(Math.random() * 8))}${Math.floor(Math.random() * 20) + 1}`,
    seat: `${String.fromCharCode(65 + Math.floor(Math.random() * 6))}${Math.floor(Math.random() * 30) + 1}`
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.departureAirport === formData.destinationAirport) {
      alert('Please select different airports for departure and destination.');
      return;
    }
    setFlightDetails(generateFlightDetails());
    setBoardingPass(formData);
  };

  const handleBack = () => {
    setBoardingPass(null);
    setFlightDetails(null);
  };

  return (
    <div className="dreams-container">
      <header className="main-header">
        <h1 className="main-title">Dreams Begin Here</h1>
        <p className="main-subtitle">Visualize your journey to a new life</p>
      </header>

      {!boardingPass ? (
        <div className="form-container">
          <form onSubmit={handleSubmit} className="dreams-form">
            <h2 className="form-title">Create Your Dream Journey</h2>

            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="departureAirport">Departure Airport</label>
                <select
                  id="departureAirport"
                  name="departureAirport"
                  value={formData.departureAirport}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select departure airport</option>
                  {airports.map((airport) => (
                    <option key={airport.code} value={airport.code}>
                      {airport.code} - {airport.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="destinationAirport">Destination Airport</label>
                <select
                  id="destinationAirport"
                  name="destinationAirport"
                  value={formData.destinationAirport}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select destination airport</option>
                  {airports.map((airport) => (
                    <option key={airport.code} value={airport.code}>
                      {airport.code} - {airport.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="departureDate">Departure Date</label>
                <input
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="passengerName">Passenger Name</label>
                <input
                  type="text"
                  id="passengerName"
                  name="passengerName"
                  value={formData.passengerName}
                  onChange={handleChange}
                  required
                  placeholder="Enter your full name"
                />
              </div>
            </div>

            <button type="submit" className="submit-button">
              Create Your Dream Journey
            </button>
          </form>
        </div>
      ) : (
        <div className="journey-container">
          <BoardingPass boardingPass={boardingPass} flightDetails={flightDetails} />
          <DreamJourneyPlanner boardingPass={boardingPass} />

          <button onClick={handleBack} className="back-button">
            <ArrowLeft size={20} />
            Back to Form
          </button>
        </div>
      )}
    </div>
  );
};

export default DreamsBeginHere;