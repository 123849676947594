// src/pages/PrivacyPolicy.jsx

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-stone-50 min-h-screen py-8 px-4">
      <style>{`
        .app-list {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin: 1rem 0;
        }

        .app-name {
          background: #f5f5f4;
          padding: 0.25rem 0.75rem;
          border-radius: 1rem;
          font-size: 0.9rem;
          color: #57534e;
        }

        @media (max-width: 768px) {
          .container {
            padding: 1.5rem;
          }
        }
      `}</style>

      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-sm p-8 sm:p-12">
        <h1 className="text-4xl font-bold text-stone-900 text-center mb-6 pb-4 border-b border-stone-200">
          Privacy Policy
        </h1>

        <div className="text-center text-stone-600 italic mb-8">
          Effective Date: October 22, 2024
        </div>

        <div className="mb-8">
          <p className="text-stone-600 mb-4">
            This Privacy Policy describes how your information is collected, used, and shared when you visit or use our website and its features, including interactive applications like:
          </p>

          <div className="app-list">
            <span className="app-name">Believe</span>
            <span className="app-name">Button</span>
            <span className="app-name">ChallengeMaster</span>
            <span className="app-name">CipherApp</span>
            <span className="app-name">Days Calculator</span>
            <span className="app-name">Dreams Begin Here</span>
            <span className="app-name">Eating Page</span>
            <span className="app-name">Element Price</span>
            <span className="app-name">Periodic Table</span>
            <span className="app-name">Gold Fall</span>
            <span className="app-name">Keyboard Music</span>
            <span className="app-name">Metronome</span>
            <span className="app-name">Noise Level Checker</span>
            <span className="app-name">Life Time</span>
            <span className="app-name">LunaSays</span>
            <span className="app-name">Shareable Image</span>
            <span className="app-name">Matrix Rain with Cats</span>
            <span className="app-name">Name Display</span>
            <span className="app-name">Name Mixer</span>
            <span className="app-name">Say No App</span>
            <span className="app-name">Pi Page</span>
            <span className="app-name">Rejection Buzzer</span>
            <span className="app-name">Moving Rock</span>
            <span className="app-name">Rock Paper Scissors</span>
            <span className="app-name">Water Loss</span>
            <span className="app-name">Unique Ball</span>
            <span className="app-name">Reverse Video Player</span>
            <span className="app-name">Golden Ratio Analyzer</span>
            <span className="app-name">Image Color Extractor</span>
            <span className="app-name">PhantomScript Mirror</span>
            <span className="app-name">Typo Generator</span>
          </div>
          <p className="text-stone-600 mt-4">
            and analytics services (referred to as "the Apps").
          </p>
        </div>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Information We Collect
        </h2>

        <h3 className="text-xl font-semibold text-stone-900 mt-6 mb-3">
          Automatically Collected Information:
        </h3>
        <p className="text-stone-600">
          The Apps may collect limited technical information to ensure proper functionality. This may include:
        </p>
        <ul className="list-disc pl-6 text-stone-600 mb-6">
          <li className="mb-2">
            <strong className="text-stone-900">Device Information:</strong> Data such as screen size, browser type, and operating system to ensure the Apps are displayed and function correctly on your device.
          </li>
          <li>
            <strong className="text-stone-900">Interaction Data:</strong>
            <ul className="list-disc pl-6 mt-2">
              <li className="mb-2">
                <strong className="text-stone-900">Text Input and Mirroring:</strong> The PhantomScript Mirror App allows users to input text, which is reversed and displayed in various fonts. Users can generate images of their mirrored text and share or download them. All text input and image generation are processed locally and are not shared unless the user chooses to do so.
              </li>
              <li className="mb-2">
                <strong className="text-stone-900">Randomized Typo Generation:</strong> The Typo Generator allows users to input text, which is processed to include random typographical errors and capitalization. The output can be copied to the clipboard by the user. All processing occurs locally, and no data is transmitted externally unless shared.
              </li>
              <li>
                <strong className="text-stone-900">Clipboard Use:</strong> Users may choose to copy the output text to the clipboard. No data is automatically collected, and copying is handled locally.
              </li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold text-stone-900 mt-6 mb-3">
          Server Analytics:
        </h3>
        <p className="text-stone-600 mb-4">
          We use server-side analytics provided by Namecheap to monitor traffic and usage patterns on our website. This may include:
        </p>
        <ul className="list-disc pl-6 text-stone-600 mb-6">
          <li className="mb-2">
            <strong className="text-stone-900">IP Address:</strong> Collected to analyze user locations and improve our services.
          </li>
          <li className="mb-2">
            <strong className="text-stone-900">Page Views and Session Duration:</strong> Information about how long users stay on each page and their interactions with different parts of the website.
          </li>
          <li className="mb-2">
            <strong className="text-stone-900">Browser and Device Data:</strong> Information about the type of browser, operating system, and device used to access the site.
          </li>
        </ul>
        <p className="text-stone-600 mb-6">
          These analytics are anonymized and aggregated and do not include personally identifiable information. The data helps us understand how users interact with the website and improve the overall user experience.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Use of Collected Information
        </h2>
        <p className="text-stone-600 mb-4">
          The information collected is used solely for:
        </p>
        <ul className="list-disc pl-6 text-stone-600 mb-6">
          <li className="mb-2">
            Enhancing your experience by ensuring features (such as text mirroring, typo generation, image generation, and clipboard copying) work as intended.
          </li>
          <li className="mb-2">
            Monitoring and analyzing website traffic to optimize the website's performance and functionality.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Cookies and Tracking
        </h2>
        <ul className="list-disc pl-6 text-stone-600 mb-6">
          <li className="mb-2">
            <strong className="text-stone-900">Local Storage:</strong> The Apps store data (such as user inputs and generated content) in your browser's local storage. This data is not shared with any third parties.
          </li>
          <li className="mb-2">
            <strong className="text-stone-900">Server-Side Cookies:</strong> Namecheap's analytics may use cookies to track session data, such as time spent on the website. These cookies do not collect personal information and are used only for performance monitoring.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Sharing of Information
        </h2>
        <p className="text-stone-600 mb-6">
          The Apps allow users to generate and copy/share data such as text, images, and results. These actions are handled locally on your device, and no data is transmitted externally unless you choose to share it using your device's built-in sharing options.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Third-Party Services
        </h2>
        <p className="text-stone-600 mb-6">
          <strong className="text-stone-900">Server Analytics</strong> provided by Namecheap collects and processes aggregated traffic data for performance monitoring.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Data Security
        </h2>
        <p className="text-stone-600 mb-6">
          We take the security of your information seriously. The Apps do not collect or transmit personal data. However, Namecheap may collect anonymized traffic data through its server analytics. While we implement measures to protect your interactions, users should note that no system is entirely secure.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Children's Privacy
        </h2>
        <p className="text-stone-600 mb-6">
          The Apps are not directed at children under 13 years old. We do not knowingly collect personal data from children. If you believe your child has interacted with the Apps inappropriately, please contact us.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Changes to This Privacy Policy
        </h2>
        <p className="text-stone-600 mb-6">
          We may update this Privacy Policy periodically to reflect changes in the Apps' functionality, the use of server analytics, or for other legal reasons. Please review this page regularly for the latest information.
        </p>

        <h2 className="text-2xl font-semibold text-stone-900 mt-10 mb-4 pb-2 border-b border-stone-200">
          Contact Us
        </h2>
        <p className="text-stone-600">
          If you have any questions about this Privacy Policy, please reach out via quadcitycollective@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;