import React from 'react';
import { FiTwitter, FiCoffee, FiMail } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const SocialIcon = ({ href, icon: Icon }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    whileHover={{ scale: 1.1, y: -2 }}
    whileTap={{ scale: 0.95 }}
    className="p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-colors duration-300"
  >
    <Icon size={20} className="text-stone-600 hover:text-stone-800 transition-colors duration-300" />
  </motion.a>
);

const Footer = () => {
  return (
    <footer className="relative z-50">
      <div className="absolute inset-0 bg-gradient-to-r from-orange-50 via-amber-50 to-rose-100 opacity-90 backdrop-blur-md" />
      <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-stone-300/50 to-transparent" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 relative">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="flex items-center gap-6">
            <SocialIcon
              href="https://x.com/quadcityc"
              icon={FiTwitter}
            />
            <SocialIcon
              href="https://www.buymeacoffee.com/azoodle"
              icon={FiCoffee}
            />
            <SocialIcon
              href="https://azoodle.substack.com/subscribe?email=example@gmail.com"
              icon={FiMail}
            />
          </div>

          <div className="text-center sm:text-right">
            <p className="font-serif text-sm text-stone-600">
              <Link
                to="/privacy"
                className="hover:text-stone-800 transition-colors duration-300 underline decoration-stone-400 hover:decoration-stone-800"
              >
                Privacy Policy
              </Link>
            </p>
            <div className="h-px w-24 mx-auto sm:ml-auto sm:mr-0 mt-2 bg-gradient-to-r from-transparent via-stone-400/30 to-transparent" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;