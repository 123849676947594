import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const CautionScreen = () => {
  const [earthImage, setEarthImage] = useState('');
  const navigate = useNavigate();  // Initialize useNavigate

  useEffect(() => {
    // Fetch EPIC Image from NASA API
    const fetchImage = async () => {
      const res = await axios.get(`https://api.nasa.gov/EPIC/api/natural/images?api_key=x1qQJ5O7DOtiBlDSf4qgwjIMoZVsQxlDjtcPVEky`);
      const date = res.data[0].date.split(" ")[0].replace(/-/g, "/");
      setEarthImage(`https://epic.gsfc.nasa.gov/archive/natural/${date}/png/${res.data[0].image}.png`);
    };

    fetchImage();
  }, []);

  // Function to handle button click and navigate to the main page
  const handleEnter = () => {
    navigate('/earth-main');  // Navigate to the main page route
  };

  return (
    <div className="nasa-welcome-container">
      <h1>Welcome to Earth</h1>
      <h2>Earth Age: 4.543 billion years</h2>
      {earthImage && <img src={earthImage} alt="Earth" />}
      <button onClick={handleEnter} className="nasa-enter-button">Enter</button>
    </div>
  );
};

export default CautionScreen;