import React, { useState, useEffect, useCallback } from 'react';
import Matter from 'matter-js';
import { AlertCircle, Trash2, Info, Settings, ChevronUp, ChevronDown } from 'lucide-react';

const MAX_BALLS = 50;
const AUTO_CLEAR_DELAY = 30000; // 30 seconds

const DEFAULT_PHYSICS = {
  gravity: 1,
  restitution: 1,
  friction: 0,
  frictionAir: 0.01,
  mixRate: 0.1,
  upwardForce: 0.02
};

const PhysicsControl = ({ label, value, min, max, step, onChange }) => (
  <div className="flex flex-col gap-1">
    <label className="text-sm font-medium">{label}</label>
    <div className="flex items-center gap-2">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        className="w-full"
      />
      <span className="text-sm w-12 text-right">{value.toFixed(2)}</span>
    </div>
  </div>
);

const PhysicsPanel = ({ physics, isOpen, setIsOpen, onPhysicsChange }) => (
  <div className="fixed left-4 top-4 z-10 bg-white rounded-lg shadow-lg">
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="w-full flex items-center justify-between p-3 bg-gray-100 rounded-t-lg hover:bg-gray-200"
    >
      <div className="flex items-center gap-2">
        <Settings className="h-4 w-4" />
        <span className="font-medium">Physics Controls</span>
      </div>
      {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
    </button>

    {isOpen && (
      <div className="p-4 space-y-4">
        <PhysicsControl
          label="Gravity"
          value={physics.gravity}
          min={0}
          max={2}
          step={0.1}
          onChange={(value) => onPhysicsChange({ ...physics, gravity: value })}
        />
        <PhysicsControl
          label="Bounciness"
          value={physics.restitution}
          min={0}
          max={1.5}
          step={0.1}
          onChange={(value) => onPhysicsChange({ ...physics, restitution: value })}
        />
        <PhysicsControl
          label="Air Friction"
          value={physics.frictionAir}
          min={0}
          max={0.05}
          step={0.001}
          onChange={(value) => onPhysicsChange({ ...physics, frictionAir: value })}
        />
        <PhysicsControl
          label="Mixing Rate"
          value={physics.mixRate}
          min={0}
          max={0.5}
          step={0.05}
          onChange={(value) => onPhysicsChange({ ...physics, mixRate: value })}
        />
        <PhysicsControl
          label="Bounce Force"
          value={physics.upwardForce}
          min={0}
          max={0.1}
          step={0.01}
          onChange={(value) => onPhysicsChange({ ...physics, upwardForce: value })}
        />
        <button
          onClick={() => onPhysicsChange(DEFAULT_PHYSICS)}
          className="w-full mt-2 px-3 py-2 bg-gray-100 rounded hover:bg-gray-200 text-sm"
        >
          Reset to Defaults
        </button>
      </div>
    )}
  </div>
);

const WelcomeOverlay = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md shadow-xl">
        <h2 className="text-2xl font-bold mb-4">Welcome to Physics Playground!</h2>
        <ul className="space-y-2 mb-6">
          <li className="flex items-center gap-2">
            <Info className="h-4 w-4" /> Click anywhere to create bouncing balls
          </li>
          <li className="flex items-center gap-2">
            <Info className="h-4 w-4" /> Watch them collide and mix colors
          </li>
          <li className="flex items-center gap-2">
            <Info className="h-4 w-4" /> Adjust physics parameters in the control panel
          </li>
        </ul>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

const CustomAlert = ({ title, description, variant = 'default' }) => (
  <div className={`rounded-lg border p-4 ${
    variant === 'destructive' ? 'bg-red-50 border-red-200 text-red-900' : 'bg-white border-gray-200 text-gray-900'
  }`}>
    <div className="flex items-center gap-2">
      <AlertCircle className="h-4 w-4" />
      <span className="font-semibold">{title}</span>
    </div>
    <div className="mt-2 text-sm">{description}</div>
  </div>
);

const UniqueBall = ({ ball }) => {
  const { r, g, b } = ball.color || {
    r: ball.greyPercentage,
    g: ball.greyPercentage,
    b: ball.greyPercentage
  };

  return (
    <div
      className="absolute w-5 h-5 rounded-full"
      style={{
        left: `${ball.position.x - 10}px`,
        top: `${ball.position.y - 10}px`,
        backgroundColor: `rgb(${r}%, ${g}%, ${b}%)`,
        transition: 'transform 0.2s',
        transform: ball.isNew ? 'scale(1.2)' : 'scale(1)',
      }}
    />
  );
};

const EnhancedBallApp = () => {
  const [uniqueBalls, setUniqueBalls] = useState([]);
  const [engine, setEngine] = useState(null);
  const [uniqueClickCount, setUniqueClickCount] = useState(0);
  const [colorMode, setColorMode] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [autoClearTimeout, setAutoClearTimeout] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [physics, setPhysics] = useState(DEFAULT_PHYSICS);
  const [showPhysicsPanel, setShowPhysicsPanel] = useState(false);

  const clearBalls = useCallback(() => {
    if (engine) {
      Matter.World.clear(engine.world, false);
      setUniqueBalls([]);
      setUniqueClickCount(0);
      setShowWarning(false);
    }
  }, [engine]);

  const handlePhysicsChange = (newPhysics) => {
    clearBalls();
    setPhysics(newPhysics);
  };

  useEffect(() => {
    const engine = Matter.Engine.create();
    const runner = Matter.Runner.create();
    engine.world.gravity.y = physics.gravity;
    setEngine(engine);

    const walls = [
      Matter.Bodies.rectangle(window.innerWidth / 2, window.innerHeight + 25, window.innerWidth, 50, { isStatic: true }),
      Matter.Bodies.rectangle(window.innerWidth / 2, -25, window.innerWidth, 50, { isStatic: true }),
      Matter.Bodies.rectangle(-25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true }),
      Matter.Bodies.rectangle(window.innerWidth + 25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true })
    ];

    Matter.World.add(engine.world, walls);
    Matter.Runner.run(runner, engine);

    Matter.Events.on(engine, 'collisionStart', (event) => {
      event.pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair;

        if (colorMode) {
          if (bodyA.color && bodyB.color) {
            const mixColor = (a, b) => a + (b - a) * physics.mixRate;
            bodyA.color = {
              r: mixColor(bodyA.color.r, bodyB.color.r),
              g: mixColor(bodyA.color.g, bodyB.color.g),
              b: mixColor(bodyA.color.b, bodyB.color.b)
            };
            bodyB.color = {
              r: mixColor(bodyB.color.r, bodyA.color.r),
              g: mixColor(bodyB.color.g, bodyA.color.g),
              b: mixColor(bodyB.color.b, bodyA.color.b)
            };
          }
        } else {
          if (bodyA.greyPercentage !== undefined && bodyB.greyPercentage !== undefined) {
            const greyDiffA = (bodyB.greyPercentage - bodyA.greyPercentage) * physics.mixRate;
            const greyDiffB = (bodyA.greyPercentage - bodyB.greyPercentage) * physics.mixRate;
            bodyA.greyPercentage += greyDiffA;
            bodyB.greyPercentage += greyDiffB;
          }
        }

        if (bodyA.isStatic || bodyB.isStatic) {
          const ball = bodyA.isStatic ? bodyB : bodyA;
          const upwardVelocity = window.innerHeight * physics.upwardForce;

          if (ball.position.y > window.innerHeight - 50) {
            Matter.Body.setVelocity(ball, { x: ball.velocity.x, y: -upwardVelocity });
          }
          if (ball.position.y < 50) {
            Matter.Body.setVelocity(ball, { x: ball.velocity.x, y: upwardVelocity });
          }
          if (ball.position.x < 50) {
            Matter.Body.setVelocity(ball, { x: Math.abs(ball.velocity.x), y: ball.velocity.y });
          }
          if (ball.position.x > window.innerWidth - 50) {
            Matter.Body.setVelocity(ball, { x: -Math.abs(ball.velocity.x), y: ball.velocity.y });
          }
        }
      });
    });

    const updatePositions = () => {
      setUniqueBalls(prev => prev.map(ball => ({
        ...ball,
        position: {
          x: ball.body.position.x,
          y: ball.body.position.y,
        },
        greyPercentage: ball.body.greyPercentage,
        color: ball.body.color,
        isNew: false
      })));
    };

    const interval = setInterval(updatePositions, 1000 / 60);

    return () => {
      clearInterval(interval);
      Matter.Runner.stop(runner);
      Matter.Engine.clear(engine);
    };
  }, [colorMode, physics]);

  useEffect(() => {
    if (uniqueBalls.length > MAX_BALLS * 0.8) {
      setShowWarning(true);
    }

    if (uniqueBalls.length === MAX_BALLS) {
      if (autoClearTimeout) clearTimeout(autoClearTimeout);
      const timeout = setTimeout(clearBalls, AUTO_CLEAR_DELAY);
      setAutoClearTimeout(timeout);
    }

    return () => {
      if (autoClearTimeout) clearTimeout(autoClearTimeout);
    };
  }, [uniqueBalls.length, clearBalls, autoClearTimeout]);

  const addUniqueBall = (e) => {
    if (!engine || uniqueBalls.length >= MAX_BALLS) return;

    setUniqueClickCount(prev => prev + 1);

    const newBall = Matter.Bodies.circle(e.clientX, e.clientY, 10, {
      restitution: physics.restitution,
      friction: physics.friction,
      frictionAir: physics.frictionAir,
    });

    if (colorMode) {
      const randomColor = () => Math.floor(Math.random() * 100);
      newBall.color = { r: randomColor(), g: randomColor(), b: randomColor() };
    } else {
      newBall.greyPercentage = uniqueClickCount % 2 === 0 ? 0 : 100;
    }

    Matter.World.add(engine.world, newBall);

    setUniqueBalls(prev => [...prev, {
      body: newBall,
      greyPercentage: newBall.greyPercentage,
      color: newBall.color,
      position: { x: e.clientX, y: e.clientY },
      isNew: true
    }]);
  };

  const handleModeToggle = () => {
    clearBalls();
    setColorMode(!colorMode);
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden">
      <WelcomeOverlay isOpen={showWelcome} onClose={() => setShowWelcome(false)} />

      <PhysicsPanel
        physics={physics}
        isOpen={showPhysicsPanel}
        setIsOpen={setShowPhysicsPanel}
        onPhysicsChange={handlePhysicsChange}
      />

      {/* Controls */}
      <div className="fixed top-4 right-4 flex flex-col gap-4 z-10">
        <button
          onClick={handleModeToggle}
          className="bg-white px-4 py-2 rounded shadow hover:bg-gray-100 transition-colors"
        >
          Toggle {colorMode ? "Greyness" : "Color"} Mode
        </button>

        <button
          onClick={clearBalls}
          className="flex items-center justify-center gap-2 bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 transition-colors"
        >
          <Trash2 className="h-4 w-4" /> Clear All
        </button>
      </div>

      {/* Ball count warning */}
      {showWarning && (
        <div className="fixed top-4 left-72 z-10 max-w-sm">
          <CustomAlert
            variant="destructive"
            title="Warning"
            description={
              <>
                Approaching maximum balls ({uniqueBalls.length}/{MAX_BALLS})
                {uniqueBalls.length === MAX_BALLS && (
                  <div className="mt-2">
                    Auto-clearing in {Math.ceil(AUTO_CLEAR_DELAY / 1000)} seconds...
                  </div>
                )}
              </>
            }
          />
        </div>
      )}

      {/* Stats */}
      <div className="fixed bottom-4 left-4 bg-white bg-opacity-90 p-4 rounded shadow">
        <div className="font-medium">
          Balls: {uniqueBalls.length}/{MAX_BALLS}
        </div>
      </div>

      {/* Click area and balls */}
      <div className="w-full h-full" onClick={addUniqueBall}>
        {uniqueBalls.map((ball, index) => (
          <UniqueBall key={index} ball={ball} />
        ))}
      </div>
    </div>
  );
};

export default EnhancedBallApp;