import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import {
  FiCompass,
  FiImage,
  FiInfo,
  FiTag,
  FiCircle,
  FiClock,
  FiStar,
  FiTrendingUp,
  FiHeart,
  FiCpu,
  FiBox,
  FiMonitor,
  FiMusic,
  FiArrowLeft
} from 'react-icons/fi';

// GameCard component from HomePage
const GameCard = ({ title, link, icon }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="h-full"
    >
      <Link
        to={link}
        className="block h-full rounded-xl overflow-hidden transition-all duration-300 ease-in-out bg-white/10 backdrop-blur-sm hover:bg-white/20 border border-white/20"
      >
        <div className="p-6 flex flex-col items-center justify-between h-full">
          <div className="text-4xl mb-4 text-stone-700 group-hover:text-stone-800">{icon}</div>
          <h3 className="text-lg font-serif text-stone-800 mb-2 text-center">{title}</h3>
          <div className="mt-4">
            <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-stone-900 text-white hover:bg-stone-800 transition-colors duration-300">
              Try Now
            </span>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};


const collections = {
  "Interactive Art": [
    { title: "Pi", link: "/pies", icon: <FiCircle /> },
    { title: "Gold", link: "/goldfall", icon: <FiCircle /> },
    { title: "Water", link: "/water", icon: <FiCircle /> },
    { title: "Cipher", link: "/cipher", icon: <FiCircle /> },
    { title: "Typo", link: "/typo", icon: <FiCircle /> },
    { title: "Mirror-Text", link: "/mirrortext", icon: <FiCircle /> },
    { title: "Periodic-Table", link: "/periodictable", icon: <FiCircle /> },
    { title: "rock-paper-scissor", link: "/rockpaperscissor", icon: <FiCircle /> },
    { title: "button", link: "/button", icon: <FiCircle /> },
    { title: "narc", link: "/narc", icon: <FiCircle /> },
    { title: "stubborn-rock", link: "/rock", icon: <FiCircle /> },
    { title: "Inherit", link: "/ballsinherit", icon: <FiCircle /> },
    { title: "Matrix Rain", link: "/matrix", icon: <FiCircle /> }
  ],

  "Sound & Music": [
    { title: "Keyboard Music", link: "/keyboardmusic", icon: <FiMusic /> },
    { title: "Metronome", link: "/metronome", icon: <FiMusic /> },
    { title: "Noise Level", link: "/noise", icon: <FiMusic /> }
  ],
  "Health & Wellness":[
    { title: "Guts", link: "/guts", icon: <FiHeart /> },
    { title: "Rejected", link: "/rejected", icon: <FiHeart /> },
    { title: "no", link: "/no", icon: <FiHeart /> },
    { title: "Luna", link: "/luna", icon: <FiHeart /> },
    { title: "Believe", link: "/believe", icon: <FiHeart /> },
    { title: "Human-O-Meter", link: "/humanchallenge", icon: <FiHeart /> },
    { title: "DreamCity", link: "/dreams", icon: <FiHeart /> }
  ],
  "Mini-Tools":[
    { title: "Color-palette", link: "/palette", icon: <FiClock /> },
    { title: "Golden ratio - o - not", link: "/goldenratio", icon: <FiClock /> },
    { title: "Reverse Video Player", link: "/reversevideoplayer", icon: <FiClock /> },
    { title: "photobooth", link: "/photobooth", icon: <FiClock /> },
    { title: "name-mix", link: "/namemix", icon: <FiClock /> },
    { title: "Days Calculator", link: "/days", icon: <FiClock /> },
    { title: "Life Time", link: "/lifetime", icon: <FiClock /> }
  ]
};

const ExplorePage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [
    {
      name: "Interactive Art",
      icon: <FiHeart />,
      count: "14 experiences",
      description: "Pieces that respond to your interaction"
    },
    {
      name: "Health & Wellness",
      icon: <FiImage />,
      count: "7 experiences",
      description: "Quirky Wellness"
    },
    {
      name: "Mini-Tools",
      icon: <FiClock />,
      count: "7 experiences",
      description: "Not-so-useful-but-useful tools"
    },
    {
      name: "Sound & Music",
      icon: <FiMusic />,
      count: "3 experiences",
      description: "Audio-visual experiences"
    }
  ];

  if (selectedCategory) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-orange-50 via-amber-50 to-rose-100 py-12 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="mb-12">
            <button
              onClick={() => setSelectedCategory(null)}
              className="flex items-center gap-2 text-stone-600 hover:text-stone-800 transition-colors"
            >
              <FiArrowLeft />
              <span>Back to Categories</span>
            </button>
          </div>

          <div className="mb-12">
            <h2 className="text-3xl font-serif font-bold text-stone-800 mb-4">
              {selectedCategory}
            </h2>
            <div className="h-px w-32 bg-gradient-to-r from-stone-400 to-transparent" />
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6"
          >
            {collections[selectedCategory].map((item, index) => (
              <motion.div
                key={item.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <GameCard {...item} />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-amber-50 to-rose-100 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-serif font-bold text-stone-800 mb-4">Explore Experiences</h1>
          <p className="text-stone-600">Discover our collection of interactive art and experiments</p>
        </div>

        {/* Featured Experience */}
        <div className="mb-16">
          <h2 className="text-2xl font-serif text-stone-800 mb-6">Featured Experience</h2>
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="bg-white/30 backdrop-blur-md rounded-xl p-8 shadow-lg cursor-pointer"
          >
            <Link to="/matrix" className="block">
              <div className="flex flex-col md:flex-row gap-8 items-center">

                <div className="w-full md:w-1/2">
                  <h3 className="text-2xl font-serif text-stone-800 mb-4">Matrix Rain</h3>
                  <p className="text-stone-600 mb-6">A unique take on the classic digital rain effect, with customizable colors and interactive elements.</p>
                  <button
                    className="px-6 py-2 bg-stone-900 text-white rounded-full hover:bg-stone-800 transition-colors"
                  >
                    Try Now
                  </button>
                </div>
              </div>
            </Link>
          </motion.div>
        </div>

        {/* Categories */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {categories.map((category) => (
            <motion.div
              key={category.name}
              whileHover={{ scale: 1.02 }}
              className="bg-white/20 backdrop-blur-sm rounded-xl p-6 border border-white/30 cursor-pointer"
              onClick={() => setSelectedCategory(category.name)}
            >
              <div className="flex items-center gap-4">
                <div className="text-2xl text-stone-700">{category.icon}</div>
                <div>
                  <h3 className="text-xl font-serif text-stone-800">{category.name}</h3>
                  <p className="text-stone-600 text-sm">{category.description}</p>
                  <p className="text-stone-500 text-xs mt-2">{category.count}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};





// Updated About Page with properly imported FiCpu
const AboutPage = () => {
  const sections = [
    {
      title: "The Vision",
      content: "Azoodle is a digital playground where art meets interaction. Each experience is crafted to create moments of wonder and exploration.",
      icon: <FiStar className="text-3xl text-amber-700" />
    },
    {
      title: "The Technology",
      content: "Built with modern web technologies, our experiences push the boundaries of what's possible in the browser.",
      icon: <FiCpu className="text-3xl text-amber-700" />
    }
  ];

  const features = [
    {
      title: "Interactive Experiences",
      content: "Every project is designed to engage and inspire.",
      icon: <FiMonitor className="text-2xl text-amber-700" />
    },
    {
      title: "Modern Stack",
      content: "Using the latest web technologies for smooth performance.",
      icon: <FiBox className="text-2xl text-amber-700" />
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-amber-50 to-rose-100 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-serif font-bold text-stone-800 mb-4">About Azoodle</h1>
          <p className="text-stone-600">Creating interactive digital experiences</p>
        </div>

        {/* Vision & Technology Sections */}
        <div className="grid md:grid-cols-2 gap-12 mb-16">
          {sections.map((section) => (
            <div
              key={section.title}
              className="bg-white/30 backdrop-blur-sm rounded-xl p-8 border border-white/30"
            >
              <div className="flex items-center gap-4 mb-4">
                {section.icon}
                <h2 className="text-2xl font-serif text-stone-800">{section.title}</h2>
              </div>
              <p className="text-stone-600 leading-relaxed">{section.content}</p>
            </div>
          ))}
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="bg-white/20 backdrop-blur-sm rounded-lg p-6"
            >
              <div className="flex items-center gap-3 mb-2">
                {feature.icon}
                <h3 className="text-lg font-serif text-stone-800">{feature.title}</h3>
              </div>
              <p className="text-stone-600">{feature.content}</p>
            </div>
          ))}
        </div>

        {/* Creator Section */}
        <div className="bg-white/20 backdrop-blur-sm rounded-xl p-8 border border-white/30">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="w-full md:w-1/3">
              <img src="/api/placeholder/300/300" alt="Creator" className="rounded-full shadow-lg" />
            </div>
            <div className="w-full md:w-2/3">
              <h2 className="text-2xl font-serif text-stone-800 mb-4">The Creator</h2>
              <p className="text-stone-600 mb-6">
                A passionate developer and artist exploring the intersection of technology and creativity.
                Each project is an attempt to push the boundaries of web-based interactions and create
                meaningful digital experiences.
              </p>
              <div className="flex gap-4">
                <button className="px-6 py-2 bg-stone-900 text-white rounded-full hover:bg-stone-800 transition-colors">
                  Contact
                </button>
                <button className="px-6 py-2 bg-white/30 text-stone-800 rounded-full hover:bg-white/50 transition-colors">
                  Portfolio
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ExplorePage, AboutPage };