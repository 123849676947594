import React, { useState, useEffect } from 'react';
import { Share2, X } from 'lucide-react';
import html2canvas from 'html2canvas';

const fonts = [
    // System Fonts
    '-apple-system',
    'system-ui',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen-Sans',
    'Ubuntu',
    'Cantarell',
    'Helvetica Neue',
    'Arial',
    'sans-serif',

    // Web Safe Fonts
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Times New Roman',
    'Didot',
    'Georgia',
    'American Typewriter',
    'Andalé Mono',
    'Courier',
    'Lucida Console',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS',

    // Google Fonts (commonly used with React)
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Poppins',
    'Source Sans Pro',
    'Raleway',
    'Inter',
    'Nunito',
    'Ubuntu',
    'Rubik',
    'Work Sans',
    'Mulish',
    'DM Sans',
    'Quicksand',
    'Manrope',
    'Space Grotesk',
    'Outfit',
    'Plus Jakarta Sans',
    'Figtree',
    'Fira Sans',
    'Karla',
    'Libre Franklin',
    'Source Code Pro',
    'IBM Plex Sans',
    'IBM Plex Mono',
    'Inconsolata',
    'JetBrains Mono',
    'Fira Code',
    'PT Sans',
    'Noto Sans',
    'Merriweather',
    'Playfair Display',
    'Lora',
    'Crimson Text',
    'Source Serif Pro',
    'Space Mono',
    'Overpass',
    'Red Hat Display',
    'Red Hat Text',
    'Josefin Sans',
    'Exo 2',
    'Cabin',
    'Public Sans',
    'Be Vietnam Pro',

    // Variable Fonts
    'Inter var',
    'Roboto Flex',
    'Recursive',
    'Source Sans Variable',
    'Fraunces',
    'Epilogue',

    // Modern Sans-serif
    'SF Pro Text',
    'SF Pro Display',
    'New York',
    'Proxima Nova',
    'Circular',
    'Gilroy',
    'Gotham',
    'Avenir Next',
    'Avenir',
    'Futura PT',
    'Brandon Grotesque',
    'Sofia Pro',

    // Technical/Code
    'Cascadia Code',
    'Menlo',
    'Consolas',
    'Courier New',
    'Fira Mono',
    'Ubuntu Mono',

    // Display/Decorative
    'Abril Fatface',
    'Lobster',
    'Pacifico',
    'Dancing Script',
    'Permanent Marker',
    'Caveat',
    'Architects Daughter',
    'Righteous',
    'Fredoka One',
    'Comfortaa',

    // Fallbacks
    'sans-serif',
    'serif',
    'monospace',
    'cursive',
    'fantasy'
];

// Usage example in React:
// const style = {
//     fontFamily: AVAILABLE_FONTS[0]
// };
//
// // Or with multiple fallbacks:
// const styleWithFallbacks = {
//     fontFamily: `${AVAILABLE_FONTS[0]}, ${AVAILABLE_FONTS[1]}, sans-serif`
// };
//
// // Using in Tailwind CSS config:
// // fontFamily: {
// //     sans: AVAILABLE_FONTS,
// //     serif: ['Georgia', 'Times New Roman', 'serif'],
// //     mono: ['Source Code Pro', 'Menlo', 'monospace'],
// // }

// Tooltip component with arrow
const Tooltip = ({ children, position = 'bottom', className = "" }) => {
  const arrowClasses = {
    top: "after:top-full after:border-t-blue-500 after:border-t-8 after:border-x-transparent after:border-x-8 after:border-b-0",
    bottom: "after:bottom-full after:border-b-blue-500 after:border-b-8 after:border-x-transparent after:border-x-8 after:border-t-0"
  };

  return (
    <div className={`
      bg-blue-500 text-white p-4 rounded-lg shadow-lg
      relative after:absolute after:left-1/2 after:-translate-x-1/2
      ${arrowClasses[position]}
      ${className}
    `}>
      {children}
    </div>
  );
};

const FlipCard = ({ font, name, onShare, isHighlighted, onFlip }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    if (onFlip) onFlip();
  };

  return (
    <div className={`group relative h-40 sm:h-48 w-full perspective-1000 ${
      isHighlighted ? 'ring-2 ring-blue-500 ring-offset-2' : ''
    }`}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onShare(font);
        }}
        className="absolute top-2 right-2 p-2 z-10 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 transition-colors"
        aria-label="Share"
      >
        <Share2 className="w-4 h-4" />
      </button>

      <div
        className={`relative w-full h-full transition-transform duration-500 transform-style-preserve-3d cursor-pointer ${
          isFlipped ? 'rotate-y-180' : ''
        }`}
        onClick={handleFlip}
      >
        <div
          id={`font-cell-${font}`}
          className="absolute w-full h-full bg-white rounded-lg shadow-md p-4 sm:p-6 backface-hidden"
        >
          <div className="flex items-center justify-center h-full">
            <div
              style={{ fontFamily: font }}
              className="text-2xl sm:text-3xl text-center"
            >
              {name || 'Your Name Here'}
            </div>
          </div>
        </div>

        <div className="absolute w-full h-full bg-blue-500 text-white rounded-lg shadow-md p-4 sm:p-6 rotate-y-180 backface-hidden">
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-lg sm:text-xl font-bold text-center">{font}</div>
            <div className="mt-2 text-xs sm:text-sm text-center">Click to flip back</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NameDisplay = () => {
  const [name, setName] = useState('');
  const [displayedFonts, setDisplayedFonts] = useState(fonts.slice(0, 12));
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [hasFlipped, setHasFlipped] = useState(false);
  const [hasShared, setHasShared] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);

  useEffect(() => {
    const hasSeenOnboarding = localStorage.getItem('hasSeenFontOnboarding');
    if (hasSeenOnboarding) {
      setShowOnboarding(false);
    }
  }, []);

  const completeOnboarding = () => {
    setShowOnboarding(false);
    localStorage.setItem('hasSeenFontOnboarding', 'true');
  };

  const loadMoreFonts = () => {
    const currentLength = displayedFonts.length;
    const newFonts = fonts.slice(currentLength, currentLength + 12);
    setDisplayedFonts(prev => [...prev, ...newFonts]);
  };

  const shareCell = async (font) => {
    const cell = document.getElementById(`font-cell-${font}`);
    try {
      const canvas = await html2canvas(cell);
      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      const file = new File([blob], `${name}-${font}.png`, { type: 'image/png' });

      if (navigator.share) {
        await navigator.share({
          files: [file],
          title: `${name} in ${font}`,
          text: `Check out "${name}" written in ${font} font!`
        });
      } else {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${name}-${font}.png`;
        link.click();
      }
      setHasShared(true);
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleNameInput = (e) => {
    setName(e.target.value);
    if (onboardingStep === 0 && e.target.value) {
      setOnboardingStep(1);
    }
  };

  const handleFlip = () => {
    setHasFlipped(true);
    if (onboardingStep === 1) {
      setOnboardingStep(2);
    }
  };

  // Render tooltips in a portal container
  const renderTooltip = () => {
    if (!showOnboarding) return null;

    switch (onboardingStep) {
      case 0:
        return (
          <div className="absolute left-0 right-0 flex justify-center z-50">
            <Tooltip position="bottom" className="max-w-md mx-4">
              <div className="flex justify-between items-start gap-4">
                <p>Start by entering your name to see it in different fonts!</p>
                <button onClick={() => setOnboardingStep(1)} className="text-white hover:text-gray-200">
                  <X className="w-4 h-4" />
                </button>
              </div>
            </Tooltip>
          </div>
        );
      case 1:
        return name && !hasFlipped ? (
          <div className="absolute top-48 sm:top-52 left-0 right-0 flex justify-center z-50">
            <Tooltip position="top" className="max-w-md mx-4">
              <div className="flex justify-between items-start gap-4">
                <p>Click any card to see the font name!</p>
                <button onClick={() => setOnboardingStep(2)} className="text-white hover:text-gray-200">
                  <X className="w-4 h-4" />
                </button>
              </div>
            </Tooltip>
          </div>
        ) : null;
      case 2:
        return hasFlipped && !hasShared ? (
          <div className="absolute top-48 sm:top-52 left-0 right-0 flex justify-center z-50">
            <Tooltip position="top" className="max-w-md mx-4">
              <div className="flex justify-between items-start gap-4">
                <p>Use the share button to save or share your favorite fonts!</p>
                <button onClick={completeOnboarding} className="text-white hover:text-gray-200">
                  <X className="w-4 h-4" />
                </button>
              </div>
            </Tooltip>
          </div>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className="w-full bg-gray-100 py-6">
      <div className="max-w-7xl mx-auto px-4 relative">
        {/* Name Input Section */}
        <div className="mb-6 relative">
          <input
            type="text"
            value={name}
            onChange={handleNameInput}
            placeholder="Enter your name"
            className="w-full max-w-xl mx-auto block p-2 text-lg border rounded shadow-sm"
          />
        </div>

        {/* Tooltips */}
        {renderTooltip()}

        {/* Grid Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 mb-6">
          {displayedFonts.map((font, index) => (
            <FlipCard
              key={font}
              font={font}
              name={name}
              onShare={shareCell}
              isHighlighted={showOnboarding && ((onboardingStep === 1 && index === 0) || (onboardingStep === 2 && index === 0))}
              onFlip={handleFlip}
            />
          ))}
        </div>

        {/* Load More Section */}
        <div className="flex justify-center items-center gap-4">
          <span className="text-sm text-gray-600">
            Showing {displayedFonts.length} of {fonts.length} fonts
          </span>
          {displayedFonts.length < fonts.length && (
            <button
              onClick={loadMoreFonts}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors text-sm"
            >
              Load More Fonts
            </button>
          )}
        </div>
      </div>

      <style jsx global>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
        .transform-style-preserve-3d {
          transform-style: preserve-3d;
        }
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
      `}</style>
    </div>
  );
};

export default NameDisplay;