import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  AlertCircle,
  Camera,
  ImageIcon,
  Share2,
  Download,
  X
} from 'lucide-react';

const calculateReelDimensions = () => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  const dimensions = {
    xs: {
      photoWidth: 300,
      photoHeight: 225,
      padding: 16,
      borderWidth: 1,
      borderRadius: 4
    },
    sm: {
      photoWidth: 400,
      photoHeight: 300,
      padding: 24,
      borderWidth: 2,
      borderRadius: 6
    },
    md: {
      photoWidth: 600,
      photoHeight: 450,
      padding: 32,
      borderWidth: 2,
      borderRadius: 8
    },
    lg: {
      photoWidth: 800,
      photoHeight: 600,
      padding: 40,
      borderWidth: 2,
      borderRadius: 8
    }
  };

  let size;
  if (vw < 640) size = 'xs';
  else if (vw < 768) size = 'sm';
  else if (vw < 1024) size = 'md';
  else size = 'lg';

  const maxWidth = vw * 0.9;
  const config = dimensions[size];

  if (config.photoWidth + (config.padding * 2) > maxWidth) {
    const scale = maxWidth / (config.photoWidth + (config.padding * 2));
    config.photoWidth *= scale;
    config.photoHeight *= scale;
    config.padding *= scale;
    config.borderRadius *= scale;
  }

  return {
    ...config,
    shadowBlur: config.borderWidth * 4,
    shadowOffsetX: config.borderWidth,
    shadowOffsetY: config.borderWidth,
    backgroundColor: 'white',
    borderColor: '#e5e7eb',
    shadowColor: 'rgba(0, 0, 0, 0.2)'
  };
};

const PhotoBooth = () => {
  // Refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);

  // State
  const [photos, setPhotos] = useState([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(-1);
  const [countdown, setCountdown] = useState(3);
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [finalReel, setFinalReel] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [isCreatingReel, setIsCreatingReel] = useState(false);
  const [reelDimensions, setReelDimensions] = useState(calculateReelDimensions());

  const filters = [
    { id: 'normal', name: 'Normal', filter: '' },
    { id: 'grayscale', name: 'Grayscale', filter: 'grayscale(100%)' },
    { id: 'sepia', name: 'Sepia', filter: 'sepia(100%)' },
    { id: 'invert', name: 'Invert', filter: 'invert(100%)' }
  ];

  // Resize handler
  useEffect(() => {
    const handleResize = () => {
      setReelDimensions(calculateReelDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const startCapture = useCallback(() => {
    setIsCapturing(true);
    setShowOnboarding(false);
    setCurrentPhotoIndex(0);
    setPhotos([]);
    setError(null);
    setFinalReel(null);

    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'user',
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    })
    .then(stream => {
      if (videoRef.current) {
        streamRef.current = stream;
        videoRef.current.srcObject = stream;

        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play()
            .then(() => {
              setIsCameraReady(true);
            })
            .catch(error => {
              throw new Error('Failed to start video playback');
            });
        };
      }
    })
    .catch(error => {
      console.error('Camera initialization error:', error);
      setError("Failed to start camera. Please ensure camera permissions are granted and try again.");
      setIsCapturing(false);
    });
  }, []);

  const stopCamera = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    setIsCapturing(false);
    setIsCameraReady(false);
    setCurrentPhotoIndex(-1);
  }, []);

  // Continue to Part 2...
  const takePhoto = useCallback(() => {
    if (!videoRef.current || !canvasRef.current || isPhotoLoading) return;

    setIsPhotoLoading(true);
    setError(null);

    try {
      const video = videoRef.current;
      const canvas = canvasRef.current;

      // Use high resolution for capture
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d', { alpha: false });
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Apply selected filter
      ctx.filter = selectedFilter ? filters.find(f => f.id === selectedFilter)?.filter || 'none' : 'none';

      // Capture frame
      ctx.drawImage(video, 0, 0);

      const photoData = canvas.toDataURL('image/jpeg', 1.0);
      const uniquePhoto = `${photoData}?t=${Date.now()}`;

      setPhotos(prevPhotos => [...prevPhotos, uniquePhoto]);
      setCurrentPhotoIndex(prev => prev + 1);

      setTimeout(() => {
        setIsPhotoLoading(false);
        setCountdown(3);
      }, 500);

    } catch (error) {
      console.error('Photo capture error:', error);
      setError("Failed to capture photo. Please try again.");
      setIsPhotoLoading(false);
      stopCamera();
    }
  }, [selectedFilter, filters, stopCamera, isPhotoLoading]);

  const createReel = useCallback(async () => {
    if (photos.length !== 4) {
      setError("Need 4 photos to create a reel");
      return;
    }

    setIsCreatingReel(true);
    setError(null);

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
        img.src = src.split('?')[0]; // Remove timestamp
      });
    };

    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d', { alpha: false });

      const {
        photoWidth,
        photoHeight,
        padding: photoPadding,
        borderWidth,
        borderRadius,
        shadowBlur,
        shadowOffsetX,
        shadowOffsetY,
        backgroundColor,
        borderColor,
        shadowColor
      } = reelDimensions;

      const totalHeight = (photoHeight * 4) + (photoPadding * 5);
      canvas.width = photoWidth + (photoPadding * 2);
      canvas.height = totalHeight;

      // Fill background
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Load and draw each photo
      for (let i = 0; i < photos.length; i++) {
        try {
          const img = await loadImage(photos[i]);
          const y = i * (photoHeight + photoPadding) + photoPadding;
          const x = photoPadding;

          // Draw shadow
          ctx.shadowColor = shadowColor;
          ctx.shadowBlur = shadowBlur;
          ctx.shadowOffsetX = shadowOffsetX;
          ctx.shadowOffsetY = shadowOffsetY;

          // Draw photo container
          ctx.beginPath();
          ctx.roundRect(
            x - borderWidth * 2,
            y - borderWidth * 2,
            photoWidth + borderWidth * 4,
            photoHeight + borderWidth * 4,
            borderRadius
          );
          ctx.fillStyle = 'white';
          ctx.fill();

          // Reset shadow
          ctx.shadowColor = 'transparent';
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;

          // Draw image with rounded corners
          ctx.save();
          ctx.beginPath();
          ctx.roundRect(
            x,
            y,
            photoWidth,
            photoHeight,
            borderRadius - borderWidth
          );
          ctx.clip();
          ctx.drawImage(img, x, y, photoWidth, photoHeight);
          ctx.restore();

          // Add border
          ctx.strokeStyle = borderColor;
          ctx.lineWidth = borderWidth;
          ctx.beginPath();
          ctx.roundRect(
            x,
            y,
            photoWidth,
            photoHeight,
            borderRadius - borderWidth
          );
          ctx.stroke();
        } catch (error) {
          throw new Error(`Failed to process photo ${i + 1}`);
        }
      }

      const reelData = canvas.toDataURL('image/jpeg', 0.9);
      setFinalReel(reelData);
    } catch (error) {
      console.error('Reel creation error:', error);
      setError(error.message || "Failed to create photo reel. Please try again.");
    } finally {
      setIsCreatingReel(false);
    }
  }, [photos, reelDimensions]);

  useEffect(() => {
    let timeoutId;

    if (isCameraReady && currentPhotoIndex >= 0 && currentPhotoIndex < 4) {
      if (countdown > 0) {
        timeoutId = setTimeout(() => setCountdown(prev => prev - 1), 1000);
      } else {
        takePhoto();
      }
    } else if (currentPhotoIndex >= 4) {
      stopCamera();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isCameraReady, currentPhotoIndex, countdown, takePhoto, stopCamera]);

  const shareReel = useCallback(async () => {
    if (!finalReel) return;

    try {
      if (!navigator.share) {
        throw new Error("Sharing is not supported on your device. Try downloading instead.");
      }

      const response = await fetch(finalReel);
      const blob = await response.blob();
      const file = new File([blob], 'photo-booth-reel.jpg', { type: 'image/jpeg' });

      await navigator.share({
        files: [file],
        title: 'My Photo Booth Reel',
        text: 'Check out my awesome photo booth reel!'
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        setError(error.message || "Failed to share reel. Please try downloading instead.");
      }
    }
  }, [finalReel]);

  const downloadReel = useCallback(() => {
    if (!finalReel) return;

    try {
      const link = document.createElement('a');
      link.href = finalReel;
      link.download = 'photo-booth-reel.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError("Failed to download reel. Please try again.");
    }
  }, [finalReel]);

  // Continue to Part 3 with the render/return...
  return (
    <div className="flex flex-col items-center gap-4 p-4 min-h-screen bg-gray-50">
      {showOnboarding && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-4 sm:p-6 rounded-lg max-w-md mx-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg sm:text-xl font-bold">Welcome to Photo Booth!</h2>
              <button
                onClick={() => setShowOnboarding(false)}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close onboarding"
              >
                <X className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>
            </div>
            <div className="space-y-3 sm:space-y-4">
              <p>Create your own photo strip in 4 easy steps:</p>
              <ol className="list-decimal ml-5 sm:ml-6 space-y-2">
                <li>Select a filter (optional)</li>
                <li>Click "Start Photo Booth" to begin</li>
                <li>Strike a pose for each of the 4 photos</li>
                <li>Create and share your photo strip!</li>
              </ol>
              <p className="text-sm text-gray-600">
                Make sure to allow camera access when prompted.
              </p>
              <button
                onClick={() => setShowOnboarding(false)}
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-xl sm:text-2xl font-bold text-gray-800">Photo Booth</h1>

      {error && (
        <div className="w-full max-w-md bg-red-50 border border-red-200 rounded-lg p-3 sm:p-4 flex gap-2 items-start">
          <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
          <p className="text-red-700 text-sm sm:text-base">{error}</p>
        </div>
      )}

      {!isCapturing ? (
        <div className="flex flex-col items-center gap-4 w-full max-w-md">
          <select
            onChange={(e) => setSelectedFilter(e.target.value)}
            value={selectedFilter || ''}
            className="w-full sm:w-auto px-4 py-2 border rounded-lg bg-white"
          >
            <option value="">Select Filter</option>
            {filters.map(filter => (
              <option key={filter.id} value={filter.id}>{filter.name}</option>
            ))}
          </select>

          <button
            onClick={startCapture}
            className="w-full sm:w-auto flex items-center justify-center gap-2 px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
          >
            <Camera className="w-5 h-5" />
            <span>Start Photo Booth</span>
          </button>
        </div>
      ) : (
        <div
          className="relative w-full rounded-lg overflow-hidden shadow-lg"
          style={{
            maxWidth: `${reelDimensions.photoWidth * 1.5}px`,
            aspectRatio: '4/3'
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            className="w-full h-full object-cover"
            style={{
              filter: selectedFilter ? filters.find(f => f.id === selectedFilter)?.filter : '',
              borderRadius: `${reelDimensions.borderRadius}px`
            }}
          />
          {isCameraReady && currentPhotoIndex < 4 && (
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-4xl sm:text-6xl font-bold text-white bg-black/50 rounded-full w-16 h-16 sm:w-24 sm:h-24 flex items-center justify-center">
                {countdown}
              </span>
            </div>
          )}
        </div>
      )}

      <canvas ref={canvasRef} className="hidden" />

      <div className="w-full max-w-4xl grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
        <div
          className="grid grid-cols-2 gap-2 sm:gap-4"
          style={{
            maxWidth: `${(reelDimensions.photoWidth * 2) + reelDimensions.padding}px`
          }}
        >
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className="relative bg-gray-100 overflow-hidden border border-gray-200"
              style={{
                borderRadius: `${reelDimensions.borderRadius}px`,
                aspectRatio: '4/3'
              }}
            >
              {photos[index] ? (
                <img
                  src={photos[index].split('?')[0]}
                  alt={`Photo ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="absolute inset-0 flex items-center justify-center text-gray-400 text-sm sm:text-base">
                  <span>Photo {index + 1}</span>
                </div>
              )}
              {currentPhotoIndex === index && isPhotoLoading && (
                <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                  <span>Capturing...</span>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center justify-center gap-4">
          {photos.length === 4 && !finalReel && (
            <button
              onClick={createReel}
              disabled={isCreatingReel}
              className="flex items-center gap-2 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ImageIcon className="w-5 h-5" />
              <span>{isCreatingReel ? 'Creating...' : 'Create Reel'}</span>
            </button>
          )}

          {finalReel && (
            <div className="flex flex-col items-center gap-4">
              <div
                className="relative w-full shadow-lg border border-gray-200"
                style={{
                  maxWidth: `${reelDimensions.photoWidth + (reelDimensions.padding * 2)}px`,
                  borderRadius: `${reelDimensions.borderRadius}px`
                }}
              >
                <img
                  src={finalReel}
                  alt="Final Photo Reel"
                  className="w-full h-auto"
                />
              </div>
              <div className="flex flex-wrap gap-4 justify-center">
                <button
                  onClick={shareReel}
                  className="flex items-center gap-2 px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                >
                  <Share2 className="w-5 h-5" />
                  <span>Share Reel</span>
                </button>
                <button
                  onClick={downloadReel}
                  className="flex items-center gap-2 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  <Download className="w-5 h-5" />
                  <span>Download Reel</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotoBooth;