import React, { useEffect, useState } from 'react';

const MatrixRainWithCats = () => {
  const [matrixColumns, setMatrixColumns] = useState([]);
  const [showingCatBreak, setShowingCatBreak] = useState(false);
  const [catUrl, setCatUrl] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);

  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%&*()';
  const trailLength = 30;

  useEffect(() => {
    document.body.classList.add('unique-matrix-body');
    return () => document.body.classList.remove('unique-matrix-body');
  }, []);

  useEffect(() => {
    const numColumns = Math.floor(window.innerWidth / 20);
    const columns = new Array(numColumns).fill(0).map(() => ({
      topPosition: Math.random() * window.innerHeight,
      speed: Math.random() * 3 + 1,
      trail: new Array(trailLength).fill(getRandomChar()),
    }));

    setMatrixColumns(columns);
  }, []);

  const getRandomChar = () => {
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  const moveRain = () => {
    if (showingCatBreak) return;

    setMatrixColumns(columns =>
      columns.map(column => {
        const newTrail = [...column.trail.slice(1), getRandomChar()];
        return {
          ...column,
          topPosition:
            column.topPosition >= window.innerHeight
              ? -20 * trailLength
              : column.topPosition + column.speed,
          trail: newTrail,
        };
      })
    );
  };

  const triggerCatBreak = async () => {
    try {
      const filters = ['gif', 'says/Wake up Neo', ''];
      const randomFilter = filters[Math.floor(Math.random() * filters.length)];
      const timestamp = Date.now();

      setCatUrl(`https://cataas.com/cat/${randomFilter}?t=${timestamp}`);
      setShowingCatBreak(true);

      const duration = 2000 + Math.random() * 2000;
      await new Promise(resolve => setTimeout(resolve, duration));

      setShowingCatBreak(false);
    } catch (error) {
      console.error('Failed to fetch cat:', error);
      setShowingCatBreak(false);
    }
  };

  useEffect(() => {
    const rainInterval = setInterval(moveRain, 100);

    const catBreakInterval = setInterval(() => {
      if (Math.random() < 0.3) {
        triggerCatBreak();
      }
    }, 15000 + Math.random() * 15000);

    return () => {
      clearInterval(rainInterval);
      clearInterval(catBreakInterval);
    };
  }, []);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const matrixContainerStyle = isFullscreen
    ? "fixed inset-0 bg-black overflow-hidden"
    : "relative h-[calc(100vh-120px)] min-h-[800px] max-h-[1000px] bg-black overflow-hidden";

  if (showingCatBreak) {
    return (
      <div className={matrixContainerStyle}>
        <div className="relative w-full max-w-2xl mx-auto">
          <img
            src={catUrl}
            alt="Reality Break Cat"
            className="w-full h-auto shadow-lg rounded-lg transition-opacity duration-500 ease-in-out"
            style={{
              opacity: showingCatBreak ? 1 : 0,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <button
        onClick={toggleFullscreen}
        className="absolute top-4 right-4 z-50 px-4 py-2 bg-green-500 text-black rounded hover:bg-green-400 transition-colors duration-200 font-mono"
      >
        {isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
      </button>
      <div className={matrixContainerStyle}>
        {matrixColumns.map((column, index) => (
          <div
            key={index}
            className="absolute"
            style={{
              left: `${index * 20}px`,
            }}
          >
            {column.trail.map((char, i) => (
              <div
                key={i}
                className="absolute text-green-500 font-mono text-xl"
                style={{
                  top: `${column.topPosition - i * 20}px`,
                  opacity: (trailLength - i) / trailLength,
                  textShadow: '0 0 8px rgba(0, 255, 0, 0.8)',
                }}
              >
                {char}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatrixRainWithCats;