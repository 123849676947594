import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CautionPage = () => {
  const [events, setEvents] = useState([]);
  const [isEarthquake, setIsEarthquake] = useState(false);

  useEffect(() => {
    const fetchAllEvents = async () => {
      try {
        // NASA EONET API (Natural Events)
        const eonetRes = await axios.get(`https://eonet.gsfc.nasa.gov/api/v3/events?api_key=x1qQJ5O7DOtiBlDSf4qgwjIMoZVsQxlDjtcPVEky`);
        const eonetEvents = eonetRes.data.events;

        // NASA DONKI API (Solar Events)
        const donkiRes = await axios.get(`https://api.nasa.gov/DONKI/CME?api_key=x1qQJ5O7DOtiBlDSf4qgwjIMoZVsQxlDjtcPVEky`);
        const donkiEvents = donkiRes.data.map(event => ({
          title: event.note || "Solar Event",
          date: event.startTime,
          type: "Solar"
        }));

        // NASA Asteroid API (Near Earth Objects)
        const asteroidRes = await axios.get(`https://api.nasa.gov/neo/rest/v1/feed?api_key=x1qQJ5O7DOtiBlDSf4qgwjIMoZVsQxlDjtcPVEky`);
        const asteroidEvents = Object.values(asteroidRes.data.near_earth_objects).flat().map(asteroid => ({
          title: asteroid.name,
          date: asteroid.close_approach_data[0]?.close_approach_date,
          type: "Asteroid"
        }));

        // Combine all events from different sources
        const allEvents = [...eonetEvents, ...donkiEvents, ...asteroidEvents];

        // Check if any event is related to an earthquake and trigger the effect
        const earthquakeEvent = eonetEvents.some(event => event.categories.some(cat => cat.title === 'Earthquakes'));
        setIsEarthquake(earthquakeEvent);

        // Update the state with all combined events
        setEvents(allEvents);
      } catch (error) {
        console.error("Error fetching NASA events:", error);
      }
    };

    fetchAllEvents();
  }, []);

  return (
    <div className={`nasa-main-container ${isEarthquake ? 'nasa-earthquake' : 'nasa-warning'}`}>
      <div className="nasa-border-warning"></div>
      <h1>NASA Events</h1>
      <div className="nasa-events-container">
        {events.map((event, index) => (
          <div key={index} className="nasa-event-item">
            <h3>{event.title}</h3>
            <p>{event.date}</p>
            {event.type && <p>Type: {event.type}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CautionPage;