import React, { useState, useEffect, useRef } from 'react';

const Slider = ({ value, onChange, min, max, step }) => (
  <input
    type="range"
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={(e) => onChange([parseInt(e.target.value, 10)])}
    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
  />
);

const Button = ({ onClick, children, className }) => (
  <button
    onClick={onClick}
    className={`py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 ${className}`}
  >
    {children}
  </button>
);

const Metronome = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [bpm, setBpm] = useState(100);
  const [beat, setBeat] = useState(0);

  const timerRef = useRef(null);
  const audioContextRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      const interval = (60 / bpm) * 1000;
      timerRef.current = setInterval(() => {
        setBeat((prevBeat) => (prevBeat + 1) % 4);
        playClick();
      }, interval);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isPlaying, bpm]);

  const playClick = () => {
    if (audioContextRef.current) {
      const oscillator = audioContextRef.current.createOscillator();
      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(beat === 0 ? 1000 : 800, audioContextRef.current.currentTime);

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.setValueAtTime(1, audioContextRef.current.currentTime);
      gainNode.gain.exponentialRampToValueAtTime(0.001, audioContextRef.current.currentTime + 0.1);

      oscillator.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      oscillator.start();
      oscillator.stop(audioContextRef.current.currentTime + 0.1);
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleBpmChange = (newBpm) => {
    setBpm(newBpm[0]);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-80">
        <h1 className="text-3xl font-bold mb-6 text-center">Metronome</h1>

        <div className="flex justify-center mb-6">
          <div className={`w-16 h-16 rounded-full flex items-center justify-center ${beat === 0 ? 'bg-red-500' : 'bg-gray-300'}`}>
            <span className="text-2xl font-bold text-white">{beat + 1}</span>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Tempo: {bpm} BPM</label>
          <Slider
            value={bpm}
            onChange={handleBpmChange}
            max={240}
            min={40}
            step={1}
          />
        </div>

        <Button
          onClick={togglePlay}
          className="w-full flex items-center justify-center"
        >
          <span className="mr-2">{isPlaying ? '⏸' : '▶'}</span>
          {isPlaying ? 'Pause' : 'Play'}
        </Button>
      </div>
    </div>
  );
};

export default Metronome;