import React, { useState, useEffect, useRef } from "react";
import Matter from "matter-js";
import { TrendingUp, Coins, DollarSign } from "lucide-react";
const { Engine, Render, World, Bodies, Runner, Body } = Matter;



// Gold price data constants
const GOLD_PRICE_DATA = {
  2024: {
    base: 65.21,
    volatility: 0.02,
    trend: 0.0001
  },
  2023: 61.45,
  2022: 57.89,
  2021: 56.92,
  2020: 58.97,
  2019: 47.89,
  2018: 41.23,
  2017: 40.51,
  2016: 39.84,
  2015: 37.16,
  2014: 40.72,
  2013: 45.33,
  2012: 53.91,
  2011: 50.23,
  2010: 39.45
};

const YEARS_WITH_EVENTS = {
  2008: "Global Financial Crisis caused gold prices to surge",
  2011: "Peak of post-crisis gold rally",
  2013: "Major gold market correction",
  2020: "COVID-19 pandemic impact on gold prices",
  2024: "Current market with real-time simulation"
};

// Stats Card Component
const StatsCard = ({ title, value, icon, className = '' }) => (
  <div className={`bg-white/90 rounded-lg shadow-lg p-6 backdrop-blur-sm ${className}`}>
    <div className="flex items-center justify-between mb-4">
      <h2 className="text-lg font-semibold text-amber-800">{title}</h2>
      {icon}
    </div>
    <p className="text-3xl font-bold text-amber-900">{value}</p>
  </div>
);

// Year Selector Component
const YearSelector = ({ selectedYear, onYearChange, priceChange }) => (
  <div className="bg-white/90 rounded-lg shadow-lg p-4 mb-8 backdrop-blur-sm pointer-events-auto">
    <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
      <select
        value={selectedYear}
        onChange={(e) => onYearChange(Number(e.target.value))}
        className="w-full sm:w-auto px-4 py-2 rounded-md border-2 border-amber-500
                  focus:border-amber-600 focus:ring-2 focus:ring-amber-400
                  bg-white text-lg font-medium"
      >
        {Object.keys(GOLD_PRICE_DATA)
          .sort((a, b) => b - a)
          .map(year => (
            <option key={year} value={year}>
              {year} {YEARS_WITH_EVENTS[year] ? "★" : ""}
            </option>
        ))}
      </select>
      <div className="text-amber-800 font-medium">
        YoY Change: {priceChange}
      </div>
    </div>
  </div>
);





const GoldFallApp = () => {
  const [totalGoldWeight, setTotalGoldWeight] = useState(0);
  const [goldPrice, setGoldPrice] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [historicalContext, setHistoricalContext] = useState("");

  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const renderRef = useRef(null);

  // Calculate price change
  const calculatePriceChange = () => {
    if (selectedYear <= 2010) return "N/A";
    const previousYear = selectedYear - 1;
    if (!GOLD_PRICE_DATA[previousYear]) return "N/A";

    const currentPrice = typeof GOLD_PRICE_DATA[selectedYear] === 'object'
      ? GOLD_PRICE_DATA[selectedYear].base
      : GOLD_PRICE_DATA[selectedYear];
    const prevPrice = GOLD_PRICE_DATA[previousYear];

    const change = ((currentPrice - prevPrice) / prevPrice * 100).toFixed(1);
    return `${change}%`;
  };

  // Initialize physics engine
  useEffect(() => {
    if (!containerRef.current) return;

    try {
      const engine = Engine.create({ enableSleeping: false });
      const containerBounds = containerRef.current.getBoundingClientRect();

      const render = Render.create({
        element: sceneRef.current,
        engine: engine,
        options: {
          width: containerBounds.width,
          height: containerBounds.height,
          wireframes: false,
          background: 'transparent'
        }
      });

      renderRef.current = render;
      engineRef.current = engine;

      render.canvas.style.position = 'absolute';
      render.canvas.style.top = '0';
      render.canvas.style.left = '0';
      render.canvas.style.width = '100%';
      render.canvas.style.height = '100%';
      render.canvas.style.zIndex = '1';

      // Create boundaries
      const ground = Bodies.rectangle(
        containerBounds.width / 2,
        containerBounds.height - 10,
        containerBounds.width,
        20,
        {
          isStatic: true,
          render: { fillStyle: '#222' }
        }
      );

      const wallThickness = 60;
      const leftWall = Bodies.rectangle(
        -30,
        containerBounds.height / 2,
        wallThickness,
        containerBounds.height,
        { isStatic: true, render: { fillStyle: 'transparent' } }
      );

      const rightWall = Bodies.rectangle(
        containerBounds.width + 30,
        containerBounds.height / 2,
        wallThickness,
        containerBounds.height,
        { isStatic: true, render: { fillStyle: 'transparent' } }
      );

      World.add(engine.world, [ground, leftWall, rightWall]);

      const runner = Runner.create();
      Runner.run(runner, engine);
      Render.run(render);

      const handleResize = () => {
        const newBounds = containerRef.current.getBoundingClientRect();
        render.canvas.width = newBounds.width;
        render.canvas.height = newBounds.height;
        render.options.width = newBounds.width;
        render.options.height = newBounds.height;

        ground.position.x = newBounds.width / 2;
        ground.position.y = newBounds.height - 10;

        leftWall.position.x = -30;
        leftWall.position.y = newBounds.height / 2;
        rightWall.position.x = newBounds.width + 30;
        rightWall.position.y = newBounds.height / 2;

        Body.setVertices(ground, Bodies.rectangle(
          newBounds.width / 2,
          newBounds.height - 10,
          newBounds.width,
          20
        ).vertices);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        Render.stop(render);
        World.clear(engine.world);
        Engine.clear(engine);
        if (render.canvas) {
          render.canvas.remove();
          render.canvas = null;
          render.context = null;
          render.textures = {};
        }
      };
    } catch (error) {
      console.error("Error initializing physics engine:", error);
    }
  }, [containerRef.current]);

  // Update gold price
  useEffect(() => {
    const calculatePrice = () => {
      if (selectedYear === 2024) {
        const yearData = GOLD_PRICE_DATA[selectedYear];
        const basePrice = yearData.base;
        const random = Math.random() * 2 - 1;
        const volatilityEffect = basePrice * yearData.volatility * random;
        const trendEffect = basePrice * yearData.trend * (new Date().getDate());
        return Number((basePrice + volatilityEffect + trendEffect).toFixed(2));
      }
      return GOLD_PRICE_DATA[selectedYear];
    };

    const newPrice = calculatePrice();
    setGoldPrice(newPrice);
    setHistoricalContext(YEARS_WITH_EVENTS[selectedYear] || "");
  }, [selectedYear]);

  // Update total value
  useEffect(() => {
    const newValue = totalGoldWeight * goldPrice;
    setTotalValue(newValue);
  }, [totalGoldWeight, goldPrice]);

  // Add gold coin
  const addGold = (e) => {
    if (!engineRef.current || !renderRef.current || !containerRef.current) return;

    try {
      const containerBounds = containerRef.current.getBoundingClientRect();
      const relativeX = e.clientX - containerBounds.left;
      const relativeY = e.clientY - containerBounds.top;

      if (relativeY < 0 || relativeY > containerBounds.height ||
          relativeX < 0 || relativeX > containerBounds.width) {
        return;
      }

      const scaleX = renderRef.current.options.width / containerBounds.width;
      const scaleY = renderRef.current.options.height / containerBounds.height;

      const x = relativeX * scaleX;
      const y = relativeY * scaleY;

      const coin = Bodies.rectangle(x, y, 40, 5, {
        restitution: 0.5,
        friction: 0.8,
        frictionAir: 0.02,
        angle: Math.random() * Math.PI,
        density: 0.002,
        render: {
          fillStyle: '#ffd700',
          strokeStyle: '#b8860b',
          lineWidth: 2
        }
      });

      setTotalGoldWeight(prev => prev + 1);
      World.add(engineRef.current.world, coin);
    } catch (error) {
      console.error("Error adding gold coin:", error);
    }
  };

  return (
    <div className="h-[calc(100vh-12rem)] relative overflow-hidden bg-gradient-to-br from-amber-50 to-amber-100">
      <div ref={containerRef} className="absolute inset-0">
        <div ref={sceneRef} className="absolute inset-0" />
      </div>

      <div
        className="absolute inset-0 z-10 cursor-pointer"
        onClick={addGold}
      />

      <div className="relative z-20 h-full pointer-events-none">
        <div className="max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8 h-full flex flex-col">
          <div className="text-center mb-8 bg-white/80 rounded-lg p-6 backdrop-blur-sm pointer-events-auto">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-amber-800 mb-4">
              Gold Rush
            </h1>
            <p className="text-lg sm:text-xl text-amber-700 mb-2">
              Click in the play area to collect gold!
            </p>
          </div>

          <div className="flex-1 flex flex-col">
            <YearSelector
              selectedYear={selectedYear}
              onYearChange={setSelectedYear}
              priceChange={calculatePriceChange()}
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 pointer-events-auto">
              <StatsCard
                title="Total Gold"
                value={`${totalGoldWeight} grams`}
                icon={<Coins className="w-6 h-6 text-amber-600" />}
              />
              <StatsCard
                title="Gold Price"
                value={`$${goldPrice.toFixed(2)} / gram`}
                icon={<TrendingUp className="w-6 h-6 text-amber-600" />}
              />
              <StatsCard
                title="Total Value"
                value={`$${totalValue.toFixed(2)}`}
                icon={<DollarSign className="w-6 h-6 text-amber-600" />}
                className="sm:col-span-2 lg:col-span-1"
              />
            </div>

            {historicalContext && (
              <div className="bg-white/80 backdrop-blur-sm border-l-4 border-amber-500 rounded-lg p-4 mb-8 shadow-lg pointer-events-auto">
                <p className="text-amber-800 text-lg">{historicalContext}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldFallApp;
