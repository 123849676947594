import React, {
  useState,
  useCallback,
  useMemo,
  useEffect  // Added this
} from 'react';
import { AlertTriangle, RefreshCw, Shuffle, Save, X } from "lucide-react";
import debounce from 'lodash/debounce';

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

class GenerationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GenerationError';
  }
}

// Error Boundary remains the same
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 bg-red-50 rounded-lg">
          <div className="flex items-center mb-2">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <h2 className="text-lg font-semibold text-red-700">Something went wrong</h2>
          </div>
          <p className="text-red-600 mb-3">
            {this.state.error?.message || 'An unexpected error occurred'}
          </p>
          <button
            onClick={() => {
              this.setState({ hasError: false, error: null });
              if (this.props.onReset) this.props.onReset();
            }}
            className="px-4 py-2 bg-red-100 text-red-700 rounded hover:bg-red-200
                     transition-colors flex items-center"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            Try Again
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const LoadingSpinner = () => (
  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);


// Main Component
const NameMixer = () => {
 const [word1, setWord1] = useState('');
const [word2, setWord2] = useState('');
const [mixedNames, setMixedNames] = useState([]);
const [selectedName, setSelectedName] = useState('');
const [isGenerating, setIsGenerating] = useState(false);
const [error, setError] = useState(null);
const [inputErrors, setInputErrors] = useState({ word1: '', word2: '' });
const [generationStyle, setGenerationStyle] = useState('standard');
const [savedNames, setSavedNames] = useState([]);
const [nameType, setNameType] = useState('general');
const [showSuccess, setShowSuccess] = useState(false);

  // Name types configuration
  const nameTypes = {
    general: { label: 'General Purpose', minLength: 2, maxLength: 30 },
    baby: { label: 'Baby Name', minLength: 2, maxLength: 15 },
    business: { label: 'Business Name', minLength: 3, maxLength: 25 },
    character: { label: 'Character Name', minLength: 2, maxLength: 20 },
    username: { label: 'Username', minLength: 3, maxLength: 15 },
    product: { label: 'Product Name', minLength: 2, maxLength: 20 }
  };

  // Reset error on input change
  useEffect(() => {
    setError(null);
  }, [word1, word2, generationStyle]);

  // Similarity Calculation Function
  const calculateSimilarity = (str1, str2) => {
  // Handle edge cases
  if (!str1 || !str2) return 0;
  if (str1 === str2) return 1;

  const longer = str1.length > str2.length ? str1 : str2;
  const shorter = str1.length > str2.length ? str2 : str1;
  const longerLength = longer.length;

  // If either string is empty, return 0 similarity
  if (longerLength === 0) return 0;

  try {
    const costs = new Array(shorter.length + 1);
    for (let i = 0; i <= shorter.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= longer.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else if (j > 0) {
          let newValue = costs[j - 1];
          if (shorter[i - 1] !== longer[j - 1]) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
      if (i > 0) costs[shorter.length] = lastValue;
    }
    return (longerLength - costs[shorter.length]) / longerLength;
  } catch (error) {
    console.error('Error calculating similarity:', error);
    return 0; // Return 0 similarity on error
  }
};

 const isTooSimilar = useCallback((newName, originalNames) => {
  if (!newName || !originalNames || !Array.isArray(originalNames)) return false;

  try {
    const similar = originalNames.some(original => {
      if (!original) return false;
      const similarity = calculateSimilarity(
        newName.toLowerCase().trim(),
        original.toLowerCase().trim()
      );
      // Relaxed similarity threshold
      return similarity > 0.9; // Changed from 0.8 to 0.9
    });
    return similar;
  } catch (error) {
    console.error('Error checking similarity:', error);
    return false;
  }
}, []);

  // Name Generation Rules
 const nameRules = useMemo(() => ({
  standard: [
    // Basic combination
    (w1, w2) => {
      const part1 = w1.slice(0, Math.ceil(w1.length / 2));
      const part2 = w2.slice(Math.floor(w2.length / 2));
      return part1 + part2;
    },
    // Alternate characters
    (w1, w2) => {
      const result = [];
      const len = Math.max(w1.length, w2.length);
      for (let i = 0; i < len; i++) {
        if (i < w1.length) result.push(w1[i]);
        if (i < w2.length) result.push(w2[i]);
      }
      return result.join('');
    },
    // Start-End
    (w1, w2) => w1.slice(0, 3) + w2.slice(-2),
    // Middle-blend
    (w1, w2) => {
      const mid1 = w1.slice(Math.floor(w1.length / 4), Math.ceil(w1.length * 3/4));
      const mid2 = w2.slice(Math.floor(w2.length / 4), Math.ceil(w2.length * 3/4));
      return mid1 + mid2;
    }
  ],
  creative: [
    // Vowel replacement
    (w1, w2) => {
      const vowels = w2.match(/[aeiou]/gi) || [];
      let result = w1;
      vowels.forEach(v => {
        result = result.replace(/[aeiou]/i, v);
      });
      return result;
    },
    // Reverse blend
    (w1, w2) => {
      const rev1 = w1.split('').reverse().join('');
      const rev2 = w2.split('').reverse().join('');
      return rev1.slice(0, 3) + rev2.slice(0, 3);
    },
    // Alternating syllables
    (w1, w2) => {
      const parts1 = w1.match(/.{1,2}/g) || [];
      const parts2 = w2.match(/.{1,2}/g) || [];
      const result = [];
      const maxLen = Math.max(parts1.length, parts2.length);
      for (let i = 0; i < maxLen; i++) {
        if (i % 2 === 0 && parts1[i]) result.push(parts1[i]);
        else if (parts2[i]) result.push(parts2[i]);
      }
      return result.join('');
    },
    // Consonant swap
    (w1, w2) => {
      const consonants = w2.match(/[^aeiou]/gi) || [];
      let result = w1;
      for (let i = 0; i < Math.min(consonants.length, 2); i++) {
        result = result.replace(/[^aeiou]/i, consonants[i]);
      }
      return result;
    }
  ],
  balanced: [
    // Equal parts
    (w1, w2) => {
      const mid1 = Math.floor(w1.length / 2);
      const mid2 = Math.floor(w2.length / 2);
      return w1.slice(0, mid1) + w2.slice(mid2);
    },
    // Interleaved
    (w1, w2) => {
      const result = [];
      const maxLen = Math.max(w1.length, w2.length);
      for (let i = 0; i < maxLen; i++) {
        if (i < w1.length) result.push(w1[i]);
        if (i < w2.length) result.push(w2[i]);
      }
      return result.join('').slice(0, Math.max(w1.length, w2.length));
    },
    // Pattern based
    (w1, w2) => {
      const pattern = Math.random() > 0.5 ?
        w1.slice(0, 2) + w2.slice(-2) :
        w2.slice(0, 2) + w1.slice(-2);
      return pattern;
    },
    // Length-weighted
    (w1, w2) => {
      const ratio = w1.length / (w1.length + w2.length);
      const part1 = w1.slice(0, Math.floor(w1.length * ratio));
      const part2 = w2.slice(Math.floor(w2.length * (1 - ratio)));
      return part1 + part2;
    }
  ]
}), []);

  // Input validation with debouncing
  const validateInput = useCallback((name, value) => {
    const type = nameTypes[nameType];
    if (!value.trim()) {
      return `${name} is required`;
    }
    if (value.length < type.minLength) {
      return `${name} must be at least ${type.minLength} characters`;
    }
    if (value.length > type.maxLength) {
      return `${name} must be less than ${type.maxLength} characters`;
    }
    if (nameType === 'username') {
      if (!/^[a-zA-Z0-9-_]+$/.test(value)) {
        return `${name} can only contain letters, numbers, hyphens, and underscores`;
      }
    } else {
      if (!/^[a-zA-Z0-9\s-_]+$/.test(value)) {
        return `${name} can only contain letters, numbers, spaces, hyphens, and underscores`;
      }
    }
    return '';
  }, [nameType, nameTypes]);

  const debouncedValidation = useMemo(
    () => debounce((name, value, setter) => {
      const error = validateInput(name, value);
      setter(prev => ({ ...prev, [name.toLowerCase()]: error }));
    }, 300),
    [validateInput]
  );

  const createMixedName = useCallback((word1, word2, style) => {
  try {
    if (!word1 || !word2) {
      throw new ValidationError('Both words are required');
    }

    const rules = nameRules[style] || nameRules.standard;
    if (!rules || !Array.isArray(rules)) {
      throw new GenerationError('Invalid mixing style');
    }

    let attempts = 0;
    let mixedName = '';

    do {
      const rule = rules[Math.floor(Math.random() * rules.length)];
      if (!rule || typeof rule !== 'function') {
        throw new GenerationError('Invalid mixing rule');
      }

      try {
        mixedName = rule(word1.toLowerCase().trim(), word2.toLowerCase().trim());
      } catch (ruleError) {
        console.error('Rule application error:', ruleError);
        mixedName = '';
      }

      attempts++;
      if (attempts > 20) {
        throw new GenerationError('Unable to generate unique name after multiple attempts');
      }
    } while (
      !mixedName ||
      isTooSimilar(mixedName, [word1, word2]) ||
      mixedName === word1 ||
      mixedName === word2
    );

    if (!mixedName) {
      throw new GenerationError('Failed to generate valid name');
    }

    // Apply type-specific formatting
    switch (nameType) {
      case 'username':
        mixedName = mixedName.replace(/\s/g, '_').toLowerCase();
        break;
      case 'business':
        mixedName = mixedName.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        break;
      case 'baby':
        mixedName = mixedName.charAt(0).toUpperCase() +
                   mixedName.slice(1).toLowerCase();
        break;
      default:
        mixedName = mixedName.charAt(0).toUpperCase() + mixedName.slice(1);
    }

    return mixedName;
  } catch (error) {
    if (error instanceof ValidationError || error instanceof GenerationError) {
      throw error;
    }
    throw new GenerationError('Error generating name: ' + error.message);
  }
}, [nameRules, nameType, isTooSimilar]);

  // Generate names
 const generateNames = useCallback(async () => {
  setError(null);
  setMixedNames([]);

  const w1Error = validateInput('First word', word1);
  const w2Error = validateInput('Second word', word2);

  if (w1Error || w2Error) {
    setInputErrors({ word1: w1Error, word2: w2Error });
    return;
  }

  setIsGenerating(true);

  try {
    const newNames = await new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const uniqueNames = new Set();
          let attempts = 0;
          const maxAttempts = 50; // Increased from 20

          // Try all styles if needed
          const allStyles = ['standard', 'creative', 'balanced'];
          let currentStyleIndex = allStyles.indexOf(generationStyle);

          while (uniqueNames.size < 5 && attempts < maxAttempts) {
            // Try current style
            const name = createMixedName(word1, word2, allStyles[currentStyleIndex]);

            if (name && !uniqueNames.has(name)) {
              uniqueNames.add(name);
            }

            attempts++;

            // If we're struggling to generate names, try different styles
            if (attempts % 10 === 0 && uniqueNames.size < 3) {
              currentStyleIndex = (currentStyleIndex + 1) % allStyles.length;
            }
          }

          if (uniqueNames.size < 3) { // Reduced minimum requirement from 5 to 3
            reject(new GenerationError('Unable to generate enough unique names'));
          }

          resolve(Array.from(uniqueNames));
        } catch (error) {
          reject(error);
        }
      }, 0);
    });

    setMixedNames(newNames);
    setSelectedName('');
  } catch (error) {
    setError(error.message);
    setMixedNames([]);
  } finally {
    setIsGenerating(false);
  }
}, [word1, word2, generationStyle, createMixedName, validateInput]);

  // Share as image


  // Handle input changes
  const handleInputChange = useCallback((value, field) => {
    const setter = field === 'word1' ? setWord1 : setWord2;
    setter(value);
    debouncedValidation(
      field === 'word1' ? 'First word' : 'Second word',
      value,
      setInputErrors
    );
  }, [debouncedValidation]);

  // Save name to list
  const saveName = useCallback((name) => {
    setSavedNames(prev => {
      if (prev.includes(name)) {
        return prev;
      }
      return [...prev, name];
    });
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 2000);
  }, []);

  // Remove saved name
  const removeSavedName = useCallback((nameToRemove) => {
    setSavedNames(prev => prev.filter(name => name !== nameToRemove));
  }, []);

  return (
    <ErrorBoundary onReset={() => {
      setWord1('');
      setWord2('');
      setMixedNames([]);
      setError(null);
    }}>
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Name Mixer
        </h1>

        {/* Name Type Selection */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            What are you naming?
          </label>
          <select
            value={nameType}
            onChange={(e) => setNameType(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-300
                     focus:border-blue-300 outline-none"
          >
            {Object.entries(nameTypes).map(([key, { label }]) => (
              <option key={key} value={key}>{label}</option>
            ))}
          </select>
        </div>

        {/* Style Selection */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Mixing Style
          </label>
          <select
            value={generationStyle}
            onChange={(e) => setGenerationStyle(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-300
                     focus:border-blue-300 outline-none"
          >
            <option value="standard">Standard</option>
            <option value="creative">Creative</option>
            <option value="balanced">Balanced</option>
          </select>
        </div>

        {/* Error Display */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
            <div className="flex items-center">
              <AlertTriangle className="h-4 w-4 text-red-500 mr-2" />
              <p className="text-sm text-red-600">{error}</p>
            </div>
          </div>
        )}

        {/* Input Fields */}
        <div className="space-y-4">
          <div className="space-y-1">
            <input
              type="text"
              value={word1}
              onChange={(e) => handleInputChange(e.target.value, 'word1')}
              placeholder="Enter first word"
              className={`w-full p-3 border rounded-md outline-none transition-colors
                ${inputErrors.word1 ? 'border-red-300 bg-red-50' : 'border-gray-300'}
                focus:ring-2 focus:ring-blue-300 focus:border-blue-300`}
            />
            {inputErrors.word1 && (
              <p className="text-xs text-red-500 ml-1">{inputErrors.word1}</p>
            )}
          </div>

          <div className="space-y-1">
            <input
              type="text"
              value={word2}
              onChange={(e) => handleInputChange(e.target.value, 'word2')}
              placeholder="Enter second word"
              className={`w-full p-3 border rounded-md outline-none transition-colors
                ${inputErrors.word2 ? 'border-red-300 bg-red-50' : 'border-gray-300'}
                focus:ring-2 focus:ring-blue-300 focus:border-blue-300`}
            />
            {inputErrors.word2 && (
              <p className="text-xs text-red-500 ml-1">{inputErrors.word2}</p>
            )}
          </div>

          {/* Generate Button */}
          <button
            onClick={generateNames}
            disabled={isGenerating}
            className={`w-full p-3 rounded-md font-medium transition-colors
              flex items-center justify-center
              ${isGenerating ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'}
              text-white`}
          >
            {isGenerating ? (
              <>
                <LoadingSpinner />
                <span className="ml-2">Mixing Names...</span>
              </>
            ) : (
              <>
                <Shuffle className="mr-2 h-5 w-5" />
                Mix Names
              </>
            )}
          </button>

          {/* Generated Names */}
          {mixedNames.length > 0 && (
            <div className="mt-6 space-y-2">
              <p className="font-semibold text-gray-700">Generated Names:</p>
              {mixedNames.map((name, index) => (
                <div key={index} className="flex items-center justify-between">
                  <button
                    onClick={() => setSelectedName(name)}
                    className={`flex-grow p-2 rounded-l-md transition-colors
                      ${selectedName === name
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
                  >
                    {name}
                  </button>
                  <button
                    onClick={() => saveName(name)}
                    className="p-2 bg-purple-100 hover:bg-purple-200 rounded-r-md
                             text-purple-700 transition-colors"
                  >
                    <Save className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Saved Names */}
          {savedNames.length > 0 && (
            <div className="mt-6">
              <h2 className="font-semibold text-gray-700 mb-2">Saved Names:</h2>
              <div className="space-y-2">
                {savedNames.map((name, index) => (
                  <div key={index}
                       className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                    <span className="text-gray-700">{name}</span>
                    <button
                      onClick={() => removeSavedName(name)}
                      className="text-red-500 hover:text-red-600 p-1"
                      aria-label="Remove saved name"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Success Message */}
        {showSuccess && (
          <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2
                        rounded-md shadow-lg animate-fade-in">
            Success!
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default NameMixer;