import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import html2canvas from 'html2canvas';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import './LifeTime.css';

const defaultActivities = [
  { id: 1, name: 'Sleep', hours: 8, color: '#FF6384' },
  { id: 2, name: 'Work/Study', hours: 8, color: '#36A2EB' },
  { id: 3, name: 'Eating', hours: 2, color: '#FFCE56' },
  { id: 4, name: 'Exercise', hours: 1, color: '#4BC0C0' },
  { id: 5, name: 'Social', hours: 2, color: '#9966FF' },
  { id: 6, name: 'Other', hours: 3, color: '#FF9F40' }
];

const defaultAge = 30;
const defaultLifeExpectancy = 80;

const LifeTime = () => {
  const [name, setName] = useState('');
  const [age, setAge] = useState(defaultAge);
  const [lifeExpectancy, setLifeExpectancy] = useState(defaultLifeExpectancy);
  const [activities, setActivities] = useState(defaultActivities);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shareImageUrl, setShareImageUrl] = useState('');
  const [showSharePrompt, setShowSharePrompt] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [newActivityName, setNewActivityName] = useState('');
  const resultsCardRef = useRef(null);
  const shareCardRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateRemainingTime = () => {
    const yearsLeft = lifeExpectancy - age;
    const daysLeft = yearsLeft * 365;
    const hoursLeft = daysLeft * 24;
    const usedHours = activities.reduce((sum, activity) => sum + activity.hours, 0);
    return Math.max(0, hoursLeft - (usedHours * daysLeft));
  };

  const remainingHours = calculateRemainingTime();
  const remainingDays = Math.round(remainingHours / 24);
  const remainingPercentage = (remainingHours / (24 * 365 * (lifeExpectancy - age))) * 100;

  const handleActivityChange = (id, hours) => {
    const newActivities = activities.map(activity =>
      activity.id === id ? { ...activity, hours: Number(hours) } : activity
    );
    const totalHours = newActivities.reduce((sum, activity) => sum + activity.hours, 0);

    if (totalHours > 24) {
      setErrorMessage("You can't have more than 24 hours in a day! Please adjust your activities.");
    } else {
      setErrorMessage('');
      setActivities(newActivities);
    }
  };

  const handleActivityNameChange = (id, newName) => {
    setActivities(activities.map(activity =>
      activity.id === id ? { ...activity, name: newName } : activity
    ));
  };

  const handleAddActivity = () => {
    if (newActivityName.trim() === '') {
      setErrorMessage("Activity name can't be empty.");
      return;
    }
    const newId = Math.max(...activities.map(a => a.id), 0) + 1;
    const newColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
    setActivities([...activities, { id: newId, name: newActivityName, hours: 0, color: newColor }]);
    setNewActivityName('');
    setShowActivityModal(false);
  };

  const handleRemoveActivity = (id) => {
    setActivities(activities.filter(activity => activity.id !== id));
  };

  const handleCalculate = () => {
    if (age === defaultAge) setAge(defaultAge);
    if (lifeExpectancy === defaultLifeExpectancy) setLifeExpectancy(defaultLifeExpectancy);

    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000);
  };

  const handleShare = async () => {
    if (shareCardRef.current) {
      try {
        const canvas = await html2canvas(shareCardRef.current);
        const imageDataUrl = canvas.toDataURL('image/png');
        setShareImageUrl(imageDataUrl);

        if (navigator.share) {
          const blob = await (await fetch(imageDataUrl)).blob();
          const file = new File([blob], 'lifetime-results.png', { type: 'image/png' });
          await navigator.share({
            files: [file],
            title: 'My Life Time Calculation',
            text: 'Check out how many days I have left for my passions and dreams!'
          });
        } else {
          setShowSharePrompt(true);
        }
      } catch (error) {
        console.error('Error sharing', error);
        alert('Sorry, there was an error sharing your results. Please try again.');
      }
    }
  };

  const handleSocialShare = (platform) => {
    const text = `I have ${remainingDays.toLocaleString()} days left for my passions and dreams! Check out my Life Time Calculation.`;
    const url = window.location.href;
    let shareUrl;

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text + ' ' + url)}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  const pieChartData = [
    ...activities.map(activity => ({
      name: activity.name,
      value: activity.hours,
      color: activity.color
    })),
    {
      name: 'Remaining Time',
      value: Math.max(0, 24 - activities.reduce((sum, activity) => sum + activity.hours, 0)),
      color: '#2ECC71'
    }
  ];

  return (
    <div className="lifetime-container">
      <h1>Life Time Visualizer</h1>
      <button className="info-button" onClick={() => setShowInfoModal(true)}>
        ℹ️ How it works
      </button>
      {showInfoModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>How it works</h2>
            <p>
              This tool helps you visualize how much time you have left for your passions and dreams.
              Enter your age and life expectancy, then adjust the sliders to represent how many hours
              you spend on each activity daily. You can add, remove, or rename activities to fit your lifestyle.
              The app will calculate the remaining time you have for other pursuits.
              You can share your results or recalculate at any time.
            </p>
            <button className="close-modal" onClick={() => setShowInfoModal(false)}>Close</button>
          </div>
        </div>
      )}
      <div className="lifetime-content">
        <div className="lifetime-inputs">
          <div className="input-group">
            <label htmlFor="name-input">Your Name:</label>
            <input
              id="name-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
          </div>
          <div className="input-group">
            <label htmlFor="age-input">Your Age:</label>
            <input
              id="age-input"
              type="number"
              value={age}
              onChange={(e) => setAge(Number(e.target.value))}
              min="0"
              max={lifeExpectancy}
            />
          </div>
          <div className="input-group">
            <label htmlFor="expectancy-input">Life Expectancy:</label>
            <input
              id="expectancy-input"
              type="number"
              value={lifeExpectancy}
              onChange={(e) => setLifeExpectancy(Number(e.target.value))}
              min={age}
            />
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="lifetime-activities">
          {activities.map(activity => (
            <div key={activity.id} className="activity-item">
  <input
    type="text"
    value={activity.name}
    onChange={(e) => handleActivityNameChange(activity.id, e.target.value)}
    className="activity-name-input"
  />
  <div className="activity-controls">
    <input
      type="range"
      min="0"
      max="24"
      value={activity.hours}
      onChange={(e) => handleActivityChange(activity.id, Number(e.target.value))}
    />
    <span className="activity-hours">{activity.hours} hours</span>
    <button onClick={() => handleRemoveActivity(activity.id)} className="remove-activity-button">
      ✕
    </button>
  </div>
</div>
          ))}
          <button onClick={() => setShowActivityModal(true)} className="add-activity-button">
            + Add Activity
          </button>
        </div>
        <button className="calculate-button" onClick={handleCalculate}>Recalculate My Time</button>
      </div>
      <div className="results-card" ref={resultsCardRef}>
        <h2>Your Remaining Time for Passions & Dreams</h2>
        <div className="time-bar">
          <div
            className="time-remaining"
            style={{ width: `${remainingPercentage}%` }}
          ></div>
        </div>
        <div className="time-breakdown">
          <div className="time-item">
            <span className="time-value">{remainingDays.toLocaleString()}</span>
            <span className="time-label">Days</span>
          </div>
        </div>
        <div className="pie-chart-container">
          <h3>Daily Time Allocation</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={(entry) => `${entry.name}: ${entry.value}h`}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <button className="share-button" onClick={handleShare}>Share My Results</button>
      </div>
      {showActivityModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Add New Activity</h2>
            <input
              type="text"
              value={newActivityName}
              onChange={(e) => setNewActivityName(e.target.value)}
              placeholder="Enter activity name"
              className="new-activity-input"
            />
            <div className="modal-buttons">
              <button onClick={handleAddActivity} className="add-button">Add</button>
              <button onClick={() => setShowActivityModal(false)} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showSharePrompt && (
        <div className="modal-overlay">
          <div className="modal-content share-prompt">
            <h2>Share Your Results</h2>
            <p>Your image has been generated. Here are the steps to share:</p>
            <ol>
              <li>Right-click (or long-press on mobile) the image below and select "Save Image"</li>
              <li>Go to your preferred social media platform</li>
              <li>Create a new post and upload the saved image</li>
              <li>Add a caption about your Life Time Calculation</li>
              <li>Post and inspire others!</li>
            </ol>
            <img src={shareImageUrl} alt="Life Time Calculation Results" className="share-image" />
            <div className="social-share-buttons">
              <button onClick={() => handleSocialShare('twitter')}>Share on Twitter</button>
              <button onClick={() => handleSocialShare('facebook')}>Share on Facebook</button>
              <button onClick={() => handleSocialShare('whatsapp')}>Share on WhatsApp</button>
            </div>
            <button className="close-modal" onClick={() => setShowSharePrompt(false)}>Close</button>
          </div>
        </div>
      )}
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.1}
        />
      )}
      {/* Hidden shareable card */}
      <div className="share-card" ref={shareCardRef}>
        <div className="share-card-content">
          <h2>{name ? `${name}'s Life Time Calculation` : 'Life Time Calculation'}</h2>
          <p className="share-card-message">
            {name ? `${name} has` : 'I have'}
          </p>
          <p className="share-card-days">{remainingDays.toLocaleString()}</p>
          <p className="share-card-message">days left for passions and dreams!</p>
        </div>
      </div>
    </div>
  );
};

export default LifeTime;