import React, { useState, useRef, useEffect } from 'react';

const Slider = ({ min, max, value, onChange, label }) => (
  <div className="flex flex-col">
    <label>{label}: {value}</label>
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      className="w-full"
    />
  </div>
);

const Input = ({ value, onChange, placeholder }) => (
  <input
    type="text"
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className="border border-gray-300 rounded px-2 py-1 w-full"
  />
);

const Button = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
  >
    {children}
  </button>
);

const ImageEditor = () => {
  const [image, setImage] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [borderWidth, setBorderWidth] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const [hue, setHue] = useState(0);
  const [grainAmount, setGrainAmount] = useState(0);
  const [watermarkText, setWatermarkText] = useState('');
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      imageRef.current = new Image();
      imageRef.current.onload = () => {
        const { width, height } = calculateAspectRatioFit(
          imageRef.current.width,
          imageRef.current.height,
          800,
          600
        );
        setCanvasSize({ width, height });
        setImage(event.target.result);
      };
      imageRef.current.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };

  const applyEffects = () => {
    if (!canvasRef.current || !imageRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Apply border
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw image
    const drawWidth = canvas.width - 2 * borderWidth;
    const drawHeight = canvas.height - 2 * borderWidth;
    ctx.drawImage(imageRef.current, borderWidth, borderWidth, drawWidth, drawHeight);

    // Apply filters
    ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) hue-rotate(${hue}deg)`;
    ctx.drawImage(canvas, 0, 0);
    ctx.filter = 'none';

    // Apply grain effect
    if (grainAmount > 0) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;
      for (let i = 0; i < pixels.length; i += 4) {
        const noise = (Math.random() - 0.5) * grainAmount;
        pixels[i] += noise;
        pixels[i + 1] += noise;
        pixels[i + 2] += noise;
      }
      ctx.putImageData(imageData, 0, 0);
    }

    // Add watermark
    if (watermarkText) {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
      ctx.font = '20px Arial';
      ctx.fillText(watermarkText, 10, canvas.height - 10);
    }
  };

  useEffect(() => {
    if (image) {
      applyEffects();
    }
  }, [image, aspectRatio, borderWidth, brightness, saturation, hue, grainAmount, watermarkText, canvasSize]);

  return (
    <div className="p-4">
      <input type="file" onChange={handleImageUpload} accept="image/*" className="mb-4" />
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        className="mb-4 border border-gray-300"
      />
      <div className="space-y-4">
        <Slider
          min={0.5}
          max={2}
          step={0.1}
          value={aspectRatio}
          onChange={(e) => setAspectRatio(parseFloat(e.target.value))}
          label="Aspect Ratio"
        />
        <Slider
          min={0}
          max={50}
          value={borderWidth}
          onChange={(e) => setBorderWidth(parseInt(e.target.value))}
          label="Border Width"
        />
        <Slider
          min={0}
          max={200}
          value={brightness}
          onChange={(e) => setBrightness(parseInt(e.target.value))}
          label="Brightness"
        />
        <Slider
          min={0}
          max={200}
          value={saturation}
          onChange={(e) => setSaturation(parseInt(e.target.value))}
          label="Saturation"
        />
        <Slider
          min={0}
          max={360}
          value={hue}
          onChange={(e) => setHue(parseInt(e.target.value))}
          label="Hue"
        />
        <Slider
          min={0}
          max={50}
          value={grainAmount}
          onChange={(e) => setGrainAmount(parseInt(e.target.value))}
          label="Grain Effect"
        />
        <Input
          value={watermarkText}
          onChange={(e) => setWatermarkText(e.target.value)}
          placeholder="Watermark text"
        />
        <Button onClick={applyEffects}>Apply Effects</Button>
      </div>
    </div>
  );
};

export default ImageEditor;