import React, { useState, useEffect } from 'react';

const MovingRockApp = () => {
  // Center initial position
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [input, setInput] = useState('');
  const [gradientAngle, setGradientAngle] = useState(0);
  const [showTutorial, setShowTutorial] = useState(true);
  const [message, setMessage] = useState('');
  const [rockEmoji, setRockEmoji] = useState('🪨');
  const [effect, setEffect] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  // Holiday checks
  const getSpecialDay = () => {
    const today = new Date();
    const month = today.getMonth();
    const date = today.getDate();

    if (month === 11 && date === 25) return 'christmas';
    if (month === 9 && date === 31) return 'halloween';
    if (month === 11 && date === 31) return 'newYear';
    if (month === 10 && date === 7) return 'electionDay';
    return 'normal';
  };

  const getSeasonalRock = () => {
    const special = getSpecialDay();
    switch (special) {
      case 'christmas':
        return '🪨\n🎅';
      case 'halloween':
        return '🪨\n🎃';
      case 'newYear':
        return '🪨\n🎉';
      case 'electionDay':
        return '🪨\n🎩';
      default:
        return '🪨';
    }
  };

  // Modified movement function to keep rock more centered
  const moveRock = () => {
    // Smaller range for more centered movement (30% range instead of 80%)
    const centerOffset = 15; // This creates a 30% movement range
    const newX = 50 + (Math.random() * 2 - 1) * centerOffset; // 35-65 range
    const newY = 50 + (Math.random() * 2 - 1) * centerOffset; // 35-65 range
    setPosition({ x: newX, y: newY });
  };

  useEffect(() => {
    setRockEmoji(getSeasonalRock());

    const interval = setInterval(() => {
      setGradientAngle((prevAngle) => (prevAngle + 1) % 360);
    }, 50);

    const handleKeyDown = (e) => {
      if (e.key.length === 1 || e.key === 'Backspace') {
        setInput((prev) => {
          if (e.key === 'Backspace') {
            return prev.slice(0, -1);
          }
          return prev.length < 30 ? prev + e.key : prev;
        });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      clearInterval(interval);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const processSpecialMessages = (text) => {
    const lowerText = text.toLowerCase();

    const responses = {
      'i love you': { message: '❤️ I love you too!', effect: '💝' },
      'sorry': { message: 'It\'s okay! *hugs*', effect: '🤗' },
      'thanks': { message: 'You\'re welcome!', effect: '😊' },
      'thank you': { message: 'You\'re welcome!', effect: '😊' },
      'happy birthday': { message: 'Thank you! Have some cake!', effect: '🎂' },
      'excuse me': { message: '*burp*', effect: '💨' },
      'please': { message: 'Moving! 🏃‍♂️', effect: '✨' },
    };

    for (const [key, response] of Object.entries(responses)) {
      if (lowerText.includes(key)) {
        return response;
      }
    }
    return null;
  };

  useEffect(() => {
    const response = processSpecialMessages(input);

    if (response) {
      setMessage(response.message);
      setEffect(response.effect);
      setIsAnimating(true);

      if (input.toLowerCase().includes('please')) {
        moveRock(); // Use the new moveRock function
      }

      if (input.toLowerCase().includes('excuse me')) {
        const startPos = position;
        // Smaller shake for centered feeling
        const shakeSequence = [
          { x: startPos.x - 1, y: startPos.y },
          { x: startPos.x + 1, y: startPos.y },
          { x: startPos.x - 0.5, y: startPos.y },
          { x: startPos.x + 0.5, y: startPos.y },
          startPos
        ];

        let step = 0;
        const shakeInterval = setInterval(() => {
          if (step < shakeSequence.length) {
            setPosition(shakeSequence[step]);
            step++;
          } else {
            clearInterval(shakeInterval);
          }
        }, 100);
      }

      setInput('');

      setTimeout(() => {
        setMessage('');
        setEffect('');
        setIsAnimating(false);
      }, 2000);
    }
  }, [input]);

  return (
    <div
      style={{
        background: `linear-gradient(${gradientAngle}deg, #ff7e5f, #feb47b)`,
        transition: 'background 0.5s ease',
        minHeight: '100vh',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{
        width: '100%',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
        {showTutorial && (
          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(8px)',
            padding: '1rem',
            borderRadius: '0.5rem',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            color: 'white',
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3 style={{ fontWeight: 'bold' }}>How to Play</h3>
              <button
                onClick={() => setShowTutorial(false)}
                style={{ color: 'white' }}
              >
                ✕
              </button>
            </div>
            <ul style={{ marginTop: '0.5rem', fontSize: '0.875rem' }}>
              <li>• Type to interact with the rock</li>

            </ul>
          </div>
        )}

        {/* Game Container */}
        <div style={{
          width: '100%',
          height: '400px',
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          overflow: 'hidden',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          border: '4px solid #e5e7eb',
          position: 'relative',
        }}>
          {/* Game Area */}
          <div style={{
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to bottom, #e0f2fe, #bfdbfe)',
          }}>
            {/* Rock with centered positioning */}
            <div
              style={{
                position: 'absolute',
                left: `${position.x}%`,
                top: `${position.y}%`,
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'all 0.3s ease-in-out',
                animation: isAnimating ? 'bounce 0.5s infinite' : 'none',
              }}
            >
              {/* Message Bubble */}
              {message && (
                <div style={{
                  position: 'absolute',
                  top: '-2rem',
                  whiteSpace: 'nowrap',
                  backgroundColor: 'white',
                  padding: '0.25rem 0.75rem',
                  borderRadius: '999px',
                  fontSize: '0.875rem',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #e5e7eb',
                  zIndex: 10,
                }}>
                  {message}
                </div>
              )}

              {/* Rock Emoji */}
              <div style={{
                fontSize: '4rem',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                lineHeight: '1.2',
              }}>
                {rockEmoji}
              </div>

              {/* Effect */}
              {effect && (
                <div style={{
                  position: 'absolute',
                  right: '-1.5rem',
                  top: '-0.5rem',
                  fontSize: '2rem',
                  animation: 'fadeOut 1s forwards',
                }}>
                  {effect}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Input Display */}
        <div style={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(8px)',
          padding: '1rem',
          borderRadius: '0.5rem',
          border: '1px solid rgba(255, 255, 255, 0.3)',
          color: 'white',
        }}>
          <p style={{ fontSize: '0.875rem', opacity: 0.7, marginBottom: '0.25rem' }}>Your message:</p>
          <p style={{ fontFamily: 'monospace', minHeight: '1.5em' }}>{input || 'Start typing...'}</p>
        </div>

        {/* Help Button */}
        <button
          onClick={() => setShowTutorial(true)}
          style={{
            position: 'fixed',
            bottom: '1rem',
            right: '1rem',
            width: '2.5rem',
            height: '2.5rem',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '9999px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          ?
        </button>
      </div>

      <style>{`
        @keyframes bounce {
          0%, 100% { transform: translate(-50%, -50%) scale(1); }
          50% { transform: translate(-50%, -50%) scale(1.1); }
        }
        @keyframes fadeOut {
          0% { opacity: 1; }
          100% { opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default MovingRockApp;