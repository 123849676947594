import React, { useState, useRef, useEffect } from 'react';
import anime from 'animejs';
import loadImage from 'blueimp-load-image';
import html2canvas from 'html2canvas';

const BanksyShredder = () => {
  const [image, setImage] = useState(null);
  const [text, setText] = useState('');
  const [polaroid, setPolaroid] = useState(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const shredding = useRef(null);
  const dropping = useRef(null);
  const pieceRef = useRef(null);

  useEffect(() => {
    if (image) {
      shred();
    }
  }, [image]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    loadImage(
      file,
      (canvas) => {
        const url = canvas.toDataURL('image/jpeg');
        setImage(url);
        updateShredderImage(url);
      },
      {
        canvas: true,
        crop: true,
        maxHeight: 566,
        maxWidth: 392,
        orientation: true
      }
    );
  };

  const handleTextChange = (e) => {
    const words = e.target.value.split(' ');
    if (words.length <= 5) {
      setText(e.target.value);
    }
  };

  const updateShredderImage = (url) => {
    document.getElementById('original').style.backgroundImage = `url(${url})`;
    const shreds = Array.from(document.getElementsByClassName('shred'));
    shreds.forEach(element => {
      element.style.backgroundImage = `url(${url})`;
    });
    document.getElementById('original').style.height = '100%';
    document.getElementById('painting').style.transform = 'translateY(0)';
  };

  const shred = () => {
    if (shredding.current) shredding.current.pause();
    if (dropping.current) dropping.current.pause();

    shredding.current = anime({
      targets: '#original',
      height: 0,
      duration: 10000,
      easing: 'linear'
    });

    dropping.current = anime({
      targets: '#painting',
      translateY: '101%',
      duration: 10000,
      easing: 'linear',
      update: function(anim) {
        if (anim.progress >= 50 && !polaroid) {
          createPolaroid();
        }
      }
    });
  };

  const createPolaroid = () => {
    const canvas = canvasRef.current;
    if (canvas && pieceRef.current) {
      html2canvas(pieceRef.current, {
        scale: 2,
        logging: true,
        backgroundColor: null,
        windowWidth: pieceRef.current.offsetWidth,
        windowHeight: pieceRef.current.offsetHeight
      }).then(pieceCanvas => {
        const ctx = canvas.getContext('2d');

        // Draw background
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Calculate aspect ratio to fit the entire piece in the polaroid
        const pieceAspect = pieceCanvas.width / pieceCanvas.height;
        const canvasAspect = canvas.width / (canvas.height * 0.8); // Leave room for text
        let drawWidth, drawHeight;

        if (pieceAspect > canvasAspect) {
          drawWidth = canvas.width;
          drawHeight = canvas.width / pieceAspect;
        } else {
          drawHeight = canvas.height * 0.8;
          drawWidth = drawHeight * pieceAspect;
        }

        const drawX = (canvas.width - drawWidth) / 2;
        const drawY = (canvas.height * 0.8 - drawHeight) / 2;

        // Draw the captured piece (including frame)
        ctx.drawImage(pieceCanvas, drawX, drawY, drawWidth, drawHeight);

        // Draw text
        ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
        ctx.font = '20px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(text, canvas.width / 2, canvas.height - 30);

        // Create polaroid effect
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 20;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);

        setPolaroid(canvas.toDataURL());
      });
    }
  };

  const shareImage = () => {
    if (polaroid) {
      const link = document.createElement('a');
      link.download = 'shredded-polaroid.png';
      link.href = polaroid;
      link.click();
    }
  };

  return (
    <div className="banksy-container">
      <main id="banksy">


        <div id="piece" ref={pieceRef} onClick={() => fileInputRef.current.click()}>
          <div id="holder">
            <div id="painting">
              <div id="shredded">
                {[...Array(11)].map((_, i) => (
                  <div key={i} className="shred"></div>
                ))}
              </div>
              <div id="original"></div>
            </div>
          </div>
          <div id="frame"></div>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </main>
      <div className="controls">
        <input
          type="text"
          placeholder="Enter text (max 5 words)"
          value={text}
          onChange={handleTextChange}
          className="text-input"
        />
        <button onClick={shareImage} disabled={!polaroid} className="share-button">
          Share Polaroid
        </button>
      </div>
      <canvas ref={canvasRef} width="400" height="500" style={{ display: 'none' }} />
      <style jsx>{`
        .banksy-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          height: 100vh;
          width: 100vw;
          overflow: hidden;
          padding-top: 5vh;
        }
        #banksy {
          position: relative;
          width: 100%;
          height: 80vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        #piece {
          box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
          cursor: pointer;
          height: 50vh;
          max-height: 820px;
          position: relative;
          width: calc(50vh * 0.774);
          transform: translateY(-10%);
        }
        #frame {
          background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/141041/banksy-frame.png);
          background-size: cover;
          height: 100%;
          position: absolute;
          width: 100%;
        }
        #holder {
          background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/141041/banksy-back.jpg);
          background-size: cover;
          height: calc(50vh * 0.69);
          left: 18.9%;
          position: absolute;
          top: 15.4%;
          width: calc(50vh * 0.774 * 0.617);
        }
        #painting {
          height: 100%;
          position: absolute;
          transform: translateY(0%);
          width: 100%;
        }
        #original, .shred {
          background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/141041/anne-painting.jpg);
          background-size: cover;
        }
        #original {
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }
        #shredded {
          bottom: 0;
          display: grid;
          grid-column-gap: 3px;
          grid-template-columns: repeat(11, 1fr);
          height: 100%;
          position: absolute;
          width: 100%;
        }
        #shredded .shred {
          background-position: top center;
        }
        #shredded .shred:nth-child(1) { background-position: 0% 0%; }
        #shredded .shred:nth-child(2) { background-position: 10% 0%; }
        #shredded .shred:nth-child(3) { background-position: 20% 0%; }
        #shredded .shred:nth-child(4) { background-position: 30% 0%; }
        #shredded .shred:nth-child(5) { background-position: 40% 0%; }
        #shredded .shred:nth-child(6) { background-position: 50% 0%; }
        #shredded .shred:nth-child(7) { background-position: 60% 0%; }
        #shredded .shred:nth-child(8) { background-position: 70% 0%; }
        #shredded .shred:nth-child(9) { background-position: 80% 0%; }
        #shredded .shred:nth-child(10) { background-position: 90% 0%; }
        #shredded .shred:nth-child(11) { background-position: 100% 0%; }
        header {
          position: absolute;
          top: 2em;
        }
        header a {
          color: black;
        }
        .controls {
          margin-top: 20px;
          display: flex;
          gap: 10px;
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }
        .text-input {
          padding: 5px;
          font-size: 16px;
        }
        .share-button {
          padding: 5px 10px;
          font-size: 16px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default BanksyShredder;