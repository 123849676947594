import React, { useState } from "react";

const TypoApp = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [copied, setCopied] = useState(false);

  const randomizeText = (text) => {
    let result = "";
    const typoChance = 0.1;
    const capitalizeChance = 0.3;
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      if (Math.random() < capitalizeChance) {
        char = char.toUpperCase();
      }
      if (Math.random() < typoChance) {
        const randomChar = String.fromCharCode(
          97 + Math.floor(Math.random() * 26)
        );
        result += randomChar;
      }
      result += char;
    }
    return result;
  };

  const handleChange = (e) => {
    const userInput = e.target.value;
    setInput(userInput);
    const randomizedOutput = randomizeText(userInput);
    setOutput(randomizedOutput);
    setCopied(false);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(output);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <div className="bg-gradient-to-br from-purple-100 to-indigo-100 shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center text-purple-800 mb-6">
          Random Typo Generator
        </h1>
        <textarea
          placeholder="Type something..."
          value={input}
          onChange={handleChange}
          className="w-full p-2 text-lg border-2 border-purple-300 rounded-md focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none mb-4"
          rows={5}
        />
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl font-semibold text-purple-700">Randomized Output:</h3>
          <button
            onClick={copyToClipboard}
            className={`px-3 py-1 rounded text-sm font-medium flex items-center gap-2 ${
              copied
                ? "bg-green-500 text-white"
                : "bg-purple-500 text-white hover:bg-purple-600"
            }`}
          >
            {copied ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                Copied!
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                  <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                </svg>
                Copy
              </>
            )}
          </button>
        </div>
        <div className="bg-white border-2 border-purple-300 rounded-md p-4 min-h-[100px] text-lg break-words">
          {output}
        </div>
        {copied && (
          <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded" role="alert">
            <p className="font-bold">Success</p>
            <p>Text copied to clipboard successfully!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TypoApp;