import React, { useState, useEffect } from 'react';

const WaterLossApp = () => {
  const [year, setYear] = useState(1924);
  const [waterLossPercentage, setWaterLossPercentage] = useState(0);
  const [rate, setRate] = useState(1);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning && year < 2124) {
        setYear(prevYear => {
          const newYear = Math.min(prevYear + rate, 2124);
          const totalYears = 2124 - 1924;
          const yearsPassed = newYear - 1924;
          const newWaterLossPercentage = (yearsPassed / totalYears) * 100;
          setWaterLossPercentage(newWaterLossPercentage);
          return newYear;
        });
      }
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [isRunning, rate, year]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-sky-100 p-4">
      <h1 className="text-4xl font-bold mb-8 text-blue-900">Water Loss Timeline</h1>

      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between mb-2">
          <span className="font-bold">1924</span>
          <span className="font-bold">2124</span>
        </div>
        <div className="h-8 bg-blue-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-blue-600 transition-all duration-300 ease-in-out"
            style={{ width: `${((year - 1924) / (2124 - 1924)) * 100}%` }}
          />
        </div>
        <div className="mt-4 text-center">
          <p className="text-2xl font-bold text-blue-900">Year: {Math.floor(year)}</p>
          <p className="text-xl text-blue-800">Water Loss: {waterLossPercentage.toFixed(1)}%</p>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2 text-blue-900">Simulation Speed:</h3>
        <div className="flex space-x-2">
          {[1, 2, 5, 10].map((speed) => (
            <button
              key={speed}
              onClick={() => setRate(speed)}
              className={`px-4 py-2 rounded ${
                rate === speed ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-800'
              }`}
            >
              {speed}x
            </button>
          ))}
        </div>
      </div>

      <button
        onClick={() => setIsRunning(!isRunning)}
        className="mt-6 bg-blue-600 text-white px-6 py-2 rounded-lg shadow-lg text-lg"
      >
        {isRunning ? 'Pause' : 'Resume'}
      </button>
    </div>
  );
};

export default WaterLossApp;