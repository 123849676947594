import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiCircle, FiClock, FiSun, FiAward, FiCpu, FiBarChart2, FiUser, FiGift, FiGrid, FiHeart, FiMusic } from 'react-icons/fi';

const GameCard = ({ title, link, icon, index }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        y: -5
      }}
      whileTap={{ scale: 0.95 }}
      className="h-full relative group"
    >
      <div className="absolute inset-0 bg-white/50 backdrop-blur-sm rounded-xl transform transition-all duration-300 group-hover:rotate-1" />
      <Link
        to={link}
        className="block h-full rounded-xl overflow-hidden transition-all duration-500 ease-in-out bg-white/10 backdrop-blur-md border border-white/20 relative shadow-lg"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-white/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        <div className="p-6 flex flex-col items-center justify-between h-full relative z-10">
          <motion.div
            className="text-4xl mb-4 text-stone-800 group-hover:text-amber-900 transition-colors duration-300"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            {icon}
          </motion.div>
          <div className="text-center space-y-1">
            <h3 className="font-serif text-xl text-stone-800 tracking-wide group-hover:text-amber-900 transition-colors duration-300">
              {title}
            </h3>
            <div className="h-0.5 w-12 mx-auto bg-gradient-to-r from-transparent via-amber-700/30 to-transparent transform scale-0 group-hover:scale-100 transition-transform duration-300" />
          </div>
          <div className="mt-4 overflow-hidden">
            <span className="inline-block px-4 py-1 rounded-full text-sm font-medium bg-stone-900/80 text-white hover:bg-amber-900/90 transition-all duration-300 transform hover:scale-105">
              Explore
            </span>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

const BorderPattern = () => (
  <div className="fixed inset-0 pointer-events-none">
    {/* Top decorative border */}
    <div className="absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-white/20 to-transparent" />
    <div className="absolute top-0 left-0 w-full overflow-hidden">
      <svg className="w-full" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 0 L100 20 L200 0 L300 20 L400 0 L500 20 L600 0 L700 20 L800 0 L900 20 L1000 0 L1100 20 L1200 0 L1300 20 L1400 0 L1500 20 L1600 0 L1700 20 L1800 0 L1900 20 L2000 0 V-20 H0 Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M0 40 L100 60 L200 40 L300 60 L400 40 L500 60 L600 40 L700 60 L800 40 L900 60 L1000 40 L1100 60 L1200 40 L1300 60 L1400 40 L1500 60 L1600 40 L1700 60 L1800 40 L1900 60 L2000 40"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </div>

    {/* Bottom decorative border */}
    <div className="absolute bottom-0 left-0 w-full overflow-hidden rotate-180">
      <svg className="w-full" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 0 L100 20 L200 0 L300 20 L400 0 L500 20 L600 0 L700 20 L800 0 L900 20 L1000 0 L1100 20 L1200 0 L1300 20 L1400 0 L1500 20 L1600 0 L1700 20 L1800 0 L1900 20 L2000 0 V-20 H0 Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M0 40 L100 60 L200 40 L300 60 L400 40 L500 60 L600 40 L700 60 L800 40 L900 60 L1000 40 L1100 60 L1200 40 L1300 60 L1400 40 L1500 60 L1600 40 L1700 60 L1800 40 L1900 60 L2000 40"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </div>
  </div>
);



const games = [
  { title: "Pi", link: "/pies", icon: <FiCircle /> },
  { title: "LifeTime", link: "/lifetime", icon: <FiClock /> },
  { title: "Gold", link: "/goldfall", icon: <FiCircle /> },

  { title: "DreamCity", link: "/dreams", icon: <FiHeart /> },

  { title: "Human-O-Meter", link: "/humanchallenge", icon: <FiHeart /> },
  { title: "Inherit", link: "/ballsinherit", icon: <FiCircle /> },
  { title: "Matrix", link: "/matrix", icon: <FiCircle /> },
  { title: "Believe", link: "/believe", icon: <FiHeart /> },

  { title: "Luna", link: "/luna", icon: <FiHeart /> },

  { title: "name-mix", link: "/namemix", icon: <FiClock /> },
  { title: "keyboard-music", link: "/keyboardmusic", icon: <FiMusic /> },
  { title: "stubborn-rock", link: "/rock", icon: <FiCircle /> },
  { title: "narc", link: "/narc", icon: <FiCircle /> },
  { title: "days", link: "/days", icon: <FiClock /> },



  { title: "button", link: "/button", icon: <FiCircle /> },

  { title: "rock-paper-scissor", link: "/rockpaperscissor", icon: <FiCircle /> },

  { title: "metronome", link: "/metronome", icon: <FiMusic /> },
  { title: "photobooth", link: "/photobooth", icon: <FiClock /> },

  { title: "Nosie-Level", link: "/noise", icon: <FiMusic /> },
  { title: "Periodic-Table", link: "/periodictable", icon: <FiCircle /> },
  { title: "Reverse Video Player", link: "/reversevideoplayer", icon: <FiClock /> },
  { title: "Golden ratio - o - not", link: "/goldenratio", icon: <FiClock /> },
  { title: "no", link: "/no", icon: <FiHeart /> },
  { title: "Mirror-Text", link: "/mirrortext", icon: <FiCircle /> },
  { title: "Rejected", link: "/rejected", icon: <FiHeart /> },
  { title: "Color-palette", link: "/palette", icon: <FiClock /> },
  { title: "Typo", link: "/typo", icon: <FiCircle /> },
  { title: "Cipher", link: "/cipher", icon: <FiHeart /> },
  { title: "Guts", link: "/guts", icon: <FiHeart /> },
  { title: "Water", link: "/water", icon: <FiCircle /> }

];

const DecorativeCorner = ({ className }) => (
  <div className={`absolute w-24 h-24 ${className}`}>
    <div className="absolute inset-0 border-2 border-amber-700/20 rounded-full transform rotate-45" />
    <div className="absolute inset-2 border-2 border-rose-600/20 rounded-full transform -rotate-45" />
    <div className="absolute inset-4 border-2 border-amber-600/20 rounded-full transform rotate-12" />
  </div>
);

const HomePage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  return (
    <div className="min-h-screen overflow-y-auto py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-orange-50 via-amber-50 to-rose-100 relative">
      <BorderPattern />

      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16 relative"
        >
          <h2 className="text-5xl md:text-6xl font-serif font-bold text-stone-800 mb-4 tracking-wide">
            Artistic Journeys
          </h2>
          <p className="font-sans text-sm uppercase tracking-[0.3em] text-stone-600 mb-6">
            Discover Unique Experiences
          </p>
          <div className="w-32 h-0.5 mx-auto bg-gradient-to-r from-transparent via-stone-400 to-transparent" />
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 md:gap-8 relative"
        >
          {games.map((game, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
            >
              <GameCard {...game} index={index} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default HomePage;