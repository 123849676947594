import React, { useState, useRef, useEffect } from 'react';

const TextPainter = () => {
  const [painting, setPainting] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    console.log('TextPainter component mounted');
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }, []);

  const startPainting = (e) => {
    console.log('Start painting');
    setPainting(true);
    paint(e);
  };

  const stopPainting = () => {
    console.log('Stop painting');
    setPainting(false);
  };

  const paint = (e) => {
    if (!painting) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    console.log(`Painting at (${x}, ${y})`);
    ctx.fillStyle = 'black';
    ctx.font = '16px Arial';
    ctx.fillText('Paint', x, y);
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'lightblue',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <h1 style={{ color: 'black', marginBottom: '20px' }}>Text Painter</h1>
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        style={{
          border: '1px solid black',
          backgroundColor: 'white'
        }}
        onMouseDown={startPainting}
        onMouseUp={stopPainting}
        onMouseMove={paint}
        onMouseLeave={stopPainting}
      />
    </div>
  );
};

export default TextPainter;