import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PlayCircle, PauseCircle, StopCircle } from 'lucide-react';

const HexagonGrid = () => {
  const [hexagons, setHexagons] = useState([]);
  const [activeHexagon, setActiveHexagon] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef(null);
  const audioBuffersRef = useRef({});
  const audioSourceRef = useRef(null);
  const gridRef = useRef(null);

  const headerHeight = 60;
  const footerHeight = 60;

  const initializeAudioContext = useCallback(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
  }, []);

  const generateHexCoordinates = (centerX, centerY, size) => {
    const angles = [0, 60, 120, 180, 240, 300];
    return angles.map(angle => {
      const radian = Math.PI / 180 * angle;
      const x = centerX + size * Math.cos(radian);
      const y = centerY + size * Math.sin(radian);
      return `${x},${y}`;
    }).join(' ');
  };

  useEffect(() => {
    const generateHexagons = () => {
      const width = window.innerWidth;
      const height = window.innerHeight - headerHeight - footerHeight;
      const hexSize = Math.min(width, height) / 8;
      const horizontalSpacing = hexSize * Math.sqrt(3);
      const verticalSpacing = hexSize * 1.5;
      const columns = Math.floor(width / horizontalSpacing);
      const rows = Math.floor(height / verticalSpacing);

      const hexagons = [];
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const x = col * horizontalSpacing + (row % 2) * (horizontalSpacing / 2);
          const y = row * verticalSpacing;
          hexagons.push({
            id: `hex-${row}-${col}`,
            x,
            y,
            size: hexSize,
            sound: `sound${(row * columns + col) % 10 + 1}.mp3`,
            gradient: `gradient-${row * columns + col}`
          });
        }
      }
      return hexagons;
    };

    const handleResize = () => {
      setHexagons(generateHexagons());
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    initializeAudioContext();

    hexagons.forEach((hexagon) => {
      if (!audioBuffersRef.current[hexagon.sound]) {
        fetch(`/sounds/${hexagon.sound}`)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => audioContextRef.current.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            audioBuffersRef.current[hexagon.sound] = audioBuffer;
          })
          .catch(error => console.error('Error loading audio:', error));
      }
    });

    return () => {
      if (audioSourceRef.current) {
        audioSourceRef.current.stop();
        audioSourceRef.current = null;
      }
    };
  }, [hexagons, initializeAudioContext]);

  const playSound = useCallback((soundFile) => {
    if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    }

    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
    }

    const source = audioContextRef.current.createBufferSource();
    source.buffer = audioBuffersRef.current[soundFile];
    source.connect(audioContextRef.current.destination);
    source.loop = true;
    source.start();
    audioSourceRef.current = source;
    setIsPlaying(true);
  }, []);

  const handleHexagonClick = useCallback((hexagon) => {
    initializeAudioContext();
    setActiveHexagon(hexagon.id);
    playSound(hexagon.sound);
  }, [initializeAudioContext, playSound]);

  const handlePlay = useCallback(() => {
    if (activeHexagon) {
      const hexagon = hexagons.find(h => h.id === activeHexagon);
      if (hexagon) {
        playSound(hexagon.sound);
      }
    }
  }, [activeHexagon, hexagons, playSound]);

  const handlePause = useCallback(() => {
    if (audioContextRef.current) {
      audioContextRef.current.suspend();
      setIsPlaying(false);
    }
  }, []);

  const handleStop = useCallback(() => {
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
      audioSourceRef.current = null;
    }
    setIsPlaying(false);
    setActiveHexagon(null);
  }, []);

  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, ${Math.floor(Math.random() * 30) + 60}%)`;
  };

  return (
    <div className="flex flex-col h-screen">
      <header className="h-[60px] bg-blue-500 flex items-center justify-between px-4 text-white text-xl font-bold">
        <span>Delftware Arms Sound Grid</span>
        <div className="flex space-x-4">
          <button onClick={handlePlay} disabled={!activeHexagon || isPlaying}>
            <PlayCircle size={24} className={`${(!activeHexagon || isPlaying) ? 'opacity-50' : 'hover:text-blue-200'}`} />
          </button>
          <button onClick={handlePause} disabled={!isPlaying}>
            <PauseCircle size={24} className={`${!isPlaying ? 'opacity-50' : 'hover:text-blue-200'}`} />
          </button>
          <button onClick={handleStop} disabled={!activeHexagon}>
            <StopCircle size={24} className={`${!activeHexagon ? 'opacity-50' : 'hover:text-blue-200'}`} />
          </button>
        </div>
      </header>
      <div className="flex-grow overflow-hidden" ref={gridRef}>
        <svg width="100%" height="100%">
          <defs>
            {hexagons.map((hexagon) => (
              <radialGradient key={hexagon.gradient} id={hexagon.gradient}>
                <stop offset="0%" stopColor={getRandomColor()} />
                <stop offset="100%" stopColor={getRandomColor()} />
              </radialGradient>
            ))}
          </defs>
          {hexagons.map((hexagon) => (
            <polygon
              key={hexagon.id}
              points={generateHexCoordinates(hexagon.x, hexagon.y, hexagon.size)}
              fill={`url(#${hexagon.gradient})`}
              stroke="#FFFFFF"
              strokeWidth="2"
              onClick={() => handleHexagonClick(hexagon)}
              className={`cursor-pointer transition-transform duration-300 ${
                activeHexagon === hexagon.id ? 'scale-110' : ''
              }`}
            />
          ))}
        </svg>
      </div>
      <footer className="h-[60px] bg-blue-500 flex items-center justify-center text-white">
        Click on hexagons to play sounds
      </footer>
    </div>
  );
};

export default HexagonGrid;