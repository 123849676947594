import React, { useState, useEffect, useRef } from "react";
import Confetti from "react-confetti";

const rejectionTerms = [
  "Social Rejection",
  "Romantic Rejection",
  "Job Rejection",
  "Creative Rejection",
  "Academic Rejection",
  "Loan Denial",
  "Emotional Rejection",
  "Imposter Syndrome",
  "Product Rejection",
  "Dismissal",
  "Friendship Rejection",
  "Pitch Rejection",
  "Manuscript Rejection",
  "Grant Rejection",
  "Audition Rejection",
  "Proposal Rejection",
];

const getOrdinalSuffix = (number) => {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return number + "st";
  }
  if (j === 2 && k !== 12) {
    return number + "nd";
  }
  if (j === 3 && k !== 13) {
    return number + "rd";
  }
  return number + "th";
};

const Firework = ({ top, left, color }) => {
  return (
    <div className="firework" style={{ top, left }}>
      {[...Array(8)].map((_, i) => (
        <div key={i} className="spark" style={{
          backgroundColor: color,
          transform: `rotate(${i * 45}deg) translateY(-15px)`
        }} />
      ))}
    </div>
  );
};

const BuzzerApp = () => {
  const [clicks, setClicks] = useState({});
  const [lastClicked, setLastClicked] = useState({});
  const [streak, setStreak] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [fireworks, setFireworks] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [clickTime, setClickTime] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    const savedClicks = JSON.parse(localStorage.getItem("clicks")) || {};
    const savedLastClicked = JSON.parse(localStorage.getItem("lastClicked")) || {};
    const savedStreak = parseInt(localStorage.getItem("streak")) || 0;

    setClicks(savedClicks);
    setLastClicked(savedLastClicked);
    setStreak(savedStreak);

    const now = new Date();
    const today = now.toDateString();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toDateString();

    // Check if streak should be reset or incremented
    if (Object.values(savedLastClicked).some(date => date === today)) {
      // Streak continues
    } else if (Object.values(savedLastClicked).some(date => date === yesterday)) {
      // New day, increment streak
      const newStreak = savedStreak + 1;
      setStreak(newStreak);
      localStorage.setItem("streak", newStreak.toString());
    } else {
      // Streak broken
      setStreak(0);
      localStorage.setItem("streak", "0");
    }

    const resetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
    const timeUntilReset = resetTime - now;

    const resetTimer = setTimeout(() => {
      localStorage.removeItem("clicks");
      localStorage.removeItem("lastClicked");
      setClicks({});
      setLastClicked({});
    }, timeUntilReset);

    return () => clearTimeout(resetTimer);
  }, []);

  const handleClick = (term) => {
    const today = new Date().toDateString();

    if (lastClicked[term] !== today) {
      const newClickCount = clicks[term] ? clicks[term] + 1 : 1;
      const newClicks = { ...clicks, [term]: newClickCount };
      const newLastClicked = { ...lastClicked, [term]: today };

      setClicks(newClicks);
      setLastClicked(newLastClicked);
      localStorage.setItem("clicks", JSON.stringify(newClicks));
      localStorage.setItem("lastClicked", JSON.stringify(newLastClicked));

      setSelectedTerm(term);
      setClickTime(newClickCount);
      setShowConfetti(true);
      launchFireworks();
      setShowPopup(true);

      // Update streak if it's the first click of the day
      if (!Object.values(lastClicked).includes(today)) {
        const newStreak = streak + 1;
        setStreak(newStreak);
        localStorage.setItem("streak", newStreak.toString());
      }

      setTimeout(() => {
        setShowConfetti(false);
        setFireworks([]);
      }, 5000);
    } else {
      alert("You've already logged a rejection for this category today!");
    }
  };

  const launchFireworks = () => {
    const newFireworks = [];
    for (let i = 0; i < 5; i++) {
      newFireworks.push({
        top: `${Math.random() * 70 + 10}%`,
        left: `${Math.random() * 80 + 10}%`,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`
      });
    }
    setFireworks(newFireworks);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const shareRejection = async () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Set canvas size
      canvas.width = 600;
      canvas.height = 400;

      // Draw background
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw rejection stamp
      ctx.save();
      ctx.translate(300, 200);

      // Outer circle
      ctx.beginPath();
      ctx.arc(0, 0, 150, 0, 2 * Math.PI);
      ctx.fillStyle = '#ff0000';
      ctx.fill();

      // Inner circle
      ctx.beginPath();
      ctx.arc(0, 0, 140, 0, 2 * Math.PI);
      ctx.fillStyle = '#ffffff';
      ctx.fill();

      // Text
      ctx.fillStyle = '#ff0000';
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('REJECTED', 0, -50);
      ctx.fillText('REJECTED', 0, 50);

      // Central text
      ctx.font = 'bold 48px Arial';
      ctx.fillText('REJECTED', 0, 10);

      // Restore context
      ctx.restore();

      // Draw rejection type and number
      ctx.fillStyle = '#000000';
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(`${selectedTerm}`, 300, 380);
      ctx.fillText(`${getOrdinalSuffix(clickTime)} Rejection`, 300, 350);

      try {
        const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
        const file = new File([blob], 'rejection.png', { type: 'image/png' });

        if (navigator.share) {
          await navigator.share({
            title: 'My Rejection',
            text: `I was the ${getOrdinalSuffix(clickTime)} person rejected for ${selectedTerm}! My rejection streak: ${streak} days`,
            files: [file],
          });
        } else {
          alert("Web Share API is not supported in your browser.");
        }
      } catch (error) {
        console.error('Error sharing:', error);
        alert("Failed to share. " + error.message);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4 relative overflow-hidden" style={{
      backgroundImage: "url('/api/placeholder/800/600')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}>
      <h1 className="text-4xl font-bold mb-4 text-red-600">Rejection Buzzer</h1>
      <div className="text-2xl font-bold mb-8 text-blue-600">Streak: {streak} days</div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {rejectionTerms.map((term, index) => (
          <button
            key={index}
            className={`bg-red-600 hover:bg-red-700 text-white font-bold py-4 px-6 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-200 flex flex-col items-center justify-center ${lastClicked[term] === new Date().toDateString() ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleClick(term)}
            disabled={lastClicked[term] === new Date().toDateString()}
            style={{
              width: "150px",
              height: "150px",
            }}
          >
            <span className="text-lg mb-2">{term}</span>
            <span className="text-3xl">{clicks[term] || 0}</span>
          </button>
        ))}
      </div>

      {showConfetti && <Confetti />}
      {fireworks.map((fw, index) => (
        <Firework key={index} {...fw} />
      ))}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md relative">
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <h2 className="text-2xl font-bold mb-4 text-red-600">Congratulations!</h2>
            <p className="mb-4">
              You're the {getOrdinalSuffix(clickTime)} person rejected for {selectedTerm}!
            </p>
            <p className="mb-4">
              Your rejection streak: {streak} days
            </p>
            <button
              onClick={shareRejection}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Share Rejection
            </button>
          </div>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} />

      <style jsx>{`
        .firework {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          animation: explode 1s ease-out forwards;
        }
        .spark {
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          opacity: 0;
          animation: spark 1s ease-out forwards;
        }
        @keyframes explode {
          0% { transform: scale(0); opacity: 1; }
          100% { transform: scale(1); opacity: 0; }
        }
        @keyframes spark {
          0% { transform: translateY(0) scale(1); opacity: 1; }
          100% { transform: translateY(20px) scale(0); opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default BuzzerApp;