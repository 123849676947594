import React, { useReducer, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import './Clocks.css';

const TICK_INTERVAL = 50; // 50ms, for smooth updates

const clockReducer = (state, action) => {
  switch (action.type) {
    case 'TICK':
      return state.map(clock => ({
        ...clock,
        time: new Date(clock.time.getTime() + TICK_INTERVAL * clock.secondsSpeed)
      }));
    case 'UPDATE_TIMEZONE':
      return state.map((clock, index) =>
        index === action.index ? { ...clock, timeZone: action.timeZone, time: new Date(), error: null } : clock
      );
    case 'UPDATE_SPEED':
      return state.map((clock, index) =>
        index === action.index ? { ...clock, secondsSpeed: action.speed } : clock
      );
    case 'ADD_CLOCK':
      return [...state, action.clock];
    case 'REMOVE_CLOCK':
      return state.filter((_, index) => index !== action.index);
    case 'SET_ERROR':
      return state.map((clock, index) =>
        index === action.index ? { ...clock, error: action.error } : clock
      );
    default:
      return state;
  }
};

const DigitalClock = ({ time, style }) => (
  <span className={`clock-text ${style}-clock-text`}>
    {format(time, 'HH:mm:ss')}
  </span>
);

const AnalogClock = ({ time }) => {
  const seconds = time.getSeconds();
  const minutes = time.getMinutes();
  const hours = time.getHours() % 12;

  const secondDegrees = ((seconds / 60) * 360) + 90;
  const minuteDegrees = ((minutes / 60) * 360) + ((seconds/60)*6) + 90;
  const hourDegrees = ((hours / 12) * 360) + ((minutes/60)*30) + 90;

  return (
    <div className="analog-clock">
      <div className="clock-face">
        <div className="hand hour-hand" style={{transform: `rotate(${hourDegrees}deg)`}}></div>
        <div className="hand minute-hand" style={{transform: `rotate(${minuteDegrees}deg)`}}></div>
        <div className="hand second-hand" style={{transform: `rotate(${secondDegrees}deg)`}}></div>
      </div>
    </div>
  );
};

const BinaryClock = ({ time }) => {
  const toBinary = (num, pad) => num.toString(2).padStart(pad, '0');

  const hours = toBinary(time.getHours(), 6);
  const minutes = toBinary(time.getMinutes(), 6);
  const seconds = toBinary(time.getSeconds(), 6);

  return (
    <div className="binary-clock">
      <div className="binary-row">{hours.split('').map((bit, i) => <div key={i} className={`binary-bit ${bit === '1' ? 'on' : 'off'}`}></div>)}</div>
      <div className="binary-row">{minutes.split('').map((bit, i) => <div key={i} className={`binary-bit ${bit === '1' ? 'on' : 'off'}`}></div>)}</div>
      <div className="binary-row">{seconds.split('').map((bit, i) => <div key={i} className={`binary-bit ${bit === '1' ? 'on' : 'off'}`}></div>)}</div>
    </div>
  );
};

const Clock = ({ clock, index, dispatch }) => {
  const { timeZone, secondsSpeed, style, time, error, type } = clock;

  const formatTime = useCallback(() => {
    try {
      return toDate(time, { timeZone });
    } catch (err) {
      console.error(`Error formatting time for ${timeZone}:`, err);
      dispatch({ type: 'SET_ERROR', index, error: 'Invalid time zone' });
      return new Date(); // Fallback to local time
    }
  }, [time, timeZone, dispatch, index]);

  const formattedTime = formatTime();

  return (
    <div className={`clock-container ${style}-clock-container ${error ? 'error' : ''}`}>
      {error ? (
        <span className="error-message">Error: {error}</span>
      ) : (
        <>
          {type === 'digital' && <DigitalClock time={formattedTime} style={style} />}
          {type === 'analog' && <AnalogClock time={formattedTime} />}
          {type === 'binary' && <BinaryClock time={formattedTime} />}
        </>
      )}
      <select
        value={timeZone}
        onChange={(e) => dispatch({ type: 'UPDATE_TIMEZONE', index, timeZone: e.target.value })}
        className="time-zone-select"
      >
        {timeZones.map(zone => (
          <option key={zone} value={zone}>{zone}</option>
        ))}
      </select>
      <input
        type="range"
        min="0.1"
        max="5"
        step="0.1"
        value={secondsSpeed}
        onChange={(e) => dispatch({ type: 'UPDATE_SPEED', index, speed: parseFloat(e.target.value) })}
        className="speed-slider"
      />
      <span className="speed-display">{secondsSpeed}x</span>
    </div>
  );
};

const timeZones = [
  "America/New_York", "America/Los_Angeles", "America/Chicago", "America/Denver",
  "Europe/London", "Europe/Paris", "Europe/Berlin", "Europe/Moscow",
  "Asia/Tokyo", "Asia/Shanghai", "Asia/Dubai", "Asia/Singapore",
  "Australia/Sydney", "Pacific/Auckland", "Pacific/Honolulu"
];
const styles = ["led", "vintage", "modern"];
const clockTypes = ["digital", "analog", "binary"];

const Clocks = () => {
  const [clocks, dispatch] = useReducer(clockReducer, [
    { timeZone: "America/New_York", secondsSpeed: 1, style: "led", type: "digital", time: new Date(), error: null },
    { timeZone: "Europe/London", secondsSpeed: 1, style: "vintage", type: "analog", time: new Date(), error: null },
    { timeZone: "Asia/Tokyo", secondsSpeed: 1, style: "modern", type: "binary", time: new Date(), error: null },
    { timeZone: "Australia/Sydney", secondsSpeed: 1, style: "led", type: "digital", time: new Date(), error: null }
  ]);

  const tick = useCallback(() => {
    dispatch({ type: 'TICK' });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(tick, TICK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [tick]);

  const handleAddClock = () => {
    dispatch({
      type: 'ADD_CLOCK',
      clock: {
        timeZone: timeZones[Math.floor(Math.random() * timeZones.length)],
        secondsSpeed: 1,
        style: styles[Math.floor(Math.random() * styles.length)],
        type: clockTypes[Math.floor(Math.random() * clockTypes.length)],
        time: new Date(),
        error: null
      }
    });
  };

  const handleRemoveClock = (index) => {
    dispatch({ type: 'REMOVE_CLOCK', index });
  };

  return (
    <div className="clock-app-container">
      <h1>Multi-Style Clocks</h1>
      <button onClick={handleAddClock} className="add-clock-button">
        Add Clock
      </button>
      <div className="clock-grid-container">
        {clocks.map((clock, idx) => (
          <div key={idx} className="clock-wrapper">
            <Clock
              clock={clock}
              index={idx}
              dispatch={dispatch}
            />
            <button onClick={() => handleRemoveClock(idx)} className="remove-clock-button">
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clocks;