import React, { useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import './Believe.css';

const BelieveCell = React.memo(({ frontText, backText, isSwiveled }) => (
  <div className="believe-cell">
    <div className={`believe-content ${isSwiveled ? 'believe-swivel' : ''}`}>
      <div className="believe-front">{frontText}</div>
      <div className="believe-back">{backText}</div>
    </div>
  </div>
));

const Believe = () => {
  const [matrix, setMatrix] = useState(Array(25).fill("BELIEVE"));
  const [swiveledCells, setSwiveledCells] = useState([]);
  const [streak, setStreak] = useState(1);

  const fetchAffirmations = useCallback(async () => {
    try {
      const response = await fetch('/affirmations.json');
      const data = await response.json();
      const shuffled = data.affirmations.sort(() => 0.5 - Math.random());
      setMatrix(shuffled.slice(0, 25));
    } catch (error) {
      console.error('Error fetching affirmations:', error);
    }
  }, []);

  useEffect(() => {
    fetchAffirmations();

    const storedStreak = localStorage.getItem('believeStreak');
    if (storedStreak) {
      setStreak(parseInt(storedStreak, 10));
    }
  }, [fetchAffirmations]);

  useEffect(() => {
    localStorage.setItem('believeStreak', streak.toString());
  }, [streak]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSwiveledCells(prev => {
        const newSwiveled = new Set(prev);
        const randomIndex = Math.floor(Math.random() * 25);
        if (newSwiveled.has(randomIndex)) {
          newSwiveled.delete(randomIndex);
        } else {
          newSwiveled.add(randomIndex);
        }
        return Array.from(newSwiveled);
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const generateStreakImage = useCallback(() => {
    const element = document.createElement('div');
    element.style.width = '600px';
    element.style.height = '315px';
    element.style.background = '#0057b7';
    element.style.color = '#ffcc00';
    element.style.fontSize = '48px';
    element.style.display = 'flex';
    element.style.flexDirection = 'column';
    element.style.justifyContent = 'center';
    element.style.alignItems = 'center';
    element.style.fontFamily = 'Arial, sans-serif';
    element.style.padding = '20px';
    element.style.boxSizing = 'border-box';
    element.style.textAlign = 'center';

    const title = document.createElement('div');
    title.textContent = 'BELIEVE';
    title.style.fontSize = '72px';
    title.style.fontWeight = 'bold';
    title.style.marginBottom = '20px';

    const streakText = document.createElement('div');
    streakText.textContent = `${streak} Day Streak!`;

    element.appendChild(title);
    element.appendChild(streakText);

    document.body.appendChild(element);

    html2canvas(element).then((canvas) => {
      document.body.removeChild(element);
      canvas.toBlob((blob) => {
        const file = new File([blob], 'believe-streak.png', { type: 'image/png' });
        const shareData = {
          files: [file],
          title: 'My Believe Streak',
          text: `I've maintained my Believe streak for ${streak} days! #BelieveStreak`,
        };

        if (navigator.canShare && navigator.canShare(shareData)) {
          navigator.share(shareData)
            .catch((error) => console.error('Error sharing:', error));
        } else {
          // Fallback for browsers that don't support sharing
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.download = 'believe-streak.png';
          link.href = url;
          link.click();
          URL.revokeObjectURL(url);
        }
      });
    });
  }, [streak]);

  return (
    <div className="believe-wrapper">
      <div className="believe-header">
        <h1>Believe</h1>
        <div className="streak-info">
          <span>Streak: {streak} days</span>
          <button onClick={generateStreakImage}>Share Streak</button>
        </div>
      </div>
      <div className="believe-matrix">
        {matrix.map((text, index) => (
          <BelieveCell
            key={index}
            frontText="BELIEVE"
            backText={text}
            isSwiveled={swiveledCells.includes(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Believe;