import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2
}

const SayNoApp = () => {
  const [dailyData, setDailyData] = useState({});
  const [currentDate, setCurrentDate] = useState('');
  const [streak, setStreak] = useState(0);
  const refAnimationInstance = useRef(null);
  const canvasRef = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fireConfetti = useCallback(() => {
    makeShot(0.25, { spread: 26, startVelocity: 55 });
    makeShot(0.2, { spread: 60 });
    makeShot(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
    makeShot(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
    makeShot(0.1, { spread: 120, startVelocity: 45 });
  }, [makeShot]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setCurrentDate(today);
    const savedData = JSON.parse(localStorage.getItem('noData')) || {};
    setDailyData(savedData);
    setStreak(calculateStreak(savedData, today));
  }, []);

  useEffect(() => {
    localStorage.setItem('noData', JSON.stringify(dailyData));
  }, [dailyData]);

  const calculateStreak = (data, today) => {
    let streakCount = 0;
    let currentDate = new Date(today);

    while (data[currentDate.toISOString().split('T')[0]]) {
      const dayData = data[currentDate.toISOString().split('T')[0]];
      if (dayData.noCount > dayData.unwillingYesCount) {
        streakCount++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else {
        break;
      }
    }

    return streakCount;
  };

  const handleClick = (isNo) => {
    setDailyData(prevData => {
      const updatedData = { ...prevData };
      if (!updatedData[currentDate]) {
        updatedData[currentDate] = { noCount: 0, unwillingYesCount: 0 };
      }
      if (isNo) {
        updatedData[currentDate].noCount += 1;
        fireConfetti();
      } else {
        updatedData[currentDate].unwillingYesCount += 1;
      }
      return updatedData;
    });

    setStreak(prevStreak => calculateStreak(dailyData, currentDate));
  };

  const calculateRatio = () => {
    const todayData = dailyData[currentDate] || { noCount: 0, unwillingYesCount: 0 };
    const total = todayData.noCount + todayData.unwillingYesCount;
    return total === 0 ? 0 : (todayData.noCount / total * 100).toFixed(1);
  };

  const generateImage = (count) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = 600;
    canvas.height = 400;

    // Background
    ctx.fillStyle = '#8B5CF6';
    ctx.fillRect(0, 0, 600, 400);

    // Text
    ctx.fillStyle = 'white';
    ctx.font = 'bold 48px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(`I said NO`, 300, 150);
    ctx.font = 'bold 72px Arial';
    ctx.fillText(`${count} times`, 300, 250);
    ctx.font = 'italic 32px Arial';
    ctx.fillText('today!', 300, 320);

    return canvas.toDataURL('image/png');
  };

  const shareImage = async () => {
    const todayData = dailyData[currentDate] || { noCount: 0, unwillingYesCount: 0 };
    const imageDataUrl = generateImage(todayData.noCount);

    if (navigator.share) {
      fetch(imageDataUrl)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'my-nos.png', { type: 'image/png' });
          navigator.share({
            title: 'My Assertiveness Progress',
            text: `I said NO ${todayData.noCount} times today!`,
            files: [file],
          }).catch(error => console.error('Error sharing:', error));
        });
    } else {
      const link = document.createElement('a');
      link.href = imageDataUrl;
      link.download = 'my-nos.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      alert(`Image saved. I said NO ${todayData.noCount} times today!`);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 p-4">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full space-y-6">
        <h1 className="text-3xl font-bold text-center text-gray-800">Assert Yourself!</h1>

        <div className="flex space-x-4">
          <button
            onClick={() => handleClick(true)}
            className="flex-1 bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            NO
          </button>
          <button
            onClick={() => handleClick(false)}
            className="flex-1 bg-red-500 hover:bg-red-600 text-white font-bold py-4 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            Unwilling Yes
          </button>
        </div>

        <div className="space-y-2">
          <p className="text-xl font-semibold text-gray-700">
            Today's No's: <span className="text-green-600">{dailyData[currentDate]?.noCount || 0}</span>
          </p>
          <p className="text-xl font-semibold text-gray-700">
            Today's Unwilling Yes's: <span className="text-red-600">{dailyData[currentDate]?.unwillingYesCount || 0}</span>
          </p>
          <p className="text-xl font-semibold text-gray-700">
            Today's Assertiveness Ratio: <span className="text-blue-600">{calculateRatio()}%</span>
          </p>
          <p className="text-xl font-semibold text-gray-700">
            Current Streak: <span className="text-purple-600">{streak}</span> days
          </p>
        </div>

        <button
          onClick={shareImage}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
        >
          Share Today's No's
        </button>

        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Goal</h2>
          <p className="text-gray-600">Improve your assertiveness by increasing your "No" count and reducing unwilling "Yes" responses. Aim for a higher assertiveness ratio!</p>
        </div>
      </div>
    </div>
  );
};

export default SayNoApp;