import React, { useState, useEffect, useRef } from 'react';

const PhantomScriptMirror = () => {
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState(24);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const canvasRef = useRef(null);

  const fonts = [
    'Arial',
    'Helvetica',
    'Georgia',
    'Times New Roman',
    'Courier New',
    'Creepster',
    'Eater',
    'Nosifer',
    'Opacity One',
    'Butcherman'
  ];

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Creepster&family=Eater&family=Nosifer&family=Opacity+One&family=Butcherman&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    link.onload = () => {
      setFontsLoaded(true);
    };

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleFontChange = (e) => {
    setFont(e.target.value);
  };

  const handleFontSizeChange = (e) => {
    setFontSize(parseInt(e.target.value, 10));
  };

  const handleClearText = () => {
    setText('');
    setName('');
    setShareUrl('');
  };

  const mirrorLetter = (char) => {
    const mirrorMap = {
      'b': 'd', 'd': 'b',
      'p': 'q', 'q': 'p',
      '(': ')', ')': '(',
      '[': ']', ']': '[',
      '{': '}', '}': '{',
      '<': '>', '>': '<',
      '/': '\\', '\\': '/',
    };
    return mirrorMap[char.toLowerCase()] || char;
  };

  const mirroredText = text.split('').map(mirrorLetter).join('');

  const generateImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = 800;
    canvas.height = 400;

    // Background
    ctx.fillStyle = '#1a0f1f';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Reversed Text
    ctx.font = `${fontSize}px "${font}"`;
    ctx.fillStyle = '#e0e0e0';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.save();
    ctx.scale(-1, 1);
    ctx.fillText(mirroredText, -canvas.width / 2, canvas.height / 2);
    ctx.restore();

    // Optional Name
    if (name) {
      ctx.font = '20px Arial';
      ctx.fillStyle = '#8a4fff';
      ctx.textAlign = 'right';
      ctx.fillText(name, canvas.width - 20, canvas.height - 20);
    }

    return canvas.toDataURL('image/png');
  };

  const handleGenerate = () => {
    const imageUrl = generateImage();
    setShareUrl(imageUrl);
  };

  const handleShare = async () => {
    const imageDataUrl = shareUrl;

    if (navigator.share) {
      try {
        const response = await fetch(imageDataUrl);
        const blob = await response.blob();
        const file = new File([blob], 'phantomscript.png', { type: 'image/png' });
        await navigator.share({
          title: 'My PhantomScript Creation',
          text: 'Check out my mirrored text!',
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing:', error);
        alert('Failed to share. The image will be downloaded instead.');
        downloadImage(imageDataUrl);
      }
    } else {
      downloadImage(imageDataUrl);
      alert('Web Share not supported on this browser. The image has been downloaded.');
    }
  };

  const downloadImage = (imageDataUrl) => {
    const link = document.createElement('a');
    link.href = imageDataUrl;
    link.download = 'phantomscript.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{
      minHeight: '100vh',
      background: 'linear-gradient(to bottom right, #4a0e4e, #170117)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      color: '#e0e0e0',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>PhantomScript</h1>

      <div style={{
        width: '100%',
        maxWidth: '600px',
        background: 'rgba(26, 15, 31, 0.6)',
        padding: '1.5rem',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <textarea
          value={text}
          onChange={handleInputChange}
          placeholder="Type your message here..."
          style={{
            width: '100%',
            height: '100px',
            padding: '0.5rem',
            marginBottom: '1rem',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#e0e0e0',
            border: '1px solid #4a0e4e',
            borderRadius: '0.25rem',
            fontFamily: font,
            fontSize: `${fontSize}px`
          }}
        />

        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder="Your name (optional)"
          style={{
            width: '100%',
            padding: '0.5rem',
            marginBottom: '1rem',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#e0e0e0',
            border: '1px solid #4a0e4e',
            borderRadius: '0.25rem'
          }}
        />

        <div style={{
          width: '100%',
          height: '100px',
          padding: '0.5rem',
          marginBottom: '1rem',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          color: '#e0e0e0',
          border: '1px solid #4a0e4e',
          borderRadius: '0.25rem',
          overflow: 'auto',
          fontFamily: font,
          fontSize: `${fontSize}px`,
          transform: 'scaleX(-1)'
        }}>
          {mirroredText}
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <label htmlFor="font-select" style={{ marginRight: '0.5rem' }}>Font:</label>
          <select
            id="font-select"
            value={font}
            onChange={handleFontChange}
            disabled={!fontsLoaded}
            style={{
              padding: '0.25rem',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              color: '#e0e0e0',
              border: '1px solid #4a0e4e',
              borderRadius: '0.25rem'
            }}
          >
            {fonts.map((f) => (
              <option key={f} value={f} style={{ fontFamily: f }}>
                {f}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <label htmlFor="font-size" style={{ marginRight: '0.5rem' }}>Font Size:</label>
          <input
            type="range"
            id="font-size"
            min="12"
            max="48"
            value={fontSize}
            onChange={handleFontSizeChange}
          />
          <span style={{ marginLeft: '0.5rem' }}>{fontSize}px</span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={handleClearText}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#4a0e4e',
              color: '#e0e0e0',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer'
            }}
          >
            Clear
          </button>
          <button
            onClick={handleGenerate}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#4a0e4e',
              color: '#e0e0e0',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer'
            }}
          >
            Generate Image
          </button>
        </div>

        {shareUrl && (
          <div style={{ marginTop: '1rem' }}>
            <p>Your image is ready! You can now share or download it:</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
              <button
                onClick={handleShare}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#8a4fff',
                  color: '#e0e0e0',
                  border: 'none',
                  borderRadius: '0.25rem',
                  cursor: 'pointer'
                }}
              >
                Share/Download Image
              </button>
            </div>
          </div>
        )}
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default PhantomScriptMirror;