import React, { useState, useRef, useCallback } from 'react';
import { Upload, Play, Rewind } from 'lucide-react';

export default function ReverseVideoPlayer() {
  const [videoFile, setVideoFile] = useState(null);
  const [isReversing, setIsReversing] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleVideoUpload = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(URL.createObjectURL(file));
    }
  }, []);

  const reverseVideo = useCallback(() => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const ctx = canvasElement.getContext('2d');

    videoElement.play();
    setIsReversing(true);

    videoElement.addEventListener('loadedmetadata', () => {
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
    });

    videoElement.addEventListener('play', () => {
      const interval = setInterval(() => {
        if (videoElement.paused || videoElement.ended) clearInterval(interval);
        ctx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      }, 1000 / 30);
    });

    videoElement.currentTime = videoElement.duration;
    const reverseInterval = setInterval(() => {
      if (videoElement.currentTime <= 0) {
        clearInterval(reverseInterval);
        videoElement.pause();
        setIsReversing(false);
        setProgress(0);
      } else {
        videoElement.currentTime -= 0.05;
        setProgress((prevProgress) => prevProgress + (0.05 / videoElement.duration) * 100);
      }
    }, 100);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Reverse Video Player</h1>

        <div className="mb-6">
          <label htmlFor="video-upload" className="block mb-2 text-sm font-medium text-gray-700">
            Upload a video
          </label>
          <div className="flex items-center justify-center w-full">
            <label htmlFor="video-upload" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload className="w-10 h-10 mb-3 text-gray-400" />
                <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                <p className="text-xs text-gray-500">MP4, WebM, or Ogg (MAX. 800MB)</p>
              </div>
              <input id="video-upload" type="file" className="hidden" accept="video/*" onChange={handleVideoUpload} />
            </label>
          </div>
        </div>

        {videoFile && (
          <div className="space-y-4">
            <video ref={videoRef} src={videoFile} controls className="w-full rounded-lg shadow-md" />
            <div className="flex justify-center">
              <button
                onClick={reverseVideo}
                disabled={isReversing}
                className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isReversing ? (
                  <Rewind className="w-5 h-5 mr-2 animate-spin" />
                ) : (
                  <Play className="w-5 h-5 mr-2" />
                )}
                {isReversing ? 'Reversing...' : 'Play in Reverse'}
              </button>
            </div>
            {isReversing && (
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
          </div>
        )}
        <canvas ref={canvasRef} className="hidden"></canvas>
      </div>
    </div>
  );
}