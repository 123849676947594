import React, { useState, useRef, useEffect } from 'react';

const SimplifiedAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState(null);
  const [debug, setDebug] = useState('');
  const mediaRecorderRef = useRef(null);

  const addDebug = (message) => {
    setDebug(prev => prev + '\n' + message);
  };

  const startRecording = async () => {
    setError(null);
    setAudioUrl(null);
    addDebug('Attempting to start recording...');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      addDebug('Microphone access granted');

      mediaRecorderRef.current = new MediaRecorder(stream);
      addDebug('MediaRecorder created');

      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => {
        chunks.push(e.data);
        addDebug('Data available: ' + e.data.size + ' bytes');
      };

      mediaRecorderRef.current.onstop = () => {
        addDebug('Recording stopped');
        const blob = new Blob(chunks, { type: 'audio/webm' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        addDebug('Audio URL created: ' + url);
      };

      mediaRecorderRef.current.start();
      addDebug('Recording started');
      setIsRecording(true);
    } catch (err) {
      setError('Error: ' + err.message);
      addDebug('Error: ' + err.message);
      console.error("Error starting recording:", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      addDebug('Stopping recording');
    } else {
      addDebug('Stop called but not recording');
    }
  };

  return (
    <div style={{
      padding: '1rem',
      maxWidth: '400px',
      margin: '20px auto',
      border: '3px solid #3B82F6',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#F3F4F6'
    }}>
      <h2 style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        color: '#1F2937',
        textAlign: 'center'
      }}>Simplified Audio Recorder</h2>

      <div style={{ marginBottom: '1rem' }}>
        <button
          onClick={isRecording ? stopRecording : startRecording}
          style={{
            width: '100%',
            padding: '0.75rem 1rem',
            backgroundColor: isRecording ? '#EF4444' : '#3B82F6',
            color: 'white',
            border: 'none',
            borderRadius: '0.25rem',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>
      </div>

      {audioUrl && (
        <div style={{ marginBottom: '1rem' }}>
          <audio controls src={audioUrl} style={{ width: '100%' }} />
        </div>
      )}

      {error && (
        <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>
      )}

      <div style={{ marginTop: '1rem' }}>
        <h3 style={{ color: '#4B5563', marginBottom: '0.5rem' }}>Debug Log:</h3>
        <pre style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          backgroundColor: '#E5E7EB',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          fontSize: '0.875rem',
          color: '#1F2937',
          maxHeight: '200px',
          overflowY: 'auto'
        }}>
          {debug}
        </pre>
      </div>
    </div>
  );
};

export default SimplifiedAudioRecorder;