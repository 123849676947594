import React, { useEffect, useState } from 'react';
import { Apple, Carrot, Trophy, Calendar } from 'lucide-react';
import vegetablesData from './vegetables.json';
import fruitsData from './fruits.json';

const categories = [
  { name: 'Vegetable', icon: Carrot, color: 'bg-green-500', data: vegetablesData.vegetables || [] },
  { name: 'Fruit', icon: Apple, color: 'bg-red-500', data: fruitsData.fruits || [] },
];

const FoodItemCard = ({ category, item, onLearnMore }) => {
  const Icon = category.icon;

  return (
    <div className={`${category.color} rounded-lg shadow-lg p-6 m-4 w-full max-w-sm mx-auto text-white`}>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">{category.name} of the Day</h2>
        <Icon className="w-8 h-8" />
      </div>
      <p className="text-xl font-semibold mb-2">{item ? item.name : 'No item selected'}</p>
      {item && item.scientific_name && (
        <p className="text-sm italic mb-4">{item.scientific_name}</p>
      )}
      {item && item.url && (
        <button
          onClick={() => onLearnMore(item)}
          className="bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors duration-200"
        >
          Learn More
        </button>
      )}
    </div>
  );
};

const EatingPage = () => {
  const [dailyItems, setDailyItems] = useState({});
  const [streak, setStreak] = useState(0);
  const [lastVisit, setLastVisit] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('foodDiversityData');
    if (storedData) {
      const { items, streak, lastVisit } = JSON.parse(storedData);
      const today = new Date().toDateString();

      if (lastVisit === today) {
        setDailyItems(items);
        setStreak(streak);
        setLastVisit(lastVisit);
      } else {
        selectDailyItems(lastVisit);
      }
    } else {
      selectDailyItems(null);
    }
  }, []);

  const selectDailyItems = (previousVisit) => {
    const newDailyItems = {};
    categories.forEach(category => {
      if (Array.isArray(category.data) && category.data.length > 0) {
        const randomIndex = Math.floor(Math.random() * category.data.length);
        newDailyItems[category.name] = category.data[randomIndex];
      } else {
        console.error(`No valid data for ${category.name}`);
        newDailyItems[category.name] = null;
      }
    });

    const today = new Date().toDateString();
    let newStreak = streak;

    if (previousVisit) {
      const previousDate = new Date(previousVisit);
      const diffDays = Math.floor((new Date() - previousDate) / (1000 * 60 * 60 * 24));

      if (diffDays === 1) {
        newStreak += 1;
      } else if (diffDays > 1) {
        newStreak = 1;
      }
    } else {
      newStreak = 1;
    }

    setDailyItems(newDailyItems);
    setStreak(newStreak);
    setLastVisit(today);

    localStorage.setItem('foodDiversityData', JSON.stringify({
      items: newDailyItems,
      streak: newStreak,
      lastVisit: today
    }));
  };

  const handleLearnMore = (item) => {
    if (item && item.url) {
      window.open(item.url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 to-indigo-600 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-extrabold text-white text-center mb-8">Food Diversity Challenge</h1>
        <div className="flex justify-center items-center mb-8">
          <Trophy className="w-8 h-8 text-yellow-400 mr-2" />
          <span className="text-2xl font-bold text-white">Streak: {streak} day{streak !== 1 ? 's' : ''}</span>
        </div>
        <div className="flex justify-center items-center mb-8">
          <Calendar className="w-6 h-6 text-white mr-2" />
          <span className="text-xl text-white">Last visit: {lastVisit || 'First time here!'}</span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {categories.map(category => (
            <FoodItemCard
              key={category.name}
              category={category}
              item={dailyItems[category.name]}
              onLearnMore={handleLearnMore}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EatingPage;