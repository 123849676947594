import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Scatter } from 'react-chartjs-2';
import './GraphMatrixApp.css';

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Flip Card Component
const FlipCard = ({ front, back }) => (
  <div className="graph-matrix-flip-card">
    <div className="graph-matrix-flip-card-inner">
      <div className="graph-matrix-flip-card-front">{front}</div>
      <div className="graph-matrix-flip-card-back">{back}</div>
    </div>
  </div>
);

// Graph Components
const LinearGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [{
      label: 'Linear Growth',
      data: [1, 2, 3, 4, 5],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const ExponentialGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [{
      label: 'Exponential Growth',
      data: [1, 2, 4, 8, 16],
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const BellCurve = () => {
  const data = {
    labels: ['-3σ', '-2σ', '-1σ', '0', '1σ', '2σ', '3σ'],
    datasets: [{
      label: 'Bell Curve (Normal Distribution)',
      data: [0.1, 0.2, 0.6, 1.0, 0.6, 0.2, 0.1],
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const HyperbolaGraph = () => {
  const data = {
    datasets: [{
      label: 'Hyperbola (xy = 1)',
      data: Array.from({ length: 10 }, (v, i) => ({
        x: i + 1,
        y: 1 / (i + 1),
      })),
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 2,
      showLine: true,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Scatter data={data} options={options} />;
};

const ParabolaGraph = () => {
  const data = {
    labels: ['-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5'],
    datasets: [{
      label: 'Parabola (y = x²)',
      data: Array.from({ length: 11 }, (v, i) => Math.pow(i - 5, 2)),
      borderColor: 'rgba(255, 206, 86, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const LogarithmicGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    datasets: [{
      label: 'Logarithmic Growth',
      data: [0, 0.69, 1.1, 1.39, 1.61, 1.79, 1.95, 2.08],
      borderColor: 'rgba(255, 159, 64, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const SigmoidGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    datasets: [{
      label: 'Sigmoid Curve',
      data: [0.01, 0.05, 0.1, 0.3, 0.6, 0.9, 0.95, 0.99],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 2,
      fill: false
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const StepGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [{
      label: 'Step Graph',
      data: [0, 2, 2, 4, 4],
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
      fill: false,
      steppedLine: true
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

const ScatterPlot = () => {
  const data = {
    datasets: [{
      label: 'Scatter Plot',
      data: [{ x: 1, y: 2 }, { x: 2, y: 3 }, { x: 3, y: 1 }, { x: 4, y: 4 }],
      backgroundColor: 'rgba(54, 162, 235, 1)'
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Scatter data={data} options={options} />;
};

const PiecewiseGraph = () => {
  const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [{
      label: 'Piecewise Graph',
      data: [1, 1, 2, 2, 3, 3, 4],
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 2,
      fill: false,
      steppedLine: 'before'
    }]
  };
  const options = { responsive: true, maintainAspectRatio: false };
  return <Line data={data} options={options} />;
};

// Render all flip cards in a grid
function GraphMatrixApp() {
  return (
    <div className="graph-matrix-container">
      <FlipCard
        front={<div className="graph-container"><LinearGraph /></div>}
        back={<div>Linear graph example: Growth over time</div>}
      />
      <FlipCard
        front={<div className="graph-container"><ExponentialGraph /></div>}
        back={<div>Exponential graph example: Compound growth</div>}
      />
      <FlipCard
        front={<div className="graph-container"><BellCurve /></div>}
        back={<div>Bell curve example: Normal distribution</div>}
      />
      <FlipCard
        front={<div className="graph-container"><HyperbolaGraph /></div>}
        back={<div>Hyperbola example: Inverse relationship</div>}
      />
      <FlipCard
        front={<div className="graph-container"><ParabolaGraph /></div>}
        back={<div>Parabola example: Projectile motion</div>}
      />
      <FlipCard
        front={<div className="graph-container"><LogarithmicGraph /></div>}
        back={<div>Logarithmic graph example: Logarithmic growth</div>}
      />
      <FlipCard
        front={<div className="graph-container"><SigmoidGraph /></div>}
        back={<div>Sigmoid curve example: Population growth</div>}
      />
      <FlipCard
        front={<div className="graph-container"><StepGraph /></div>}
        back={<div>Step graph example: Utility cost based on usage</div>}
      />
      <FlipCard
        front={<div className="graph-container"><ScatterPlot /></div>}
        back={<div>Scatter plot example: Data correlation</div>}
      />
      <FlipCard
        front={<div className="graph-container"><PiecewiseGraph /></div>}
        back={<div>Piecewise function example: Tiered tax rates</div>}
      />
    </div>
  );
}

export default GraphMatrixApp;