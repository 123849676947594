

import React, { useState, useEffect, useRef } from 'react';
import { differenceInDays, differenceInSeconds, format, isFuture, isPast, startOfToday } from 'date-fns';

const FlipDownTimer = ({ value }) => (
  <div className="bg-gray-800 text-white p-2 rounded-lg shadow-inner">
    <div className="text-4xl font-bold">{value.toString().padStart(2, '0')}</div>
  </div>
);

const LEDSegment = ({ digit }) => {
  const segments = [
    [1,2,3,4,5,6],
    [2,3],
    [1,2,4,5,7],
    [1,2,3,4,7],
    [2,3,6,7],
    [1,3,4,6,7],
    [1,3,4,5,6,7],
    [1,2,3],
    [1,2,3,4,5,6,7],
    [1,2,3,4,6,7]
  ];

  const parsedDigit = parseInt(digit);
  const segmentConfig = isNaN(parsedDigit) ? segments[0] : segments[parsedDigit];

  return (
    <svg width="20" height="30" viewBox="0 0 20 30">
      {[0,1,2,3,4,5,6].map(segment => {
        const on = segmentConfig.includes(segment + 1);

        const d = [
          "M5,2.5 L15,2.5 L12.5,5 L7.5,5 Z",
          "M15.5,3.5 L17.5,6 L17.5,13.5 L15.5,16 Z",
          "M15.5,16.5 L17.5,19 L17.5,26.5 L15.5,29 Z",
          "M5,27.5 L15,27.5 L12.5,25 L7.5,25 Z",
          "M4.5,16.5 L2.5,19 L2.5,26.5 L4.5,29 Z",
          "M4.5,3.5 L2.5,6 L2.5,13.5 L4.5,16 Z",
          "M5,15 L15,15 L12.5,17.5 L7.5,17.5 Z"
        ][segment];

        return <path key={segment} d={d} fill={on ? "#ff0000" : "#330000"} />
      })}
    </svg>
  );
};

const LEDTimer = ({ value }) => {
  const paddedValue = value.toString().padStart(2, '0');
  return (
    <div className="bg-black p-2 rounded-lg flex">
      <LEDSegment digit={paddedValue[0]} />
      <LEDSegment digit={paddedValue[1]} />
    </div>
  );
};

const generateRandomGradient = () => {
  const color1 = Math.floor(Math.random()*16777215).toString(16);
  const color2 = Math.floor(Math.random()*16777215).toString(16);
  return `linear-gradient(135deg, #${color1}, #${color2})`;
};

const DaysCalculator = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [days, setDays] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [timerStyle, setTimerStyle] = useState('flip');
  const [gradient, setGradient] = useState(generateRandomGradient());
  const canvasRef = useRef(null);

  useEffect(() => {
    if (startDate && endDate) {
      const diffDays = differenceInDays(new Date(endDate), new Date(startDate));
      setDays(Math.abs(diffDays));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let timer;
    if (isFuture(new Date(endDate))) {
      timer = setInterval(() => {
        const now = new Date();
        const end = new Date(endDate);
        const totalSeconds = differenceInSeconds(end, now);

        if (totalSeconds <= 0) {
          clearInterval(timer);
          setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
          const days = Math.floor(totalSeconds / (3600 * 24));
          const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = Math.floor(totalSeconds % 60);
          setCountdown({ days, hours, minutes, seconds });
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [endDate]);

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const setToday = (setter) => () => {
    setter(format(startOfToday(), 'yyyy-MM-dd'));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const generateShareableImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = 'bold 48px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${days} days to go`, canvas.width / 2, canvas.height / 2);

      const timerY = canvas.height / 2 + 60;
      ctx.font = 'bold 36px Arial';
      ctx.fillText(`${countdown.days}d : ${countdown.hours}h : ${countdown.minutes}m : ${countdown.seconds}s`, canvas.width / 2, timerY);

      shareImage(canvas.toDataURL('image/png'));
    };
    img.src = uploadedImage || createGradientImage();
  };

  const createGradientImage = () => {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = 500;
    tempCanvas.height = 500;
    const tempCtx = tempCanvas.getContext('2d');
    const gradientObj = tempCtx.createLinearGradient(0, 0, 500, 500);
    gradientObj.addColorStop(0, gradient.match(/#[a-f0-9]{6}/gi)[0]);
    gradientObj.addColorStop(1, gradient.match(/#[a-f0-9]{6}/gi)[1]);
    tempCtx.fillStyle = gradientObj;
    tempCtx.fillRect(0, 0, 500, 500);
    return tempCanvas.toDataURL('image/png');
  };

  const shareImage = async (imageDataUrl) => {
    const blob = await (await fetch(imageDataUrl)).blob();
    const file = new File([blob], 'countdown.png', { type: 'image/png' });

    if (navigator.share) {
      navigator.share({
        files: [file],
        title: 'My Countdown',
        text: 'Check out my countdown!'
      }).then(() => {
        console.log('Shared successfully');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      const link = document.createElement('a');
      link.href = imageDataUrl;
      link.download = 'countdown.png';
      link.click();
    }
  };

  const isDaysSince = isPast(new Date(endDate));
  const isDaysToGo = isFuture(new Date(endDate));

  const TimerComponent = timerStyle === 'flip' ? FlipDownTimer : LEDTimer;

  return (
    <div className="max-w-6xl mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-lg flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 pr-0 md:pr-4 mb-6 md:mb-0">
        <h1 className="text-2xl font-bold mb-4">Days Calculator</h1>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Start Date</label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="date"
              value={startDate}
              onChange={handleDateChange(setStartDate)}
              className="w-full p-2 border rounded"
            />
            <button
              onClick={setToday(setStartDate)}
              className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 hover:bg-gray-300 rounded-r"
            >
              Today
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">End Date</label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="date"
              value={endDate}
              onChange={handleDateChange(setEndDate)}
              className="w-full p-2 border rounded"
            />
            <button
              onClick={setToday(setEndDate)}
              className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 hover:bg-gray-300 rounded-r"
            >
              Today
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Timer Style</label>
          <select
            value={timerStyle}
            onChange={(e) => setTimerStyle(e.target.value)}
            className="mt-1 w-full p-2 border rounded"
          >
            <option value="flip">Flip Down</option>
            <option value="led">LED</option>
          </select>
        </div>

        {startDate && endDate && (
          <div className={`p-4 rounded ${isDaysSince ? 'bg-yellow-300 border-4 border-black' : 'bg-blue-100'}`}>
            {isDaysSince && (
              <p className="text-xl font-bold">
                {days} days since {format(new Date(startDate), 'MMMM d, yyyy')}
              </p>
            )}
            {isDaysToGo && (
              <div>
                <p className="text-3xl font-bold mb-2">{days}</p>
                <p className="text-lg">
                  days to go until {format(new Date(endDate), 'MMMM d, yyyy')}
                </p>
                <div className="mt-4 flex justify-between">
                  <TimerComponent value={countdown.days} />
                  <TimerComponent value={countdown.hours} />
                  <TimerComponent value={countdown.minutes} />
                  <TimerComponent value={countdown.seconds} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="w-full md:w-1/2 pl-0 md:pl-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Upload Background Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="mt-1 w-full"
          />
        </div>

        <div className="relative" style={{ height: '400px' }}>
          {uploadedImage ? (
            <img src={uploadedImage} alt="Uploaded" className="w-full h-full object-cover rounded" />
          ) : (
            <div className="w-full h-full rounded" style={{ background: gradient }}></div>
          )}
          {isDaysToGo && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white">
              <span className="text-6xl font-bold mb-4">{days}</span>
              <span className="text-2xl">days to go</span>
              <div className="mt-4 flex space-x-2">
                <TimerComponent value={countdown.days} />
                <TimerComponent value={countdown.hours} />
                <TimerComponent value={countdown.minutes} />
                <TimerComponent value={countdown.seconds} />
              </div>
            </div>
          )}
        </div>

        <button
          onClick={generateShareableImage}
          className="mt-4 w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Share Countdown Image
        </button>
      </div>

      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default DaysCalculator;