import React from 'react';

const BeautifulButton = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <button
        className="px-8 py-4 text-xl font-bold text-white transition-all duration-300 ease-in-out transform bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300 active:bg-blue-800"
        onClick={() => console.log("Button clicked!")}
      >
        Click Me!
      </button>
    </div>
  );
};

export default BeautifulButton;