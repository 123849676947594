import React, { useEffect, useState, useRef, useCallback } from 'react';

const PiPage = () => {
  const [digits, setDigits] = useState('');
  const [numDigitsPerRow, setNumDigitsPerRow] = useState(0);
  const [numRows, setNumRows] = useState(0);
  const containerRef = useRef(null);
  const [rowOffsets, setRowOffsets] = useState([]);
  const [isAnimating, setIsAnimating] = useState(true);
  const [speed, setSpeed] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const fetchPiDigits = async () => {
      try {
        const response = await fetch('/pi.txt');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        setDigits(text.replace(/\s/g, '')); // Remove any whitespace
      } catch (error) {
        console.error('Error fetching Pi digits:', error);
        setError('Failed to load Pi digits. Please try again later.');
      }
    };

    fetchPiDigits();
  }, []);

  const calculateDimensions = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;
      const fontSize = 24; // Base font size
      const digitWidth = fontSize * 0.7;
      const digitHeight = fontSize * 1.2;
      const calculatedNumDigitsPerRow = Math.floor(containerWidth / digitWidth);
      const calculatedNumRows = Math.floor((containerHeight - 150) / digitHeight); // Adjusted for header and control panel

      setNumDigitsPerRow(calculatedNumDigitsPerRow);
      setNumRows(calculatedNumRows);
      setRowOffsets(new Array(calculatedNumRows).fill(0));
    }
  }, []);

  useEffect(() => {
    calculateDimensions();
    window.addEventListener('resize', calculateDimensions);

    return () => window.removeEventListener('resize', calculateDimensions);
  }, [calculateDimensions]);

  useEffect(() => {
    let interval;
    if (isAnimating) {
      interval = setInterval(() => {
        setRowOffsets(prevOffsets =>
          prevOffsets.map((offset) =>
            (offset + 1) % numDigitsPerRow
          )
        );
      }, speed);
    }

    return () => clearInterval(interval);
  }, [isAnimating, speed, numDigitsPerRow]);

  const toggleAnimation = () => setIsAnimating(!isAnimating);

  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value);
    setSpeed(newSpeed);
  };

  const handleSearch = useCallback(() => {
    if (searchTerm.length === 0 || searchTerm.length > 5) {
      setSearchResults([]);
      return;
    }
    const results = [];
    let searchIndex = -1;
    while ((searchIndex = digits.indexOf(searchTerm, searchIndex + 1)) !== -1) {
      results.push(searchIndex);
    }
    setSearchResults(results);
  }, [searchTerm, digits]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  // Color-blind friendly palette with good contrast
  const digitColors = [
    '#E69F00', '#56B4E9', '#009E73', '#F0E442', '#0072B2',
    '#D55E00', '#CC79A7', '#000000', '#E69F00', '#56B4E9'
  ];

  const isDigitHighlighted = useCallback((rowIndex, digitIndex) => {
    const globalIndex = rowIndex * numDigitsPerRow + digitIndex;
    return searchResults.some(result => {
      const start = result - rowOffsets[rowIndex];
      const end = start + searchTerm.length;
      return globalIndex >= start && globalIndex < end;
    });
  }, [searchResults, searchTerm, numDigitsPerRow, rowOffsets]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

    return (
    <div className="pi-container" ref={containerRef} style={{ height: 'calc(100vh - 60px)', width: '100vw', overflow: 'hidden', position: 'relative', backgroundColor: '#1a1a1a', color: 'white', fontSize: '24px' }}>
      <div style={{ padding: '0.5rem', backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', overflowX: 'auto' }}>
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginRight: '1rem', whiteSpace: 'nowrap' }}>The Infinity of Pi</h2>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
          <button onClick={toggleAnimation} style={{ marginRight: '0.5rem', padding: '0.25rem 0.5rem', backgroundColor: '#3a3a3a', border: 'none', borderRadius: '4px', color: 'white', fontSize: '0.9rem', whiteSpace: 'nowrap' }}>
            {isAnimating ? 'Pause' : 'Resume'}
          </button>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
            <span style={{ marginRight: '0.25rem', fontSize: '0.9rem' }}>Speed:</span>
            <input
              type="range"
              min="50"
              max="500"
              step="10"
              value={speed}
              onChange={handleSpeedChange}
              style={{ width: '80px', margin: '0 0.25rem' }}
            />
            <span style={{ marginLeft: '0.25rem', fontSize: '0.9rem' }}>{speed}ms</span>
          </div>
          <input
            type="text"
            placeholder="Search (max 5)"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.slice(0, 5))}
            style={{ marginRight: '0.5rem', padding: '0.25rem', backgroundColor: '#3a3a3a', border: 'none', borderRadius: '4px', color: 'white', fontSize: '0.9rem', width: '100px' }}
          />
          <span style={{ marginRight: '0.5rem', fontSize: '0.9rem', whiteSpace: 'nowrap' }}>Results: {searchResults.length}</span>
          <button onClick={() => setShowInfo(true)} style={{ padding: '0.25rem 0.5rem', backgroundColor: '#3a3a3a', border: 'none', borderRadius: '4px', color: 'white', fontSize: '0.9rem', whiteSpace: 'nowrap' }}>
            ℹ️ Info
          </button>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        {[...Array(numRows)].map((_, rowIndex) => {
          const startIndex = rowIndex * numDigitsPerRow + rowOffsets[rowIndex];
          const rowDigits = digits.slice(startIndex, startIndex + numDigitsPerRow);
          return (
            <div key={rowIndex} style={{ whiteSpace: 'nowrap' }}>
              {rowDigits.split('').map((digit, index) => (
                <span
                  key={index}
                  style={{
                    color: digitColors[parseInt(digit)],
                    display: 'inline-block',
                    width: '0.7em',
                    textAlign: 'center',
                    backgroundColor: isDigitHighlighted(rowIndex, index) ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                    textShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                  }}
                >
                  {digit}
                </span>
              ))}
            </div>
          );
        })}
      </div>
      {showInfo && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
          <div style={{ backgroundColor: '#2a2a2a', padding: '2rem', borderRadius: '10px', maxWidth: '600px', maxHeight: '80%', overflowY: 'auto' }}>
            <h2 style={{ marginBottom: '1rem' }}>About The Infinity of Pi Visualization</h2>
            <p>This visualization displays the digits of Pi in an infinite scroll, showcasing the endless nature of this mathematical constant. Each digit is color-coded for easy recognition and pattern identification.</p>
            <h3 style={{ marginTop: '1rem' }}>Features:</h3>
            <ul>
              <li><strong>Animation Control:</strong> Pause or resume the scrolling animation of Pi digits.</li>
              <li><strong>Speed Adjustment:</strong> Control the speed of the animation using the slider.</li>
              <li><strong>Digit Search:</strong> Search for specific sequences of up to 5 digits within Pi. The search results will be highlighted in the visualization.</li>
            </ul>
            <p>This visualization aims to provide an intuitive and engaging way to explore the infinite sequence of Pi, allowing users to observe patterns and search for specific number sequences within this fundamental mathematical constant.</p>
            <button onClick={() => setShowInfo(false)} style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#3a3a3a', border: 'none', borderRadius: '4px', color: 'white' }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PiPage;