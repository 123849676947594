import React, { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import html2canvas from 'html2canvas';

const compliments = [
  { text: "You're as beautiful as the full moon" },
  { text: "You have an incredible sense of humor" },
  { text: "Your positivity is infectious" },
  { text: "I admire your integrity" },
  { text: "You have a heart of gold" },
  { text: "Your kindness makes such a difference" },
  { text: "You’re one of the most genuine people I know" },
  { text: "Your energy is so uplifting" },
  { text: "You are so thoughtful" },
  { text: "You always know how to brighten someone's day" },
  { text: "Your confidence is inspiring" },
  { text: "You are brilliant at what you do" },
  { text: "You always find the perfect solution to problems" },
  { text: "Your creativity knows no bounds" },
  { text: "You have such a sharp mind" },
  { text: "You are so resourceful and capable" },
  { text: "The way you approach challenges is impressive" },
  { text: "Your attention to detail is remarkable" },
  { text: "You are so articulate and well-spoken" },
  { text: "You learn things so quickly—it’s amazing!" },
  { text: "You are a natural leader" },
  { text: "You have such a radiant smile" },
  { text: "Your eyes are captivating" },
  { text: "You have such great style" },
  { text: "You always look effortlessly put together" },
  { text: "You have such a warm presence" },
  { text: "You glow with confidence" },
  { text: "Your hair looks fantastic today" },
  { text: "You have an elegant and unique sense of fashion" },
  { text: "You have a great sense of personal style" },
  { text: "You carry yourself with such grace" },
  { text: "You always know the right thing to say" },
  { text: "You make everyone feel welcome" },
  { text: "You are so empathetic and understanding" },
  { text: "You are great at making people feel heard and appreciated" },
  { text: "Your emotional intelligence is on another level" },
  { text: "You handle every situation with such grace and tact" },
  { text: "You’re a great listener" },
  { text: "You’re so patient and kind-hearted" },
  { text: "Your ability to connect with others is truly impressive" },
  { text: "You make people feel comfortable just being around you" },
  { text: "You have such an amazing work ethic" },
  { text: "Your dedication to your work is inspiring" },
  { text: "You always go above and beyond" },
  { text: "You put so much heart into everything you do" },
  { text: "Your perseverance is admirable" },
  { text: "You never give up, and that’s so inspiring" },
  { text: "The effort you put into your work is clear to see" },
  { text: "You are so disciplined and focused" },
  { text: "Your commitment to excellence is impressive" },
  { text: "You are a master of your craft" },
  { text: "You should be so proud of what you’ve achieved" },
  { text: "You've come so far and it’s amazing to witness" },
  { text: "Your success is well deserved" },
  { text: "You make everything look so easy" },
  { text: "You’ve accomplished so much—it's incredible" },
  { text: "Your hard work really paid off" },
  { text: "You are a real role model" },
  { text: "You make success look effortless" },
  { text: "Your accomplishments speak volumes about your talent" },
  { text: "You’re such an inspiration to those around you" },
  { text: "You can do anything you set your mind to" },
  { text: "You are stronger than you realize" },
  { text: "You have everything it takes to succeed" },
  { text: "I believe in you and your abilities" },
  { text: "You’re capable of more than you know" },
  { text: "You are destined for great things" },
  { text: "Keep going—you're doing amazing work" },
  { text: "You've got this!" },
  { text: "I admire your determination and strength" },
  { text: "You're on the right path, keep it up" }
];

const getMoonPhase = () => {
  const date = DateTime.now();
  const year = date.year;
  const month = date.month;
  const day = date.day;

  const c = Math.floor((year - 1900) / 19);
  const b = Math.floor((year - 1899) / 4);
  const a = year - 1900;
  const m = Math.floor((month + 9) / 12);
  const d = (384 * a + 20 * b - 24 * c + 355 + (30 * month + day - 3 * m)) % 1024;
  const phase = d / 1024;

  return phase;
};

const getMoonPhaseEmoji = (phase) => {
  if (phase < 0.125) return '🌑';
  if (phase < 0.25) return '🌒';
  if (phase < 0.375) return '🌓';
  if (phase < 0.5) return '🌔';
  if (phase < 0.625) return '🌕';
  if (phase < 0.75) return '🌖';
  if (phase < 0.875) return '🌗';
  return '🌘';
};

const LunaSays = () => {
  const [currentCompliment, setCurrentCompliment] = useState(compliments[0]);
  const [moonPhase, setMoonPhase] = useState(getMoonPhase());
  const [name, setName] = useState('');
  const shareCardRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setMoonPhase(getMoonPhase());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const changeCompliment = () => {
    const randomIndex = Math.floor(Math.random() * compliments.length);
    setCurrentCompliment(compliments[randomIndex]);
  };

  const handleShare = async () => {
    if (shareCardRef.current) {
      try {
        const canvas = await html2canvas(shareCardRef.current);
        const imageDataUrl = canvas.toDataURL('image/png');

        if (navigator.share) {
          const blob = await (await fetch(imageDataUrl)).blob();
          const file = new File([blob], 'luna-compliment.png', { type: 'image/png' });
          await navigator.share({
            files: [file],
            title: 'Luna Compliment',
            text: 'Check out this lovely compliment from Luna!'
          });
        } else {
          // Fallback for browsers that don't support navigator.share
          const link = document.createElement('a');
          link.href = imageDataUrl;
          link.download = 'luna-compliment.png';
          link.click();
        }
      } catch (error) {
        console.error('Error sharing', error);
        alert('Sorry, there was an error sharing your compliment. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-900 to-purple-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white bg-opacity-20 backdrop-blur-lg rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold text-center text-white mb-6">Luna</h1>
        <div ref={shareCardRef} className="flex flex-col items-center space-y-6 bg-gradient-to-b from-indigo-800 to-purple-800 p-6 rounded-lg">
          <div className="text-9xl">{getMoonPhaseEmoji(moonPhase)}</div>
          <div className="text-center text-white">
            <p className="text-xl font-semibold mb-2">{currentCompliment.text}</p>

          </div>
          {name && <p className="text-white italic">- {name}</p>}
        </div>
        <div className="mt-6 space-y-4">
          <button
            onClick={changeCompliment}
            className="w-full px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
          >
            New Compliment
          </button>
          <input
            type="text"
            placeholder="Enter a name (optional)"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 bg-white bg-opacity-20 text-white placeholder-gray-300 rounded"
          />
          <button
            onClick={handleShare}
            className="w-full px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
          >
            Share Compliment
          </button>
        </div>
      </div>
    </div>
  );
};

export default LunaSays;