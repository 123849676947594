import React, { useState } from 'react';

const variants = {
  classic: ['Rock', 'Paper', 'Scissors'],
  corporate: ['Merger', 'Layoff', 'Startup'],
  politics: ['Scandal', 'Lobbyist', 'Filibuster'],
  family: ['Chores', 'Allowance', 'Grounding'],
  food: ['Spicy', 'Sweet', 'Sour'],
};

const getWinner = (player, computer, currentVariant) => {
  const choices = variants[currentVariant];
  if (player === computer) return 'Tie';
  if (
    (choices.indexOf(player) + 1) % choices.length ===
    choices.indexOf(computer)
  ) {
    return 'Player';
  }
  return 'Computer';
};

const RockPaperScissors = () => {
  const [currentVariant, setCurrentVariant] = useState('classic');
  const [playerChoice, setPlayerChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState(null);

  const playGame = (choice) => {
    const choices = variants[currentVariant];
    const computerChoice = choices[Math.floor(Math.random() * choices.length)];
    setPlayerChoice(choice);
    setComputerChoice(computerChoice);
    setResult(getWinner(choice, computerChoice, currentVariant));
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Rock Paper Scissors Variants</h2>
      <select
        value={currentVariant}
        onChange={(e) => setCurrentVariant(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      >
        {Object.keys(variants).map((variant) => (
          <option key={variant} value={variant}>
            {variant.charAt(0).toUpperCase() + variant.slice(1)}
          </option>
        ))}
      </select>

      <div className="flex justify-between mb-4">
        {variants[currentVariant].map((choice) => (
          <button
            key={choice}
            onClick={() => playGame(choice)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {choice}
          </button>
        ))}
      </div>

      {playerChoice && computerChoice && (
        <div className="text-center">
          <p>Player chose: {playerChoice}</p>
          <p>Computer chose: {computerChoice}</p>
          <p className="font-bold mt-2">
            {result === 'Tie'
              ? "It's a tie!"
              : `${result} wins!`}
          </p>
        </div>
      )}
    </div>
  );
};

export default RockPaperScissors;