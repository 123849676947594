import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import PiPage from './Projects/pi/PiPage';
import LifeTime from './Projects/lifetime/LifeTime';
import CautionScreen from './Projects/caution/CautionScreen';
import CautionPage from './Projects/caution/CautionPage';
import GoldFallApp from './Projects/goldfall/GoldFallApp';
import Clocks from './Projects/clocks/Clocks';
import DreamsBeginHere from './Projects/dreams/DreamsBeginHere';
import GraphMatrixApp from './Projects/graphs/GraphMatrixApp';
import ChallengeMaster from './Projects/challenges/ChallengeMaster';
import UniqueBallApp from './Projects/balls/UniqueBallApp';
import UniqueMatrixRain from './Projects/matrix/UniqueMatrixApp';
import Believe from './Projects/believe/Believe';
import SunsetSunriseDisplay from './Projects/sunriseset/SunsetSunriseDisplay';
import LunaSays from './Projects/lunar/LunaSays';
import ImageTextDestroyer from './Projects/itsover/ImageTextDestroyer';
import NameCombiner from './Projects/namemix/NameCombiner';
import KeyboardMusic from './Projects/keyboardmusic/KeyboardMusic';
import MovingRockApp from './Projects/rock/MovingRockApp';
import NameDisplay from './Projects/narc/NameDisplay';
import DaysCalculator from './Projects/days/DaysCalculator';
import HexagonGrid from './Projects/hexagrid/HexagonGrid';
import TextPainter from './Projects/textpaint/TextPainter';
import LoveMe from './Projects/lovemerose/LoveMe';
import Button from './Projects/button/Button';
import ImageEditor from './Projects/itsover/ImageEditor';
import RockPaperScissors from './Projects/rock/RockPaperScissors';
import HourGlassTimer from './Projects/clocks/HourGlassTimer';
import MetronomeApp from './Projects/keyboardmusic/MetronomeApp';
import PhotoBooth from './Projects/itsover/PhotoBooth';
import AudioSpectrogram from './Projects/keyboardmusic/AudioSpectrogram';
import NoiseLevelChecker from './Projects/keyboardmusic/NoiseLevelChecker';
import PeriodicTable from './Projects/elements/PeriodicTable';
import ReverseVideoPlayer from './Projects/retrotv/ReverseVideoPlayer';
import GoldenRatioAnalyzer from './Projects/itsover/GoldenRatioAnalyzer';
import SayNoApp from './Projects/no/SayNoApp';
import PhantomScriptMirror from './Projects/textpaint/PhantomScriptMirror';
import BuzzerApp from './Projects/reject/BuzzerApp';
import ImageColorExtractor from './Projects/itsover/ImageColorExtractor';
import TypoApp from './Projects/textpaint/TypoApp';
import CipherPage from './Projects/cipher/CipherPage';
import EatingPage from './Projects/eats/EatingPage';
import WaterLossApp from './Projects/water/WaterLossApp';
import { ExplorePage, AboutPage } from './components/navigation-pages';
import PrivacyPolicy from './components/PrivacyPolicy';

// In your Routes component:


import './styles/App.css';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header className="flex-shrink-0" />
        <main className="flex-grow overflow-y-auto" style={{ height: 'calc(100vh - 160px)' }}>

            <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pies" element={<PiPage />} />
            <Route path="/lifetime" element={<LifeTime />} />
            <Route path="/goldfall" element={<GoldFallApp />} />
            <Route path="/clocks" element={<Clocks />} />
            <Route path="/dreams" element={<DreamsBeginHere />} />
            <Route path="/graphs" element={<GraphMatrixApp />} />
            <Route path="/humanchallenge" element={<ChallengeMaster />} />
            <Route path="/ballsinherit" element={<UniqueBallApp />} />
            <Route path="/matrix" element={<UniqueMatrixRain />} />
            <Route path="/believe" element={<Believe />} />
            <Route path="/earth-welcome" element={<CautionScreen />} />
            <Route path="/earth-main" element={<CautionPage />} />
            <Route path="/suns" element={<SunsetSunriseDisplay />} />
            <Route path="/luna" element={<LunaSays />} />
            <Route path="/itsover" element={<ImageTextDestroyer />} />
            <Route path="/namemix" element={<NameCombiner />} />
            <Route path="/keyboardmusic" element={<KeyboardMusic />} />
            <Route path="/rock" element={<MovingRockApp />} />
            <Route path="/narc" element={<NameDisplay />} />
            <Route path="/days" element={<DaysCalculator />} />
            <Route path="/hexgrid" element={<HexagonGrid />} />
            <Route path="/textpaint" element={<TextPainter />} />
            <Route path="/loveme" element={<LoveMe />} />
            <Route path="/button" element={<Button />} />
            <Route path="/imageeditor" element={<ImageEditor />} />
            <Route path="/rockpaperscissor" element={<RockPaperScissors />} />
            <Route path="/hourglass" element={<HourGlassTimer />} />
            <Route path="/metronome" element={<MetronomeApp />} />
            <Route path="/photobooth" element={<PhotoBooth />} />
            <Route path="/audio" element={<AudioSpectrogram />} />
            <Route path="/noise" element={<NoiseLevelChecker />} />
            <Route path="/periodictable" element={<PeriodicTable />} />
            <Route path="/reversevideoplayer" element={<ReverseVideoPlayer />} />
            <Route path="/goldenratio" element={<GoldenRatioAnalyzer />} />
            <Route path="/no" element={<SayNoApp />} />
            <Route path="/mirrortext" element={<PhantomScriptMirror />} />
            <Route path="/rejected" element={<BuzzerApp />} />
            <Route path="/palette" element={<ImageColorExtractor />} />
            <Route path="/typo" element={<TypoApp />} />
            <Route path="/cipher" element={<CipherPage />} />
            <Route path="/guts" element={<EatingPage />} />
            <Route path="/water" element={<WaterLossApp />} />
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/about" element={<AboutPage />} />
             <Route path="/privacy" element={<PrivacyPolicy />} />
           </Routes>

         </main>
        <Footer className="flex-shrink-0" />
      </div>
    </Router>
  );
}

export default App;