import React, { useState, useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import ColorThief from 'colorthief';

const ImageColorExtractor = () => {
  const [image, setImage] = useState(null);
  const [colors, setColors] = useState([]);
  const [numColors, setNumColors] = useState(5);
  const [hoveredColor, setHoveredColor] = useState(null);
  const [colorFormat, setColorFormat] = useState('hex'); // 'hex' or 'rgb'
  const [copiedColor, setCopiedColor] = useState(null);
  const imgRef = useRef();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    resizeImage(file);
  };

  const resizeImage = (file) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      'JPEG',
      100,
      0,
      (uri) => {
        setImage(uri);
      },
      'base64'
    );
  };

  const extractColors = () => {
    const colorThief = new ColorThief();
    if (imgRef.current.complete) {
      const palette = colorThief.getPalette(imgRef.current, numColors);
      setColors(palette.map(rgb => ({
        rgb: `rgb(${rgb.join(',')})`,
        hex: rgbToHex(rgb[0], rgb[1], rgb[2])
      })));
    } else {
      imgRef.current.addEventListener('load', () => {
        const palette = colorThief.getPalette(imgRef.current, numColors);
        setColors(palette.map(rgb => ({
          rgb: `rgb(${rgb.join(',')})`,
          hex: rgbToHex(rgb[0], rgb[1], rgb[2])
        })));
      });
    }
  };

  const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }).join('');

  const handleNumColorsChange = (e) => {
    setNumColors(parseInt(e.target.value));
    if (image) {
      extractColors();
    }
  };

  const getContrastColor = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g).map(Number);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  };

  const createSophisticatedPaletteImage = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const width = 800;
    const height = 400;
    canvas.width = width;
    canvas.height = height;

    // Background
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(0, 0, width, height);

    // Title
    ctx.fillStyle = '#333';
    ctx.font = 'bold 24px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('Color Palette', width / 2, 40);

    // Color swatches
    const swatchSize = 100;
    const startX = (width - (colors.length * swatchSize)) / 2;
    const startY = 80;

    colors.forEach((color, index) => {
      const x = startX + (index * swatchSize);

      // Main color swatch
      ctx.fillStyle = color.rgb;
      ctx.beginPath();
      ctx.arc(x + swatchSize / 2, startY + swatchSize / 2, swatchSize / 2 - 5, 0, 2 * Math.PI);
      ctx.fill();

      // Color code
      ctx.fillStyle = 'black';
      ctx.font = '14px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(color.hex, x + swatchSize / 2, startY + swatchSize + 20);

      // RGB code (smaller)
      ctx.fillStyle = '#666';
      ctx.font = '12px Arial';
      ctx.fillText(color.rgb, x + swatchSize / 2, startY + swatchSize + 40);
    });

    // Footer
    ctx.fillStyle = '#666';
    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('Generated with Image Color Extractor', width / 2, height - 20);

    return canvas.toDataURL();
  };

  const sharePalette = async () => {
    const paletteImage = createSophisticatedPaletteImage();
    if (navigator.share) {
      try {
        const blob = await (await fetch(paletteImage)).blob();
        const file = new File([blob], 'color-palette.png', { type: 'image/png' });
        await navigator.share({
          title: 'My Color Palette',
          text: 'Check out this color palette I extracted!',
          files: [file]
        });
      } catch (error) {
        console.error('Error sharing:', error);
        alert('Failed to share. Your browser may not support sharing files.');
      }
    } else {
      alert('Native sharing is not supported on this device/browser.');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedColor(text);
      setTimeout(() => setCopiedColor(null), 2000);
    });
  };

  return (
    <div className="container mx-auto p-4 max-w-2xl">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Image Color Extractor</h1>
        <div className="mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>
        <div className="mb-4 flex justify-between items-center">
          <select
            value={numColors}
            onChange={handleNumColorsChange}
            className="block w-1/2 mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value={5}>5 Colors</option>
            <option value={10}>10 Colors</option>
          </select>
          <div className="flex items-center">
            <span className="mr-2">Color Format:</span>
            <select
              value={colorFormat}
              onChange={(e) => setColorFormat(e.target.value)}
              className="block w-20 mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="hex">HEX</option>
              <option value="rgb">RGB</option>
            </select>
          </div>
        </div>
        {image && (
          <div className="mt-4">
            <img
              src={image}
              alt="Uploaded"
              ref={imgRef}
              onLoad={extractColors}
              className="w-full rounded-lg shadow-lg"
            />
            <div className="flex flex-wrap justify-center gap-4 mt-4">
              {colors.map((color, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div
                    className="w-20 h-20 rounded-full shadow-md flex items-center justify-center cursor-pointer transition-transform duration-200 hover:scale-110"
                    style={{ backgroundColor: color.rgb }}
                    onMouseEnter={() => setHoveredColor(color)}
                    onMouseLeave={() => setHoveredColor(null)}
                  >
                    {hoveredColor === color && (
                      <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                        {color[colorFormat]}
                        <br />
                        {colorFormat === 'hex' ? color.rgb : color.hex}
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => copyToClipboard(color.hex)}
                    className="mt-2 px-2 py-1 text-xs bg-gray-200 hover:bg-gray-300 rounded"
                  >
                    {copiedColor === color.hex ? 'Copied!' : color.hex}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex justify-center gap-4 mt-6">
          <button
            onClick={() => {
              const link = document.createElement('a');
              link.href = createSophisticatedPaletteImage();
              link.download = 'sophisticated-color-palette.png';
              link.click();
            }}
            disabled={!colors.length}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Download Palette
          </button>
          <button
            onClick={sharePalette}
            disabled={!colors.length}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Share Palette
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageColorExtractor;