import React, { useState, useEffect, useRef } from 'react';
import { Music, ChevronUp, ChevronDown, Volume2, VolumeX, Play, Keyboard, Mouse, Space, HelpCircle, X, Settings } from 'lucide-react';
import * as Tone from 'tone';

const TutorialModal = ({ isOpen, onClose, currentStep, onNext, onBack, steps }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 p-4 sm:p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="flex justify-center mb-4 sm:mb-6">
          {steps[currentStep].icon}
        </div>

        <h3 className="text-lg sm:text-xl font-bold text-center mb-2">
          {steps[currentStep].title}
        </h3>

        <p className="text-sm sm:text-base text-gray-600 text-center mb-4 sm:mb-6">
          {steps[currentStep].description}
        </p>

        <div className="flex justify-center space-x-2 mb-4 sm:mb-6">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full transition-colors ${
                index === currentStep ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>

        <div className="flex justify-between">
          <button
            onClick={onBack}
            className={`px-3 py-1.5 sm:px-4 sm:py-2 rounded text-sm sm:text-base text-gray-700 hover:bg-gray-100 transition-colors ${
              currentStep === 0 ? 'invisible' : ''
            }`}
          >
            Back
          </button>
          <button
            onClick={onNext}
            className="px-3 py-1.5 sm:px-4 sm:py-2 rounded text-sm sm:text-base bg-blue-500 text-white hover:bg-blue-600 transition-colors"
          >
            {currentStep < steps.length - 1 ? 'Next' : 'Get Started'}
          </button>
        </div>
      </div>
    </div>
  );
};

const ControlPanel = ({ isMuted, toggleMute, isSustainOn, toggleSustain, octave, changeOctave, showTutorial, setShowTutorial }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Mobile menu
  const mobileMenu = (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden" onClick={() => setIsMenuOpen(false)}>
      <div className="absolute right-0 top-0 h-full w-64 bg-white p-4" onClick={e => e.stopPropagation()}>
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => setShowTutorial(true)}
            className="flex items-center space-x-2 p-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors w-full"
          >
            <HelpCircle className="w-5 h-5" />
            <span>Tutorial</span>
          </button>
          <button
            onClick={toggleMute}
            className="flex items-center space-x-2 p-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors w-full"
          >
            {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
            <span>{isMuted ? 'Unmute' : 'Mute'}</span>
          </button>
          <button
            onClick={toggleSustain}
            className={`flex items-center space-x-2 p-2 rounded transition-colors w-full ${
              isSustainOn ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            <Space className="w-5 h-5" />
            <span>Sustain</span>
          </button>
          <div className="flex flex-col space-y-2">
            <span className="text-sm font-semibold">Octave</span>
            <div className="flex items-center justify-between bg-gray-200 rounded p-2">
              <button
                onClick={() => changeOctave(-1)}
                className="p-1 hover:bg-gray-300 rounded transition-colors"
              >
                <ChevronDown className="h-4 w-4" />
              </button>
              <span className="text-lg font-semibold">{octave}</span>
              <button
                onClick={() => changeOctave(1)}
                className="p-1 hover:bg-gray-300 rounded transition-colors"
              >
                <ChevronUp className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Desktop controls
  const desktopControls = (
    <div className="hidden md:flex items-center space-x-4">
      <button
        onClick={() => setShowTutorial(true)}
        className="p-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
        title="Show Tutorial"
      >
        <HelpCircle className="w-5 h-5" />
      </button>
      <button
        onClick={toggleMute}
        className="p-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
      >
        {isMuted ? <VolumeX /> : <Volume2 />}
      </button>
      <button
        onClick={toggleSustain}
        className={`p-2 rounded transition-colors ${
          isSustainOn ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
        }`}
      >
        Sustain
      </button>
      <div className="flex items-center space-x-2">
        <button
          onClick={() => changeOctave(-1)}
          className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
        >
          <ChevronDown className="h-4 w-4" />
        </button>
        <span className="text-lg font-semibold">Octave: {octave}</span>
        <button
          onClick={() => changeOctave(1)}
          className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
        >
          <ChevronUp className="h-4 w-4" />
        </button>
      </div>
    </div>
  );

  return (
    <>
      {/* Mobile menu button */}
      <button
        className="md:hidden p-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
        onClick={() => setIsMenuOpen(true)}
      >
        <Settings className="w-5 h-5" />
      </button>

      {/* Mobile menu panel */}
      {isMenuOpen && mobileMenu}

      {/* Desktop controls */}
      {desktopControls}
    </>
  );
};

const KeyboardMusic = () => {
  const [pressedKeys, setPressedKeys] = useState(new Set());
  const [playedNotes, setPlayedNotes] = useState([]);
  const [octave, setOctave] = useState(4);
  const [isMuted, setIsMuted] = useState(false);
  const [isSustainOn, setIsSustainOn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showTutorial, setShowTutorial] = useState(true);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  const synthRef = useRef(null);

  const tutorialSteps = [
    {
      title: "Welcome to the Virtual Piano!",
      description: "Learn how to play music in your browser. Let's get you started with the basics.",
      icon: <Music className="w-8 h-8 text-blue-500" />
    },
    {
      title: "Keyboard Controls",
      description: "Use your computer keyboard to play notes! Keys A-L correspond to white keys, and W-P correspond to black keys.",
      icon: <Keyboard className="w-8 h-8 text-blue-500" />
    },
    {
      title: "Mouse Control",
      description: "You can also click and hold the piano keys with your mouse to play notes.",
      icon: <Mouse className="w-8 h-8 text-blue-500" />
    },
    {
      title: "Sustain",
      description: "Hold the spacebar or click the Sustain button to make notes ring out longer, just like a piano's sustain pedal!",
      icon: <Space className="w-8 h-8 text-blue-500" />
    }
  ];

  const keyMap = {
    'a': 'C',
    'w': 'C#',
    's': 'D',
    'e': 'D#',
    'd': 'E',
    'f': 'F',
    't': 'F#',
    'g': 'G',
    'y': 'G#',
    'h': 'A',
    'u': 'A#',
    'j': 'B',
    'k': 'C',
    'o': 'C#',
    'l': 'D',
    'p': 'D#',
    ';': 'E'
  };

  const initializeAudio = async () => {
    await Tone.start();
    if (!synthRef.current) {
      synthRef.current = new Tone.PolySynth(Tone.Synth).toDestination();
    }
    setIsInitialized(true);
  };

  useEffect(() => {
    return () => {
      if (synthRef.current) {
        synthRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (synthRef.current) {
      synthRef.current.volume.value = isMuted ? -Infinity : 0;
    }
  }, [isMuted]);

  const playNote = (note) => {
    if (!isMuted && synthRef.current && isInitialized) {
      synthRef.current.triggerAttack(`${note}${octave}`);
    }
  };

  const stopNote = (note) => {
    if (synthRef.current && !isSustainOn && isInitialized) {
      synthRef.current.triggerRelease(`${note}${octave}`);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isInitialized) return;
      const key = event.key.toLowerCase();
      if (keyMap[key] && !event.repeat) {
        setPressedKeys(prev => new Set(prev).add(key));
        playNote(keyMap[key]);
        setPlayedNotes(prev => [...prev, keyMap[key]].slice(-5));
      } else if (key === ' ') {
        setIsSustainOn(true);
      }
    };

    const handleKeyUp = (event) => {
      if (!isInitialized) return;
      const key = event.key.toLowerCase();
      if (keyMap[key]) {
        setPressedKeys(prev => {
          const newSet = new Set(prev);
          newSet.delete(key);
          return newSet;
        });
        stopNote(keyMap[key]);
      } else if (key === ' ') {
        setIsSustainOn(false);
        if (synthRef.current) {
          synthRef.current.releaseAll();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [keyMap, octave, isSustainOn, isInitialized]);

  const changeOctave = (change) => {
    setOctave(prev => Math.max(0, Math.min(8, prev + change)));
  };

  const toggleMute = () => {
    setIsMuted(prev => !prev);
  };

  const toggleSustain = () => {
    setIsSustainOn(prev => !prev);
    if (isSustainOn && synthRef.current) {
      synthRef.current.releaseAll();
    }
  };

  const handleTutorialNext = () => {
    if (currentTutorialStep < tutorialSteps.length - 1) {
      setCurrentTutorialStep(prev => prev + 1);
    } else {
      setShowTutorial(false);
      setCurrentTutorialStep(0);
    }
  };

  const handleTutorialBack = () => {
    if (currentTutorialStep > 0) {
      setCurrentTutorialStep(prev => prev - 1);
    }
  };

   const renderKey = (keyChar, note) => {
    const isBlackKey = note.includes('#');
    return (
      <div
        key={keyChar}
        className={`
          border border-gray-300 rounded-b-lg cursor-pointer select-none relative
          ${isBlackKey ?
            'bg-black text-white h-20 sm:h-24 md:h-32 z-10 -mx-2 sm:-mx-3 md:-mx-4 w-4 sm:w-6 md:w-8' :
            'bg-white text-black h-32 sm:h-40 md:h-48 w-6 sm:w-8 md:w-12'
          }
          ${pressedKeys.has(keyChar) ? 'bg-blue-500 text-white' : ''}
        `}
        onMouseDown={() => {
          if (isInitialized) {
            playNote(note);
            setPressedKeys(prev => new Set(prev).add(keyChar));
          }
        }}
        onMouseUp={() => {
          if (isInitialized) {
            stopNote(note);
            setPressedKeys(prev => {
              const newSet = new Set(prev);
              newSet.delete(keyChar);
              return newSet;
            });
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault(); // Prevent double-firing on mobile
          if (isInitialized) {
            playNote(note);
            setPressedKeys(prev => new Set(prev).add(keyChar));
          }
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          if (isInitialized) {
            stopNote(note);
            setPressedKeys(prev => {
              const newSet = new Set(prev);
              newSet.delete(keyChar);
              return newSet;
            });
          }
        }}
      >
        <div className="absolute bottom-1 sm:bottom-2 left-0 right-0 text-center">
          <div className="text-[8px] sm:text-xs font-bold">{note}</div>
          <div className="text-[8px] sm:text-xs">{keyChar.toUpperCase()}</div>
        </div>
      </div>
    );
  };

  if (!isInitialized) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <button
          onClick={initializeAudio}
          className="bg-blue-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg flex items-center space-x-2 hover:bg-blue-600 transition-colors text-sm sm:text-base"
        >
          <Play className="w-4 h-4 sm:w-5 sm:h-5" />
          <span>Click to Start Audio</span>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-2 sm:p-4">
      <TutorialModal
        isOpen={showTutorial}
        onClose={() => setShowTutorial(false)}
        currentStep={currentTutorialStep}
        onNext={handleTutorialNext}
        onBack={handleTutorialBack}
        steps={tutorialSteps}
      />

      <div className="bg-white rounded-lg shadow-lg p-3 sm:p-4 md:p-6 w-full max-w-4xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg sm:text-xl md:text-2xl font-bold flex items-center">
            <Music className="mr-2 w-5 h-5 sm:w-6 sm:h-6" /> Keyboard Music
          </h2>
          <ControlPanel
            isMuted={isMuted}
            toggleMute={toggleMute}
            isSustainOn={isSustainOn}
            toggleSustain={toggleSustain}
            octave={octave}
            changeOctave={changeOctave}
            showTutorial={showTutorial}
            setShowTutorial={setShowTutorial}
          />
        </div>
        <div className="relative flex justify-center mb-4">
          <div className="flex justify-center overflow-x-auto pb-4 max-w-full">
            {Object.entries(keyMap).map(([key, note]) => renderKey(key, note))}
          </div>
        </div>
        <div className="text-center">
          <h3 className="font-semibold mb-2 text-sm sm:text-base">Recently Played Notes:</h3>
          <div className="flex flex-wrap justify-center gap-2">
            {playedNotes.map((note, index) => (
              <div key={index} className="p-1 sm:p-2 bg-gray-200 rounded text-sm">
                {note}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default KeyboardMusic;