import React, { useState, useRef } from 'react';

const CipherApp = () => {
  const [mode, setMode] = useState('encode');
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [selectedCipher, setSelectedCipher] = useState('Caesar');
  const [copySuccess, setCopySuccess] = useState(false);
  const outputTextRef = useRef(null);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    handleTranslation(inputText, selectedCipher, newMode);
  };

  const handleCipherChange = (event) => {
    const newCipher = event.target.value;
    setSelectedCipher(newCipher);
    handleTranslation(inputText, newCipher, mode);
  };

  const handleTextChange = (event) => {
    const text = event.target.value;
    setInputText(text);
    handleTranslation(text, selectedCipher, mode);
  };

  const handleTranslation = (text, cipher, currentMode) => {
    let translatedText = '';
    switch (cipher) {
      case 'Caesar':
        translatedText = caesarCipher(text, currentMode);
        break;
      case 'Atbash':
        translatedText = atbashCipher(text);
        break;
      default:
        translatedText = text;
    }
    setOutputText(translatedText);
  };

  const caesarCipher = (text, currentMode) => {
    const shift = 3;
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return text
      .toUpperCase()
      .split('')
      .map((char) => {
        if (alphabet.includes(char)) {
          let shiftedIndex = (alphabet.indexOf(char) + (currentMode === 'encode' ? shift : -shift)) % 26;
          return alphabet[shiftedIndex >= 0 ? shiftedIndex : 26 + shiftedIndex];
        }
        return char;
      })
      .join('');
  };

  const atbashCipher = (text) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const reversed = alphabet.split('').reverse().join('');
    return text
      .toUpperCase()
      .split('')
      .map((char) => (alphabet.includes(char) ? reversed[alphabet.indexOf(char)] : char))
      .join('');
  };

  const copyToClipboard = () => {
    if (outputTextRef.current) {
      outputTextRef.current.select();
      document.execCommand('copy');
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Cipher App</h1>
        <div className="flex mb-4">
          <button
            className={`flex-1 py-2 ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleModeChange('encode')}
          >
            Encode
          </button>
          <button
            className={`flex-1 py-2 ${mode === 'decode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleModeChange('decode')}
          >
            Decode
          </button>
        </div>
        <textarea
          className="w-full p-2 mb-4 border rounded"
          rows="4"
          value={inputText}
          onChange={handleTextChange}
          placeholder={`Enter text to ${mode}`}
        />
        <select
          className="w-full p-2 mb-4 border rounded"
          value={selectedCipher}
          onChange={handleCipherChange}
        >
          <option value="Caesar">Caesar Cipher</option>
          <option value="Atbash">Atbash Cipher</option>
        </select>
        <p className="font-semibold mb-2">Translated Message:</p>
        <div className="relative">
          <textarea
            ref={outputTextRef}
            className="w-full p-2 mb-2 border rounded bg-gray-100"
            rows="4"
            value={outputText}
            readOnly
            placeholder={`Your ${mode === 'encode' ? 'encoded' : 'decoded'} message will appear here`}
          />
          <button
            onClick={copyToClipboard}
            className="absolute top-2 right-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
          >
            {copySuccess ? 'Copied!' : 'Copy'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CipherApp;